import styled from 'styled-components';
import { Form } from "react-bootstrap";

import {
  colorTextDark,
  backgroundTertiary,
  detailColorLight2
} from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  padding: 20px 22px;
  height: 64px;
  gap: 18px;

  display: flex;
  align-items: center;
  border-radius: 12px;

  border: ${({ isInvalid }) => isInvalid ? '1px solid #FF7563' : `1px solid ${detailColorLight2}`};

  .icon-input {
    width: 20px;
    height: 20px;
  }

  @media(min-width: 1550px){
    padding: 1.389vw 1.528vw;
  }
`;

export const StyledInput = styled(Form.Control)`
  width: 100%;
  height: 60px;
  color: ${colorTextDark} !important;
  font-family: 'Biennale', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  padding: 4px;

  outline: none;
  /* transition: all 0.4s ease; */
  background-color: ${backgroundTertiary} !important;

  padding: 0 !important;
  border: none;

  ::placeholder {
    color: ${colorTextDark};
  }

  &:focus,
  &:focus-visible {
    color: ${colorTextDark};
    border-color: ${backgroundTertiary};
    text-shadow: none;
  }

  &:active {
    color: ${colorTextDark};
  }

  ::-webkit-input-placeholder { /* Edge */
    color: ${colorTextDark};
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${colorTextDark};
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: ${colorTextDark};
  }

  // Change colors input autocomplete
  &:-webkit-autofill {
    border: 1px solid ${backgroundTertiary} !important;
    -webkit-box-shadow: 0 0 0 50px ${backgroundTertiary} inset !important;
    -webkit-text-fill-color: ${colorTextDark} !important;
  }


  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${colorTextDark};
  }
`;
