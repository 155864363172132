import styled from 'styled-components';
import { Link } from 'react-router-dom';
import windowSize from '../../../../constants/WindowSize';
import variables from '../../../../../_metronic/variables.scss';

export const ContainerDesafio = styled.div`
text-decoration: none;
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 14px 18px;
border-radius: 16px;
background: ${({theme}) => theme.colors.primary};
color: ${variables.backgroundColorLightPrimary};
border-radius: 15px;
font-family: 'Biennale Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 1px;
height: 95px;
z-index: 1;

img {
  margin-left: 16px;
}
`;

export const ContainerDesafioLink = styled(Link)`
text-decoration: none;
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 16px;
border-radius: 16px;
background: ${({theme}) => theme.colors.primary};
color: ${variables.backgroundColorLightPrimary};
border-radius: 15px;
font-family: 'Biennale Regular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 1px;
height: ${({width}) => width > windowSize.SM ? '110px' : '72px'};

&:hover {
  color: ${variables.backgroundColorLightPrimary};
}

`;

export const SectionLeft = styled.div`
display: flex;
flex: 1;
flex-direction: row;
align-items: center;
justify-content: center;

img {
  margin-right: 12px;
}

div {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  margin-top: 6px;
  > h5 {
    font-family: 'Biennale Regular';
    font-style: normal;
    font-weight: 600;
    flex: 1;
    font-size: ${({width}) => width ? '18px' : '14px'};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

 > h6 {
   font-size: 12px;
 }
}
`;

export const SectionRight = styled.div`
width: 200px;
margin-left: 10px;
display: flex;
flex-direction: row;
align-items: center;


`;

export const ButtonContainerDesafio = styled.button`
cursor: pointer;
display: flex;
width: 140px;
height: 50px;
justify-content: center;
align-items: center;
background: ${({ theme }) => theme.colors.secondary};
border: 1px solid ${({ theme }) => theme.colors.primary};
border-radius: 12px;
color: ${variables.backgroundColorLightPrimary};
z-index: 10000;

span {
font-size: 14px;
font-weight: 600;
/* margin-bottom: -4px; */
}
`;
export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  border-radius: 19px;
  background: transparent;
  border: 0 none;
  margin-left: 12px;
  z-index: 10000;

 & > img {
  margin: 0px;

  &:hover {
    transition: 0.2s;
    opacity: 0.8;
   }
 }
`;
