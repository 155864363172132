import Rubens from "../../assets/images/testimony_photos/rubens.svg";
import Eliane from "../../assets/images/testimony_photos/eliane.svg";
import Dorfman from "../../assets/images/testimony_photos/dorfman.svg";
import RafaelOliveira from "../../assets/images/testimony_photos/rafael_oliveira.svg";
import Tonete from "../../assets/images/testimony_photos/tonete.svg";
import LucasVenturini from "../../assets/images/testimony_photos/lucas_venturini.svg";

export const CardsTestimonialsDescriptions = [
  {
    id: 1,
    description:"“No começo, tive muito medo de que isso desse errado (mudar de carreira). E entrar para a área de tecnologia é totalmente diferente das outras. <strong>Mas o pessoal aqui é muito acolhedor, eles te ajudam, não te menosprezam. E tem uma comunidade enorme, muitos alunos realmente são incríveis, e os professores eu não tenho nem o que falar, de tão bom que eles são. </strong>Com a Devaria, eles garantem a oportunidade de um futuro melhor! ”",
    name: "<strong>Rubens Pinheiro</strong>",
    img: Rubens
  },
  {
    id: 2,
    description: "“Eu estou em transição de carreira e iniciei uma nova formação em desenvolvimento web na Devaria. A experiência tem sido incrível, com cursos bem estruturados, profissionais qualificados e o suporte notável da equipe. <strong>O ambiente colaborativo da Devaria me permitiu interagir com os outros alunos e ampliar a minha rede de contatos, além de participar de projetos em equipe.</strong> Recomendo a Devaria como uma instituição de ensino de qualidade! ”",
    name: "<strong>Eliana de Lima Henriqueta</strong>",
    img: Eliane
  },
  {
    id: 3,
    description: "“Foi a melhor coisa que aconteceu na minha vida, sem demagogia! <strong>Os  conteúdos são  atualizados e relevantes pro mercado, com certeza aceleram a busca pela tão sonhada vaga como dev.</strong> Os caras são diferenciados, e graças à Devaria hoje tenho a possibilidade de trabalhar com minha filhota ao meu lado!”",
    name: '<strong>Raphael Dorfman</strong>',
    img: Dorfman
  },
  {
    id: 4,
    description: "“A Devaria me ajudou a descobrir o meu objetivo de vida, meu propósito. Quando descobri o mundo da programação, fiquei incrivelmente perdido e achava que programar era pra quem tinha dinheiro para pagar cursos. <strong>Com a Devaria, dei meus primeiros passos, e ainda estou dando, mas cada dia mais próximo de me tornar um bom programador.</strong> Pra mim, a metodologia é perfeita, e tudo é perfeito.”",
    name: '<strong>Rafael Oliveira</strong>',
    img: RafaelOliveira
  },
  {
    id: 5,
    description: "“Depois que conheci a Devaria tive certeza de que iria seguir na área da tecnologia! <strong> Partindo do absoluto zero em programação, a equipe de professores e profissionais maravilhosos me deram a oportunidade de uma colocação no mercado de trabalho,</strong> e hoje enxergo que minha evolução profissional está muito ligada à minha evolução pessoal. Sou muito grato por todo esse apoio, vocês são feras!”",
    name: '<strong>Gabriel Tonete</strong>',
    img: Tonete
  },
  {
    id: 6,
    description: "“Honestamente, a Devaria foi o único curso que equilibrou super bem teoria e prática, na minha opinião. <strong>Adoro a plataforma, os cursos são excelentes, relevantes, diversos, muito bem explicados e desenvolvidos.</strong> Os projetos são muito bem escolhidos e tem aplicações na vida real de um programador. Sou muito grato à Devaria! Com certeza, é um game changer para muitos de nós, que estamos entrando na programação!”",
    name: '<strong>Israel Almeida Cardoso</strong>'
  },
  {
    id: 7,
    description: "<strong> Desisti de um curso de medicina e acabei indo para o TI</strong> por influência do meu irmão e cunhada, ambos devs, que  me apresentaram a Devaria. <strong>Na minha primeira entrevista de estágio fui contratado por uma grande empresa,</strong> continuei estudando com vocês e levei a Devaria pro time. Hoje, sou um dev iniciante, mas com grandes expectativas. A base que vocês passam é tudo que se usa no mercado!",
    name: '<strong>Lucas Venturini</strong>',
    img: LucasVenturini
  },
  {
    id: 8,
    description: "Resolvi que eu queria começar no mundo da computação, e sinceramente <strong>já estava quase desistindo de procurar uma 'entrada',</strong> quando vi um ex-aluno de vocês dando um feedback super positivo, e de como ele evoluiu graças a vocês, e resolvi dar uma chance... e que bom que essa pessoa estava lá! Cheguei atrasado no curso, mas <strong>de lá pra cá já senti uma evolução monstruosa como dev!</strong> Muito obrigado pela iniciativa de vocês!",
    name: '<strong>Rafael Silva</strong>'
  },
]
