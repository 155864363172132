import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    background-color: #0A0A0A;
    border: ${({ colorPrimary }) => `2px solid ${colorPrimary};`};
    width: 416px;
    width: 28.889vw;
    /* min-height: ${({ heightContainer }) => heightContainer};
    max-height: ${({ heightContainer }) => heightContainer}; */
    border-radius: 1.111vw;

    @media(min-width: 768px) {
        margin-top: ${({ margiTop }) => margiTop};
    }
    @media(max-width: 769px) {
        /* max-width: 416px; */
        width: 86.667vw;
        border-radius: 12px;
        height: 100%;
    }

    h1 {
        margin: 0;
    }

    .melhor-custo {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3bd42dbf;
        border-radius: 0.5357142857142857vw 0.5357142857142857vw 0px 0px;
        gap: 12px;
        height: 3.334vw;

        @media(max-width: 768px) {
            border-radius: 9px 9px 0 0;
            height: 48px;
        }
    }

    .melhor-custo svg {
        height: 2.4vw;
        width: 2.4vw;

        @media(max-width: 768px) {
            height: 32px;
            width: 32px;
        }
    }

    .melhor-custo span {
        color: #F0F5FF;
        font-family: 'Biennale Black';
        font-size: 1.66667vw;

        @media(max-width: 768px) {
            font-size: 20px;
            font-family: 'Biennale Heavy';
        }
    }

    .title {

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.66667vw 0;

        @media(max-width: 768px) {
          padding: 16px 0;
        }
    }

    .title h1 {
        font-family: 'Biennale Black';
        font-size: 2.22222vw;
        font-weight: 900;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #F0F5FF;
        margin-bottom: 1.11111vw;

        @media(max-width: 768px) {
            font-size: 28px;
            margin-bottom: 8px;
        }
    }

    .preco {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .preco span {
        font-family: 'Biennale Regular';
        font-size: 0.97222vw;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #EB5757;
        text-align: initial;
        width: 70%;
        margin-bottom: 5px;

        @media(max-width: 768px) {
            font-size: 14px;
            width: ${({ freemium, advanced }) => freemium ? (advanced ? '215px' : '235px') : (advanced ? '224px' : '243px')};
        }
    }

    .preco span text {
        text-decoration: line-through;
    }
    .preco .preco-sem-desconto {
      font-family: 'Biennale Bold' !important;
      font-size: 1.944445vw;
      font-weight: 900 !important;

      @media(max-width: 768px) {
          font-size: 24px;
      }
    }

    .preco .recompensa{
      background-color: #B2881C;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 1vw 0;
      padding-top: 10px;
      font-size: 14px;
    }

    .preco .recompensa .recompensa-conteudo{
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      @media(max-width: 768px) {
        width:250px;
    }
    }
    .preco .recompensa .recompensa-conteudo div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    .preco .recompensa .recompensa-conteudo div .recompensa-texto{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
    .preco .recompensa .recompensa-conteudo div .recompensa-texto h5{
      color: #F0F5FF !important;
      font-family: 'Biennale Black';
      font-weight: 900;
      font-style: normal;
      text-align: left;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0 !important;
    }
    .preco .recompensa .recompensa-conteudo div .simbolo{
      gap: 0 !important;
    }
    .preco .recompensa .recompensa-conteudo .avista{
      color: #F0F5FF !important;
      width: 100%;
      text-align: center;
      font-size: 18px;
    }

    .preco strong {
        font-family: 'Biennale Black';
        font-weight: 900;
        letter-spacing: 0em;
        text-align: center;
        font-size: 3.8163387000596303vw;
        color: #F0F5FF;
        text-align: initial;
        /* line-height: 64px; */
        display: flex;
        gap: 8px;
        width: 70%;
        align-items: center;
        justify-content: center;

        @media(max-width: 768px) {
            font-size: 56px;
            width: 100%;
            justify-content: center;
        }
    }

    .preco strong .simbolo strong {
        font-size: 1.944445vw;

        @media(max-width: 768px) {
            font-size: 24px;
        }
    }

    .preco strong .valor strong {
        line-height: 1;
        font-size: 4.444442vw;
        font-family: 'Biennale Heavy';

        @media(max-width: 768px) {
            font-size: 50px;
        }
    }
    .preco strong .valor-concorrente, .preco strong .valor, .preco .recompensa .recompensa-conteudo div .valor{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .preco strong .valor-concorrente strong {
        line-height: 1.2;
        font-size: 3.333vw;
        margin: 0 0 0.5555556vw;
        text-align: center;

        @media(max-width: 768px){
            font-size: 38px;
        }
    }

    .preco strong .mes {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        text-wrap: nowrap;
    }

    .preco strong .mes strong {
        font-size: 1.2vw;
        font-family: 'Biennale SemiBold';
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;

        @media(max-width: 768px) {
            font-size: 16px;
        }
    }

    .preco .avista {
        color: #F0F5FF !important;
        font-family: 'Biennale SemiBold';
        display: flex;
        font-size: 1.25vw;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        justify-content: center;
        margin: 0.5555556vw 0 1.11111vw;

        @media(max-width: 768px) {
            margin: 5px 0 8px;
            font-size: 16px;
        }
    }

    .preco .avista-concorrente{
        color: #F0F5FF !important;
        font-family: 'Biennale SemiBold';
        display: flex;
        font-size: 1.25vw;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: center;
        justify-content: center;
        margin: 0.5555556vw 0 1.11111vw;
        height: 19px;

        @media(max-width: 768px) {
            margin: 5px 0 8px;
            font-size: 16px;
        }
    }

    .title .proxima-turma {
        color: #EB5757;
        text-decoration: underline;
        font-family: 'Biennale Black';
        font-size: 1.66667vw;
        font-weight: 900;
        letter-spacing: 0em;
        text-align: center;

        @media(max-width: 768px) {
            font-size: 16px;
        }
    }

    a {
        width: 88%;
        height: 4.445vw;
        background-color: ${({ colorPrimary }) => colorPrimary};
        color: ${({ colorSecondary }) => colorSecondary};
        border-radius: 0.7vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        font-family: 'Biennale Bold';
        font-size: 1.389vw;
        font-weight: 900;
        letter-spacing: 0em;
        text-align: center;

        @media(max-width: 768px) {
            height: 56px;
            font-size: 16px;
            border-radius: 8px;
            width: 88%;
        }

    }

    .listCheck {
        padding: 0 1.66667vw;
        margin-bottom: 1.43vw;
        display: flex !important;
        flex-direction: column;


        flex: 1;

        @media(min-width: 768px) {
            gap: 0.690846vw;
        }

        @media(max-width: 769px) {
            gap: 8px;
            padding: 0 8px;
            margin-bottom: 16px;
        }
    }

    .listCheck .conteudo {
        display: flex;
        gap: 8px;

    }

    .listCheck .conteudo span, li span {
        color: #F0F5FF;
        font-size: 1.11111vw;
        line-height: 1.945vw;
        font-family: 'Biennale Bold';

        @media(max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .listCheck .conteudo svg {
        min-width: 1.111vw;
        width: 1.111vw;
        position: relative;

        @media(min-width: 1380px) {
            margin-top: 0.277778vw;
        }

        @media(max-width: 768px) {
            min-width: 12px;
            width: 12px;
            margin-top: 0;
        }
    }

    .listCheck .conteudo svg path {
        fill: #F0F5FF;
    }

    .listCheck .conteudo span strong {
        color: #3BD42D;
        font-family: 'Biennale SemiBold';
    }

    .cta-proxima-turma {
        margin: 0 1.667vw 1.667vw;
        width: auto;

        @media(max-width: 768px) {
            margin: 0 16px 16px;
        }
    }

    .not-found {
        width: 80%;
        height: auto;
        margin-bottom: 1.5vw;

        @media(max-width: 768px) {
            width: 70%;
        }
    }

    .not-found path {
        fill: #DCDCDC;
    }

    ul {
        padding: 0 1.4285714285714286vw 0 2.857142857142857vw;
        color: #DCDCDC;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        @media(max-width: 768px) {
            padding: 0 24px;
        }
    }

    ul li {
        font-family: 'Biennale';
        font-size: 1.11111vw;

        @media(max-width: 768px) {
            font-size: 14px;
        }
    }

`;
