import React, { useState, useEffect } from 'react';
import { EnefApiService } from '../../services/EnefApiService';
import IsValidEmail from "../../utils/validators/IsValidEmail";
import { Content } from './styles';
import { InputCustom } from '../InputCustom';
import formatTel from '../../utils/FormatTel';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'reactstrap';
import Swal from 'sweetalert2';

export const ContactModal = ({ isOpen, onClose }) => {
  const api = new EnefApiService();
  const [isLoading, setIsLoading] = useState(false);

  const [nome, setNome] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  useEffect(() => {
    resetInputField();
  }, [onClose])

  const formIsValid = () => {
    const isValid =
      !!nome &&
      !!empresa &&
      !!email &&
      IsValidEmail(email) &&
      !!telefone;

    return isValid;
  }

  const resetInputField = () => {
    setNome('')
    setEmpresa('')
    setEmail('')
    setTelefone('')
  };

  async function handleSubmit() {
    try {
      setIsLoading(true);
      await api.makeHttpRequest({
        method: 'POST',
        url: '/contact',
        data: {
          nome,
          empresa,
          email,
          telefone: telefone.replace(/\D/g, "")
        }
      });

      setIsLoading(false);
      Swal.fire({
        title: "Sucesso!",
        text: "Mensagem enviada com sucesso.",
        icon: "success",
        heightAuto: false,
        timer: 10000
      });
    } catch (error) {
      Swal.fire({
        title: "Erro!",
        text: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : "Erro interno, tente mais tarde",
        icon: "error",
        heightAuto: false,
        timer: 10000
      });
    } finally {
      setIsLoading(false);
      resetInputField();
    }
  }

  return (
    <Modal
      size='lg'
      centered
      className="my-modal"
      isOpen={isOpen}
      style={{ maxWidth: '640px', width: '100%' }}
    >
      <Content>
        <h4 className='mb-custom-24'>Preencha os campos abaixo e nossos especialistas entrarão em contato ;)</h4>

        <div className='inputs'>
          <div className="field inputContent">
            <InputCustom
              img="images/icons/icon-contact-user.png"
              type="text"
              name="nome"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>

          <div className="field inputContent">
            <InputCustom
              img="images/icons/icon-contact-business.png"
              type="text"
              name="empresa"
              placeholder="Empresa"
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
            />
          </div>

          <div className="field inputContent">
            <InputCustom
              img="images/icons/icon-contact-email.png"
              type="text"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={email && !IsValidEmail(email)}
            />
          </div>

          <div className="field inputContent">
            <InputCustom
              img="images/icons/icon-phone.png"
              type="text"
              name="telefone"
              placeholder="Celular (DDD)"
              value={formatTel(telefone)}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </div>
        </div>

        <button
          type="submit"
          className="send-button-modal"
          onClick={() => handleSubmit()}
          disabled={!formIsValid()}
        >
          {!isLoading ? (
            "Enviar"
          ) : (
            <Spinner animation="border" role="status" />
          )}
        </button>

        <button
          className='close-button-modal'
          onClick={onClose}
        >
          <img src="images/icons/icon-close-modal.png" alt="ícone de fechar modal" />
        </button>
      </Content>
    </Modal>
  )
}
