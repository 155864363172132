import axios from "axios";
import { EnefApiService } from "../services/EnefApiService";

export const REGISTER_URL = "api/usuario";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
const api = new EnefApiService();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: '/login',
    method: 'POST',
    data: {
      Login: email,
      Senha: password,
      grantType: 'credential'
    }
  });
}

export async function resendEmail(email) {
  return api.makeHttpRequest({
    url: '/usuario/resend-mail/'+email,
    method: 'PUT',
  });
}

export async function loginGoogle(email) {

  return api.makeHttpRequest({
    url: '/login',
    method: 'POST',
    data: {
      Login: email,
      grantType: 'social'
    }
  });
}

export async function verificaEmail(email) {
  return api.makeHttpRequest({
    url: `/usuario/verificar-email-login?login=${email}`,
    method: 'GET',
  });
}

export async function register(
  email,
  nome,
  senha,

  campaign,
  content,
  medium,
  source,
  term,
  UtmId,

  loginGoogle,
  // googleId,
  imgUserGoogle,
  aceiteDevstars,
  isBuy,

  envioCodigoConfirmacao = false
  ) {

  const response = await api.makeHttpRequest({
    url: '/usuario',
    method: 'POST',
    data: {
      email,
      nome,
      senha,
      campaign,
      content,
      medium,
      source,
      term,
      UtmId,
      LoginGoogle: loginGoogle,
      GoogleUserImg: imgUserGoogle,
      aceiteDevstars,
      IdBuyTreinamento: +isBuy,
      envioCodigoConfirmacao: envioCodigoConfirmacao || false
    }
  });
 return response;
}

export async function updatePassword({
  currentPassword,
  password,
}) {

  const response = await api.makeHttpRequest({
    url: `/usuario/update-password`,
    method: 'PUT',
    data: {
      SenhaAtual: currentPassword,
      NovaSenha: password,
    },
  });
  return response;
}

export async function updateUser({
  email,
  nome,
  linkdIn,
  gitHub,
  imgAvatar,
  AceiteDevstars,
  loginGoogle,
  imgUserGoogle,
  userAddress
}) {

  const bodyFormData = new FormData();
  imgAvatar && bodyFormData.append('Avatar.File', imgAvatar);
  email && bodyFormData.append('Email', email);
  nome && bodyFormData.append('Nome', nome);
  linkdIn && bodyFormData.append('LinkedIn', linkdIn);
  gitHub && bodyFormData.append('GitHub', gitHub);
  loginGoogle && bodyFormData.append('LoginGoogle', loginGoogle);
  imgUserGoogle && bodyFormData.append('GoogleUserImg', imgUserGoogle);

  userAddress.address && bodyFormData.append('userAddress.address', userAddress.address);
  userAddress.city && bodyFormData.append('userAddress.city', userAddress.city);
  userAddress.complement && bodyFormData.append('userAddress.complement', userAddress.complement);
  userAddress.neighborhood && bodyFormData.append('userAddress.neighborhood', userAddress.neighborhood);
  userAddress.number && bodyFormData.append('userAddress.number', userAddress.number);
  userAddress.state && bodyFormData.append('userAddress.state', userAddress.state);
  userAddress.zipcode && bodyFormData.append('userAddress.zipcode', userAddress.zipcode);
  bodyFormData.append('AceiteDevstars', AceiteDevstars);


  const response = await api.makeHttpRequest({
    url: `/usuario/perfil`,
    method: 'PUT',
    data: bodyFormData,
  });
  return response;
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return api.makeHttpRequest({
    url: '/usuario/me'
  });
};
