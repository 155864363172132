import styled from "styled-components";

export const Container = styled.div `
    border: 0.5px solid #B0A4FF;
    border-radius: 12px;
    background-color: #0A0A0A;

    .topico {
        display: flex;
        background-color: #B0A4FF;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 14px;
        border: none;
        /* border-radius: 12px; */
        border-radius: ${({ expandir }) => expandir ? '12px 12px 0 0' : '12px'};
    
        @media(max-width: 768px){
            padding: 6px 16px;
        }
    }

    .topico h1 {
        margin: 0;
        font-family: 'Biennale Black';
        font-size: 18px;
        line-height: 28px;
        font-weight: 900;
        letter-spacing: 0em;
        text-align: left;
        color: #0A0A0A;

        @media(max-width: 768px){
            font-size: 14px;
        }

    }

    .topico svg {
        /* width: 1.389vw; */
        @media(max-width: 768px) {
            width: 20px;
            min-width: 20px;
        }
    }

    .conteudo {
        display: flex;
        flex-direction: column;
        padding: 16px 22px;
        
        @media(max-width: 768px){
            padding: 8px 16px;
        }
    }

    .conteudo hr {
        background: #B0A4FF;
        height: 0.5px;
        border: 0;
        width: 100%;
        margin: 16px 0;

        @media(max-width: 768px){
            margin: 8px 0;
        }
    }

    .duvidas-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .duvida {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #0A0A0A;
        border: none;
        width: 100%;
        padding: 0;
    }

    .duvida h1 {
        color: #B0A4FF;
        font-family: 'Biennale Medium';
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;

        @media(max-width: 768px){
            font-size: 14px;
        }
    }

    .conteudo span {
        font-family: 'Biennale Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        color: #F0f5ff;
        
        @media(max-width: 768px){
            font-size: 14px;
            line-height: 24px;
        }
    }

    .conteudo svg {
        font-size: 18.3px;
        fill: #B0A4FF;
        min-width: fit-content;
    }
`;