import React from "react";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { SelectInstancia } from "../../../app/partials/layout/components/SelectInstancia"

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">

        {/* <UserNotifications
          skin="light"
          iconType=""
          icon="flaticon2-bell-alarm-symbol"
          type="primary"
          dot="false"
        /> */}

        
        <SelectInstancia />

        <LanguageSelector iconType="" />

        <UserProfile showBadge={true} />
      </div>
    );
  }
}
