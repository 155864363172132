import styled from "styled-components";

export const ContainerInstancia = styled.div`
    display: flex;
    align-self: center;
    margin: 0 10px;

    span {
        font-size: 14px;
        margin-right: 6px;
        display: flex;
        align-self: center;
        font-family: 'Biennale SemiBold';
    }

    select {
        /* padding: 4px 5px 0; */
        border: 1px solid #cccccc;
        border-radius: 5px;
        display: flex;
        text-align: center;
        height: 30px;
        width: 80px;
        font-family: 'Catesque Regular';
        cursor: pointer;
    }
`;
