import styled from 'styled-components';
import {
  colorTextDark,
  primaryColor3,
} from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(max-width: 768px) {
    flex-direction: column;
  }

  .content-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.07vh;

    h2, p {
      font-family: 'Biennale Bold';
      color: ${colorTextDark};

      margin: 0;
    }

    h2 {
      width: 100%
    }

    p {
      font-family: 'Biennale';

      strong {
        font-family: 'Biennale bold';
        color: ${({ colorHighlight }) => colorHighlight ?? primaryColor3 };
      }

      @media(max-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .mb-custom-16 {
      margin-bottom: 16px;
    }

    .content-section-desc {
      margin-bottom: 56px;
    }

    .content-training-candidates {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .content-img {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          max-width: 1280px;
          margin-bottom: 56px;


          @media(max-width: 768px) {
            margin-bottom: 24px;
          }

          @media(max-width: 520px) {
            width: 100%;
            max-width: 375px;
          }
        }
      }
    }
  }
`;
