import styled from 'styled-components';
import { colorTextDark,
  primaryColor1,
  primaryColor3 } from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(min-width: 769px){
    flex-direction: row;
  }


  .content-section {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media(min-width: 1728px){
      gap: ${({ students }) => students && `2.314vw !important;`};
    }

    @media(min-width: 769px){
      gap: 40px;
    }

    @media(max-width: 768px){
      margin-top: 0;
      flex-direction: column;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    gap: 2.07vh;

    .mb-custom {
      margin-bottom: 16px;
    }



    @media(max-width: 768px){
      width: 100%;
      align-items: flex-start;
      margin: 0 0 24px 0;
    }

    h2, h3, h4, h5, p, span, strong {
      font-family: 'Biennale';
      color: ${colorTextDark};
      text-align: left;
      width: 100%;

      margin: 0;

      @media(max-width: 768px){
        text-align: left;
      }
    }

    h2 {
      font-family: 'Biennale Bold';
      font-style: normal;
      font-weight: 900;

    }

    p {
      font-family: 'Biennale Regular';
      font-style: normal;
      font-weight: 400;

      span{
        font-family: 'Biennale';
      }

      strong {
        font-family: 'Biennale Bold';
        color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
      }

    }

    .customizable-text-p-3 {
    }
  }

  .container-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: ${({ students }) => students && `16px`};

    @media(min-width: 1728px) {
      margin-top: ${({ students }) => students && `0.925vw`};
    }

    @media(max-width: 768px) {
      align-items: center;
      margin-top: ${({ students }) => students && `24px`};
    }
  }
`;

export const ContainerDescription = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    gap: 48px 0;

    @media(max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
      width: 100%;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }

    .container-description-cards {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      width: 82.222vw;

      @media(min-width: 1728px) {
        gap: ${({ students }) => students && `2.314vw !important;`};
      }

      @media(min-width: 1201px) {
        ${({ students }) => students && `
          flex-wrap: wrap;
          gap: 40px 0;
        `};
      }

      @media(max-width: 1200px) {
        ${({ students }) => students && `
          flex-direction: column;
          width: 100%;
          align-items: center;
          gap: 28.5px 0;
        `};
      }

      ${({ students }) => !students && `
        @media(min-width: 1025px) {
          flex-wrap: wrap;
          gap: 40px 0;
        }

        @media(max-width: 1024px) {
          flex-direction: column;
          width: 100%;
          align-items: center;
          gap: 28.5px 0;
        }
      `};
    }

    .cards-section {
      width: 700px;
      height: 200px;
    }

    h2, h3, h4, h5, p, span, strong {
      color: ${colorTextDark};
      text-align: left;

      margin: 0;
    }

      h2 {
        font-family: 'Biennale Bold';
        font-style: normal;
        font-weight: 900;
        font-size: 3.692rem;
        line-height: 7.272vh;
      }

      p {
        font-family: 'Biennale' !important;
        width: 100%;

        strong {
          font-family: 'Biennale Bold';
          color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
        }
      }

      .swiper {
        display: flex;
        align-items: center;
        padding-bottom: 20px;


        @media(max-width: 768px) {
          width: 312px;
          padding-bottom: 32px;
        }
      }

      .swiper-wrapper {
      }

      .swiper-slide {
        display: flex !important;
        width: 100%;
      }

      .swiper-pagination {
        margin: 32px 0 0 !important;
        bottom: 0 !important;

        @media(max-width: 768px) {
          margin: 16px 0 0 !important;
        }

        span {
          width: 16px;
          height: 16px;

          @media(max-width: 768px) {
            width: 8px;
            height: 8px;
          }
        }

        .swiper-pagination-bullet {
          opacity: 1;
          background-color: ${({ colorHighlight }) => colorHighlight ?? 'white'} !important;
        }

        .swiper-pagination-bullet-active {
          background-color: #F0F5FF !important;
        }
      }
`;
