import styled from "styled-components";
import windowSize from "../../constants/WindowSize";
import { Link } from "react-router-dom";

import variables from '../../../_metronic/variables.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${variables.secondaryColor1+'4d'};

  padding: 0 6.67%;

  @media (min-width: 769px) {
    padding: 0 5.556vw;
    flex-direction: row;
    height: 96px;
    min-height: 96px
  }

  @media(min-width: 1441px){
    padding: 0 5%;
  }

  .logo {
    width: 155px;
    height: 32px;
    margin-right: 40px;
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .menu .search {
    max-width: 420px;
    width: 100%;
    height: 48px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.tertiary};
    font-family: 'Biennale';
    font-style: italic;
    padding: 0 12px;
    display: flex;
    align-items: center;
    margin-right: 40px;

    .isIconMobile {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      background-color: red;
    }

    svg,
    path {
      width: 34px;
      height: 34px;
      margin-right: 15px;
      stroke: ${({ theme }) => theme.colors.tertiary};
    }

    input {
      width: 100%;
      border: 0;
      background: transparent;
      font-size: 15px;
      color: ${({ theme }) => theme.colors.tertiary};
      font-family: 'Biennale Regular';
      font-style: italic;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.tertiary};
        font-style: italic;
      }
    }
  }

  .searchMobile {
    display: flex;
    width: 100%;
    margin: 0 0 15px;
    animation: searchAnimation .2s;

    .search {
      max-width: 420px;
      width: 100%;
      height: 40px;
      border-radius: 12px;
      border: 1px solid ${({ theme }) => theme.colors.tertiary};
      padding: 0 12px;
      display: flex;
      align-items: center;

      svg,
      path {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        stroke: ${({ theme }) => theme.colors.tertiary};
      }

      input {
        width: 100%;
        border: 0;
        background: transparent;
        font-size: 14px;
        font-weight: 400 !important;
        color: ${({ theme }) => theme.colors.tertiary};

        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: 400 !important;
          color: ${({ theme }) => theme.colors.tertiary};
        }
      }
    }

    .menu-mobile {
      display: flex;
      width: 115px;
      justify-content: space-between;

      button {
        border: 0;
        background: transparent;
        padding: 0;
      }

      a svg path {
        stroke: ${({ theme }) => theme.colors.tertiary};
      }

      svg {
        width: 24px;
      }
    }
  }

  @keyframes searchAnimation {
    from {
      transform: translateY(-15px);
    }
    to {
      transform: translateY(0);
    }
  }

  .checkout {
    margin-right: 0;
    display: flex;
    align-items: center;
    width: 20px;

    @media(min-width: 768px){
      margin-right: 16px;
      width: 24px;
    }
  }

  .checkout svg {
    &:hover {
      opacity: 0.5;
    }
  }

  .checkout svg path {
    stroke: ${({ theme }) => theme.title === 'light' && variables.primaryColor1}
  }
`;

const PatternItem = `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: pre;

  z-index: 10;
  padding: 20px 0;

  @media(max-width: 769px) {
    color: white;
    padding: 10px;
  }
  cursor: pointer;

  span {
    font-size: 16px;
    pointer-events: none;

    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    margin-right: 42px;
    font-family: "Biennale Regular";
    color: ${variables.colorTextDark};
    text-decoration: none;
  }

  @media(max-width: 1024px) {
    span {
      font-size: 12px;
    }

    svg {
      font-size: 24px;
    }
  }

  transition: all 0.15s linear;
  &:hover {
    color: ${variables.primaryColor3};
    // transform: scale(1.05);
  }
`;

export const LinkItemsAula = styled.label`
  ${PatternItem};
  margin: 0;
  white-space: pre;
`;

export const NavbarLink = styled(Link)`
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: "Biennale Regular";
  padding: 16px 24px;

  color: ${({ selected }) => selected ? variables.secondaryColor1 : variables.colorTextDark};
  background: ${({ selected }) => selected ? variables.transparencia1 : ''};
  border-radius: ${({ selected }) => selected ? '8px' : ''};

  display: inline-block;

  &:hover {
    color: ${variables.secondaryColor1};
  }

  &::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${({ selected }) => !selected ? variables.secondaryColor1 : '' };
    transition: 0.2s;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 1024px) {
    font-size: 1.5vw;
  }
`;

export const Navbar = styled.h1`
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: "Biennale Regular";
  padding: 16px 24px;

  color: ${({ selected }) => selected ? variables.secondaryColor1 : variables.colorTextDark};
  background: ${({ selected }) => selected ? variables.transparencia1 : ''};
  border-radius: ${({ selected }) => selected ? '8px' : ''};
  cursor: default;
  margin: 0;
  display: inline-block;

  &:hover {
    color: ${variables.secondaryColor1};
  }

  &::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${({ selected }) => !selected ? variables.secondaryColor1 : '' };
    transition: 0.2s;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 1024px) {
    font-size: 1.5vw;
  }
`;

export const HeaderLinkTagA = styled.a`
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: "Biennale Regular";
  color: ${variables.colorTextDark};
  padding: 16px 24px;

  display: inline-block;

  &:hover {
    color: ${variables.secondaryColor1};
  }

  &::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${({ selected }) => !selected ? variables.secondaryColor1 : '' };
    transition: 0.2s;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 1024px) {
    font-size: 1.5vw;
  }
`;

export const NavButtonHeader = styled(Link)`
  font-family: "Biennale Regular";
  font-size: 16px;
  font-weight: 600;

  color: ${variables.secondaryColor1};
  background-color: transparent;

  padding: 14px 24px;

  border-radius: 8px;
  border: 1px solid ${variables.secondaryColor1};

  min-width: 111px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;

  &:hover {
    transition: 0.2s;
    color: ${variables.backgroundTertiary};
    background: ${variables.secondaryColor1};
  }
`;

export const ContainerButtonMenu = styled.button`
  cursor: pointer;
  display: flex;
  max-width: 165px;
  min-width: 125px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid ${variables.secondaryColor1};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 12px;
  height: 48px;
  margin: 0 0 0 40px;

  span {
    font-size: 16px;
    font-weight: 600;
    font-family: "Biennale Regular";
    margin-bottom: -2px;
    max-width: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: inline;
  }

  & > img.iconCrown {
    width: 25px;
    height: 25px;
    margin-left: 8px;
  }

  .icon-user-svg  {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: transparent;
  }

  &:hover {
    transition: 0.2s;
    background: ${variables.secondaryColor1 + '3b'};
    color: ${({ theme }) => (theme.title === "dark" ? variables.red : variables.backgroundColorDarkPrimary)};
  }

  & > div {
    border: 2px solid ${variables.secondaryColor1};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 8px;

    img {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: transparent;
    }
  }
`;

export const ContainerIconTheme = styled.div`
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;

  svg,
  path {
    cursor: pointer;
    stroke: ${({ theme }) => theme.title === 'dark' ? theme.colors.tertiary : theme.colors.secondary};
    stroke-width: 1.5;
    height: 30px;
    width: 26px !important;

    @media (min-width: 768px) {
      width: 30px !important;
    }
  }

  svg:hover {
    opacity: 0.5;
  }
`;

export const SubMenuAula = styled.div`

  margin-top: 4px;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  align-items: center;
  background: ${variables.transparencia1};

`;

export const ItemSubMenu = styled(Link)`
  font-size: 13px;
  font-weight: 600;
  color: var(--color-4);

  margin: 0;
  padding: 5px;
  border-radius: 0;

  width: 100%;
  padding: 5px 24px;

  &:hover {
    text-decoration: underline;
    background: var(--color-3-opacity-35);
    color: var(--color-4);
  }
`;


export const Whrapper = styled.div`
  display: flex;
  padding: ${({ width }) => (width > windowSize.SM ? "0 7.8%" : "0 6.67%")};
  flex-direction: row;
  width: 100vw;
  height: 72px;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 118px;
    margin-right: 40px;
    display: flex;
    align-items: center;

    img {
      height: 40px;

      @media (min-width: 769px){
        height: 32px;
      }

      @media(max-width: 768px){
        width: 118px;
      }
    }
  }

  .devstars g path {
    fill: var(--color-5);
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    max-width: 872px;
    width: 100%;
  }

  .menu-mobile {
    display: flex;
    gap: 12px;
    justify-content: space-between;

    button {
      border: 0;
      background: transparent;
      padding: 0;
    }

    a svg path {
      stroke: ${variables.secondaryColor1};
      stroke-width: 2.5;
    }

    svg {
      stroke-width: 2.5;
      width: 24px;
    }
  }
`;

export const ContainerBanner = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};

  .rec-slider-container {
    margin: 0;
  }

  .rec-pagination {
    display: none !important;
  }

  .rec-item-wrapper {
    height: 100%;
  }

  .rec-item-wrapper > div {
    width: 100%;
  }

  .rec-item-wrapper > div > div,
  .rec-item-wrapper > div > div > p,
  .rec-item-wrapper > div > div > p > a,
  .rec-item-wrapper > div > div > p > a > img {
    height: 100%;
  }
`;

export const ContainerMenuList = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuList = styled.div `
    position: absolute;
    background: var(--color-8);
    padding: 5px 0;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 150px;
    border: 1px solid var(--color-4-opacity-25);
    border-radius: 4px;
    text-align: center;

    a {
      padding: 4px 8px;
      cursor: pointer;   
      color: var(--color-4);
      font-family: 'Biennale SemiBold';
      font-size: 12px;

      :hover {
        background: var(--color-4-opacity-25);
      }
    }

    hr {
      border-top: 1px solid var(--color-4);
      width: 95%;
      display: flex;
      margin: 0 auto;
    }
`