import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  backgroundTertiary,
  primaryColor3
} from '../../../_metronic/variables.scss';
import { ButtonCustom } from '../../components/ButtonCustom';
import useGetWindowWidth from '../../utils/useGetWindowWidth';
import { Container, SubContainerTextCta } from './styles';

export function IntroVideo({
  textTitle,
  title,
  subTitle,
  subTitleOne,
  subTitleTwo,
  link,
  colorHighlight,
  buttonText,
  colorHighlightDefault,
  showModal,
  launchIn,
  home,
  company,
  students,
  route,
  onClick,
  styleContainerText = {},
  styleTitle = {},
  className,
}) {
  const width = useGetWindowWidth();

  return (
    <Container
      colorHighlight={colorHighlight}
      colorHighlightDefault={colorHighlightDefault}
      home={home}
      company={company}
      students={students}
      className={className}
    >
      <div className="content-section">
        <SubContainerTextCta
          colorHighlight={colorHighlight}
          colorHighlightDefault={colorHighlightDefault}
          home={home}
          company={company}
          students={students}
          style={styleContainerText}
        >
          <div className="content-text">
            {textTitle &&
            <h5 className='customizable-text-h5'>{textTitle}</h5>}

            {title &&
              <h2 className='customizable-text-h2-video styleTitle'>{title}</h2>}

            {subTitle &&
              <h3 className='customizable-text-h3-video'>{subTitle}</h3>}

            {subTitleOne &&
              <p className="mt-custom-16 customizable-text-p-video" dangerouslySetInnerHTML={{ __html: subTitleOne }} />}

            {subTitleTwo &&
              <p className="mb-custom-20 customizable-text-p-video" dangerouslySetInnerHTML={{ __html: subTitleTwo }} />}
          </div>
          {width <= 960 && (
            <div className="content-video">
              <ReactPlayer
                  pip
                  url={link}
                  controls={true}
                  config={{
                    vimeo: {
                      playerVars: {
                        origin: window.location.origin
                      }
                    }
                  }}
                  className="react-player"
                />
          </div>
          )}
          <div className="content-button">
            {showModal && <ButtonCustom
                text={buttonText}
                color={colorHighlight}
                colorText={backgroundTertiary ?? primaryColor3}
                introVideo
                onClick={showModal}
                route={route}
              />
            }
            {!showModal && !launchIn && <ButtonCustom
                text={buttonText}
                color={colorHighlight}
                colorText={backgroundTertiary ?? primaryColor3}
                introVideo
                onClick={() => onClick && onClick()}
                route={route}
              />
            }

            {launchIn &&
              <button className='launch-in' disabled>
                Lançamento oficial dia 01/02/2023
              </button>
            }
          </div>
        </SubContainerTextCta>

        {width > 960 && (
            <div className="content-video" >
            <ReactPlayer
                  pip
                  url={link}
                  controls={true}
                  config={{
                    vimeo: {
                      playerVars: {
                        origin: window.location.origin
                      }
                    }
                  }}
                  className="react-player"
                />
          </div>
        )}
      </div>
    </Container>
  )
}
