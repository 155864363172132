import { EnefApiService } from "./EnefApiService";

const api = new EnefApiService();

// ############
//  GET
// ############
export const getSaibaMais = async () =>
    await api.makeHttpRequest({ url: '/site/registration' });

export const getSaibaMaisProximaTurma = async () =>
    await api.makeHttpRequest({ url: '/site/registration-classes' });

export const getTurmaTrilha = async (turmaId, classId = false) =>
    await api.makeHttpRequest({ url: `/carreira/turma/${turmaId}?typeClassId=${classId}` });

//  => GET (PAINEL)
export const getCarreiraTrilha = async (carreiraId) =>
    await api.makeHttpRequest({ url: `/carreira/perfil/mais1code/${carreiraId}` });

export const getTurmasEmpresa = async () =>
    await api.makeHttpRequest({ url: `/site/turma-list` });

export const getTurmaHeaderTabela = async (turmaId) =>
    await  api.makeHttpRequest({ url: `/site/accompaniment/headers?turmaId=${turmaId}` });

export const getModulesByTraining = async (trainingId) =>
  await api.makeHttpRequest({ url: `/site/modulos-by-treinamento/${trainingId}` });

export const getFreemium = async () =>
  await api.makeHttpRequest({ url: `/site/freemium` });

export const getTurmaRowsTabela = async ({
    turmaId=0,
    subTurmaId=0,
    orderBy='id',
    filterString='',
    perPage=25,
    page=1,
    OrderByDirection=''
}) => await  api.makeHttpRequest({
    url: `/site/accompaniment?turmaId=${turmaId}&subTurmaId=${subTurmaId}&orderBy=${orderBy}&OrderByDirection=${OrderByDirection}&filterString=${filterString}&page=${page}&perPage=${perPage}`
});

export const getQuantidadeVagasEmpresa = async ({ turmaId }) =>
    await api.makeHttpRequest({ url: `/site/vagas-disponiveis/${turmaId}` });

export const getTurmasAtivas = async () =>
    await api.makeHttpRequest({ url: '/turma/turmas-ativas'});

export const getDescricao = async (videoId) =>
    await api.makeHttpRequest({ url: `/site/description/${videoId}`});

export const getExportTurma = async (filter) =>
    await api.download(
      { url: `/site/accompaniment/export?turmaId=${filter.turmaId}&subTurmaId=${filter.subTurmaId}&FilterString=${filter.search}` },
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' );

// ############
//  POST
// ############

//  => POST (PAINEL)

export const postPreSelecionado = async ({
    turmaId,
    candidatoId,
    isContrato
}) => await api.makeHttpRequest({
    method: "POST",
    url: `/vagas/candidatos/selecao`,
    data: {
      candidatoId: candidatoId,
      turmaId: turmaId,
      isContrato: isContrato
    }
});




// UPDATE
