import styled from 'styled-components';

import windowSize from '../../../../constants/WindowSize';
import variables from '../../../../../_metronic/variables.scss';

export const ContainerModal = styled.div`
    position: absolute;
    z-index: 11;
    width: 70%;
    max-height: 80%;
    min-height: 560px;
    background: ${variables.backgroundTertiary};
    border: 2px solid ${variables.devariaBorderColor};
    border-radius: 12px;
    top: 50%;
    left: 50%;
    overflow: auto;
    transform: translate(-50%, -50%);
    padding: 40px 32px 32px;
    -webkit-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    -moz-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
`;

export const Code = styled.div`
  width: 100%;
  max-width: 990px;
  margin: 0;

  @media(min-width: 769px){
    padding: 0 25px 0 0;
  }

  code {
    background-color: transparent;
    color: white;
    font-family: 'Fira Code' !important;
    font-size: 1rem !important;
    padding: 0;
  }
`;


export const ContainerQuestions = styled.div`
   margin: 40px 0 0;
`;

export const ContainerAlternatives = styled.div`
   margin: 32px 24px;

`;

export const MessageIsIncorrectSwered = styled.span`
  color: ${({isCorrectSwered}) => isCorrectSwered === 'success' ? variables.primaryColor3 : ' #FF7563'};
  font-family: 'Biennale Regular';
  `;

export const MenssageError = styled.p`
  color: #FF7563;
  font-family: 'Biennale Regular';
  margin: 7px;
  font-size: 14px;
`;


export const ContainerAlternativesMobile = styled.div`
   margin: 12px 8px;
`;

export const ContainerHeaderModal = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  align-items: flex-start;


  & > h2 {
    color: ${({theme}) => theme.colors.primary};
    /* margin-bottom: 18px; */
    margin: 0;
    }

  & > button {
    border: 1.5px solid ${({theme}) => theme.colors.primary};
    background: transparent;
    padding: 4px 22px;
    border-radius: 8px;
    font-family: 'Biennale Regular';
    font-size: 12px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.primary};

    &:hover {
      transition: 0.2s;
      border: 1.5px solid ${({theme}) => theme.colors.primary};
      background: ${({theme}) => theme.colors.primary};
      color: ${variables.backgroundColorLightPrimary};
    }
  }

  & > p {
     color: ${({theme}) => theme.colors.text};
     display: -webkit-inline-box;
     width: 85%;
     font-size: 20px;
     margin: 0;
     font-family: 'Biennale SemiBold';
   }

  h4 > div > p {
    width: 90%;
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    width: 144px;
    gap: 5px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    & > span.questionValue {
      color: ${({theme}) => theme.colors.text};
      font-size: 16px;
      font-family: 'Biennale Regular';
    }

    strong {
      font-family: "Biennale Bold";
      color: ${variables.primaryColor3};
      margin-left: 18px;
      font-size: 16px;
    }
  }

`;

export const Alternative = styled.button`
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: 18px;
   background-color: ${variables.transparencia1};
   padding: 12px;
   border-radius: 8px;
   display: flex;
   border: ${({isSelected, isIncorrectSwered, isCorrectSwered}) =>
   isIncorrectSwered ? '2px solid #FF7563' :
   isCorrectSwered ? `2px solid ${variables.primaryColor3}` :
   isSelected ? `2px solid ${variables.primaryColor3}` :
   `2px solid ${variables.devariaBorderColor}`};

   ${({isSwered}) => !isSwered && `
      &:hover {
        transition: 0.2s;
        border: 2px solid ${variables.primaryColor3};
      }
    `};

    & > span {
      margin-top: 6px;
     color: #FF7563;
     font-size: 12px;
    }

    & > span.success {
     color: ${variables.primaryColor3};
    }

   & > p {
    margin: 0;
    color: ${({theme}) => theme.colors.text};
    font-size: 16px;
    font-family: 'Biennale Regular';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    text-align: justify;
    width: 100%;
   }
   p > span {
    /* text-align: initial; */
   }

`;

export const AlternativeMobile = styled.button`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: 100%;
   margin-top: 16px;
   background-color: ${variables.transparencia1};
   padding: 8px;
   border-radius: 6px;
   display: flex;
   border: ${({isSelected, isIncorrectSwered, isCorrectSwered}) =>
   isIncorrectSwered ? '2px solid #FF7563' :
   isCorrectSwered ? `2px solid ${variables.primaryColor3}` :
   isSelected ? `2px solid ${variables.primaryColor3}` :
   `1px solid ${variables.devariaBorderColor}`};

   & > p {
    margin: 0;
    color: ${({theme}) => theme.colors.text};
    font-size: 12px;
    font-family: 'Biennale Regular';
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    align-items: flex-start;
    width: 100%;

     & > strong {
     color: ${({theme}) => theme.colors.text};
     margin: 2px 6px 0 0;
    }
   }

  & > span {
     text-align: start;
     margin-top: 6px;
     color: #FF7563;
     font-size: 12px;
     font-family: 'Biennale Regular';
    }

    & > span.success {
     color: ${variables.primaryColor3};
    }

`;

export const FileComponent = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 18px;
  flex-direction: column;

  @media(min-width: 768px){
    flex-direction: row;
    margin-top: 56px;
    height: 64px;
  }

  span {
    height: 55px;
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 12px;
    background: ${({ theme }) => theme.title === 'dark' ? variables.backgroundColorDarkPrimary : variables.backgroundColorLightSecondary};
    border: 1px solid ${({ theme }) => theme.title === 'dark' ? variables.detailColorLight4 : variables.backgroundColorLightTertiary};
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    padding: 14px 16px;

    opacity: ${({ disabledFile }) => disabledFile ? 0.3 : 1 };

    @media(min-width: 768px){
      font-size: 16px;
      padding: 16px;
      height: 64px;
    }
  };

  input {
    display: none;
  };

  .btn-pathFile, label {
    height: 50px;
    color: ${variables.colorTextDark};
    background: ${variables.primaryColor2};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
    gap: 20px;
    opacity: ${({ disabledFile }) => disabledFile ? 0.3 : 1 };
    cursor: ${({ disabledFile }) => disabledFile ? 'not-allowed' : 'pointer'};

    @media(min-width: 768px){
      height: 55px;
      font-size: 16px;
      height: 64px;
      width: 280px;
    }
  };

  .btn-pathFile svg, label svg {
    width: 20px;
    height: 20px;

    @media(min-width: 768px){
      width: 24px;
      height: 24px;
    }
  };
`;

export const Extensões = styled.div`
  p {
    font-family: 'Biennale';
    font-size: 14px;
    line-height: 32px;
    margin: ${({ margin }) => margin};
    color: ${variables.primaryColor1};
  }
`;

export const MensagemEspera = styled.p`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin: 24px 0;
  font-size: 16px;
  line-height: 22px;

  @media(min-width: 768px){
    font-size: 18px;
    line-height: 32px;
  }
`;

export const FeedBack = styled.div`
    margin: 24px 0;

    h2 {
      font-family: 'Biennale Regular';
      font-size: 16px;
      margin-bottom: 16px;
      color: ${variables.primaryColor3};

      @media(min-width: 768px){
        font-size: 18px;
        line-height: 22px;
      }
    }

    p {
      font-family: 'Biennale Regular';
      font-size: 16px;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.text};

      @media(min-width: 768px){
        font-size: 18px;
        line-height: 32px;
      }
    }
`;

export const Button = styled.button`
    text-align: center;
    margin: ${({margin}) => margin};
    border: 1.5px solid ${({theme, color}) => color ?
    color : theme.colors.primary};
    background: ${({theme, highlight, color}) => highlight ?
    'transparent' : color ? color : theme.colors.primary};
    padding: 10px 18px;
    width: ${({mobile}) => mobile ? '100%' : '98px'};
    border-radius: 8px;
    font-family: 'Biennale Regular';
    font-size: 14px;
    font-weight: 600;
    color: ${({highlight, theme, color}) => !highlight ?  variables.backgroundColorLightPrimary :
    color ? color : theme.colors.primary};

    svg {
      transform: rotate(90deg);
      margin: auto 0 !important;
     }

    &:hover {
      transition: 0.2s;
      background: ${({theme, highlight, color, isLoadingCompleteChallenge}) =>
       isLoadingCompleteChallenge ? 'transparent' : !highlight ?
      'transparent' : color ? color : theme.colors.primary};
      color: ${({highlight, theme, color}) => highlight ?  variables.backgroundColorLightPrimary :
      color ? color : theme.colors.primary};
   }
`;

export const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* margin: ${({mobile}) => mobile ? '0 8px' : '0 24px'}; */


  button {
    font-family: 'Biennale SemiBold';
    font-size: 16px;
    border-radius: 12px;
    padding: 14px 32px;
  }

  .btn-next {
    background-color: transparent;
    color: ${variables.primaryColor3};
    border: 1px solid ${variables.primaryColor3};

    :hover {
      color: ${variables.colorTextDark};
      background-color: ${variables.primaryColor3};
    }
  }

  .btn-back {
    background-color: transparent;
    color: ${variables.primaryColor1};
    border: 1px solid ${variables.primaryColor1};

    :hover {
      color: ${variables.colorTextDark};
      background-color: ${variables.primaryColor1};
    }
  }
  .btn-next {
    background-color: transparent;
    color: ${variables.primaryColor3};
    border: 1px solid ${variables.primaryColor3};

    :hover {
      color: ${variables.colorTextDark};
      background-color: ${variables.primaryColor3};
    }
  }


/*
  & > div {
   & > button {
    margin-left: 18px;
    border: 1.5px solid ${({theme}) => theme.colors.primary};
    background: ${({theme}) => theme.colors.primary};
    background: transparent;
    padding: 10px 18px;
    width: 98px;
    border-radius: 8px;
    font-family: 'Biennale Regular';
    font-size: 14px;
    font-weight: 600;
    color: ${variables.backgroundColorLightPrimary};

    &:hover {
      transition: 0.2s;
      background: transparent;
      background: ${({theme}) => theme.colors.primary};
      color: ${({theme}) => theme.colors.primary};
    }
   }

   & > button.returnButton {
    border: 1.5px solid ${({theme}) => theme.colors.primary};
    background: transparent;
    color: ${({theme}) => theme.colors.primary};

    &:hover {
      transition: 0.2s;
      background: ${({theme}) => theme.colors.primary};
      color: ${variables.backgroundColorLightPrimary};
    }
   }

   & > button.buttonComplete {
    border: 1.5px solid ${variables.primaryColor3};
    background: ${variables.primaryColor3};

    &:hover {
      transition: 0.2s;
      background: transparent;
      color: ${variables.primaryColor3};
    }
   }
  } */
`;

export const ContainerModalExit = styled.div`
    display: flex;
    ${({width}) => width <= windowSize.SM && 'width: 80%'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: ${variables.backgroundTertiaryLight};
    border: 2px solid ${({theme, type}) => type === 'success' ? variables.primaryColor3 :
    type === 'error' ? ' #FF7563' : variables.devariaBorderColor};
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 22px;
    -webkit-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    -moz-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);

    h4 {
    color: ${({theme, type}) => type === 'success' ?  variables.primaryColor3 :
    type === 'error' ? ' #FF7563' : theme.colors.primary};
    margin-bottom: 18px;
    }

    p {
    text-align: center;
    max-width: 310px;
    font-size: 16px;
    color: ${({theme}) => theme.colors.text};
    margin-bottom: 18px;
    }
`;

export const ContainerModalMobile = styled.div`
    position: absolute;
    width: 90%;
    height: 85%;
    background: ${variables.backgroundTertiary};
    border: 2px solid ${variables.devariaBorderColor};
    border-radius: 12px;
    top: 50%;
    left: 50%;
    overflow: scroll;
    transform: translate(-50%, -50%);
    padding: 16px;
    -webkit-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    -moz-box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);
    box-shadow: 6px 9px 14px -4px rgba(0,0,0,0.39);

    h2 {
    color: ${({theme}) => theme.colors.primary};
    margin-bottom: 18px;
    }
`;

export const ContainerHeaderModalMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > h5 {
    font-size: 20px;
    margin: 8px 8px 16px 0;
    color: ${({theme}) => theme.colors.primary};
  }

  & > button {
  position: absolute;
  border: 0 none;
  padding: 6px;
  border-radius: 28px;
  background: transparent;
  right: -16px;
  top: -16px;

    &:hover {
     transition: 0.2s;
     opacity: 0.7;
   }
  }
`;

export const ContainerHeaderQuestions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: 14px;
    margin-bottom: 14px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.text};

    font-family: 'Biennale Regular';
   & > strong {
    color: ${variables.primaryColor3};
    }
  }

  & > span.nameQuestion {
    font-size: 16px;
    display: flex;
    margin-bottom: 14px;
    color: ${({theme}) => theme.colors.text};
    font-family: 'Biennale Regular';
  }


`;
