import MedalhaOuro from '../assets/medalhas/Medalha-Ouro.svg';
import MedalhaPrata from '../assets/medalhas/Medalha-Prata.svg';
import MedalhaBronze from '../assets/medalhas/Medalha-Bronze.svg';
import MedalhaMérito from '../assets/medalhas/Medalha-Mérito.svg';
import MedalhaDesabilitada from '../assets/medalhas/Medalha-Desabilitada.svg';

const perPageDevstars = process.env.REACT_APP_PERPAGE_DEVSTARS ?? 30;

export function getMedalha(position){
    if(position === 1) 
        return MedalhaOuro;
    else if(position === 2)
        return MedalhaPrata;
    else if(position === 3)
        return MedalhaBronze;
    else if(position >= 4 && position <= perPageDevstars)
        return MedalhaMérito;
    else
        return MedalhaDesabilitada;
}