import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  DesafioSet: "[DesafioEspecial.Set] Action"
};

const INITIAL_STATE = {
  desafio: {}
}

export const desafioEspecialReducer = persistReducer(
  { storage, key: "desafioEspecial" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.DesafioSet: {
        return { ...state, desafio: action.payload };
      }
      default:
        return state;
    }
  }
);

export const desafioEspecialActions = {
  setDesafio: desafio => ({ type: actionTypes.DesafioSet, payload: desafio })
};