import variables from '../../_metronic/variables.scss';

export default {
  title: 'dark',
  mainColor: variables.primaryColor1,
  yellowish: variables.primaryColor2,
  disabledButton: variables.disableButton,
  colors: {
    background: {
      primary: variables.backgroundColorDarkPrimary,
      secondary: variables.backgroundColorDarkSecondary,
      tertiary: variables.backgroundColorDarkTertiary,
    },
    primary: variables.primaryColor1,
    secondary: variables.primaryColor2,
    tertiary: variables.primaryColor3,
    text: variables.colorTextDark
  }
};
