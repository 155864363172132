import React from 'react';
import { Container, ContainerTag } from './styles';

function ButtonCustom({
  text,
  color,
  onClick,
  isPago,
  isTag,
  colorText,
  width,
  introVideo,
  route
 })
  {
  return (
    isTag
      ?
      <ContainerTag
        color={color}
        colorText={colorText}
        className="buttonPadrao"
        isPago={isPago}
        width={width}
        introVideo={introVideo}
        onClick={onClick}
        href={route}
      >
        {text}
      </ContainerTag>
      :
      <Container
        color={color}
        colorText={colorText}
        className="buttonPadrao"
        isPago={isPago}
        width={width}
        introVideo={introVideo}
        onClick={onClick}
        href={route}
      >
        {text}
      </Container>
  );
}

export { ButtonCustom };
