import React from 'react';
import { Container } from './styles';

import Timer from '../../../../pages/aulas/trainingListing/Componentes/Timer';
import moment from 'moment';


function LaunchBanner({setShowModal}){
    const [isOpen, setIsOpen] = React.useState(false);
    const dataLancamento = "2022-09-12T00:00:00";

    setInterval(() => {
        if(moment(dataLancamento).isBefore(Date.now()) && !isOpen){
            setIsOpen(true)
        }
    }, [1000])

    return(
        <Container>
            <div className="name-lancamento">
                <h1>A Devaria acredita na democratização<br/><strong>do acesso à educação!</strong></h1>
            </div>
            <div className="name-lancamento">
             <h2><strong>Bolsas de estudo</strong> de até <strong>40%</strong> <br/>para alunos de baixa renda</h2>

            </div>
            <div className="timer">
              <span onClick={() => setShowModal(true)}>Saiba mais</span>
            </div>
        </Container>
    );
}

export { LaunchBanner };
