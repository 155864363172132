import styled from 'styled-components';
import { footerColorTertiary } from '../../../_metronic/variables.scss';

export const Separator = styled.span`
  border: 1px solid ${({separatorColor}) => separatorColor ?? 'white'};
  transform: rotate(360deg);
  height: 72px;
`;

export const Container = styled.div`
  min-width: 21.805vw;
  max-width: 22.638vw;
  min-height: 10.277vw;

  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 1920px) {
    max-width: 13.855vw;
    min-width: 266px;
  }

  @media(min-width: 1728px) {
    max-width: 15.40vw;
    min-width: 266px;
  }

  @media(max-width: 768px) {
    min-width: 312px;
  }

  .card-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 1.666vw;
    gap: 16px 0;

    @media(max-width: 1200px) and (min-width: 1024px) {
      min-height: 23.376vh;
    }

    @media(max-width: 768px) {
      min-height: 0;
      min-width: 33.947vw;
      gap: 16px;
      padding: 0;
    }

    @media(max-width: 540px) {
      min-width: 47.777vw;
    }

    @media(max-width: 360px) {
      min-width: 75vw;
    }

    img {
      width: 48px;
    }

    p {
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 400;

      max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
      text-align: center;
      padding: ${({ padding }) => padding ?? '0'};

      @media(max-width: 768px) {
        padding: ${({ paddingMobile }) => paddingMobile ?? '0'};
      }

      strong {
        color: #fff !important;
        font-weight: 600;
      }
    }
  }

  .mb-custom-20 {
  }
`;
