import React, { useEffect, useState } from 'react';
import moment from 'moment';

function Timer({ dataInscricaoFinal }) {
    const [timer, setTimer] = useState('00:00:00')

    const formatHour = (duration) => {
        const timer = duration._data
        if(timer.months > 0){
            return `${timer.months}M:${timer.days}D ${timer.hours}`
        }
        else {
            return `${timer.days}D ${timer.hours}`
        }
    }

    useEffect(() => {
        if (dataInscricaoFinal) {
            const end = moment(dataInscricaoFinal);
            setInterval(() => {
                var duration = moment.duration(-(moment(new Date()).diff(end)));
                var formated = formatHour(duration) + moment.utc(duration.asMilliseconds()).format(":mm:ss")
                setTimer(formated)
            }, [1000])
        }
    }, [dataInscricaoFinal])

    return (
        <strong>{timer}</strong>
    );
}

export default Timer;
