import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  InstanciaSet: "[Instancia.Set] Action"
};

const INITIAL_STATE = {
  instancia: process.env.REACT_APP_INSTANCIA
}

export const instanciaReducer = persistReducer(
  { storage, key: "instancia" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.InstanciaSet: {
        return { ...state, instancia: action.payload };
      }
      default:
        return state;
    }
  }
);

export const instanciaActions = {
  setInstancia: instancia => ({ type: actionTypes.InstanciaSet, payload: instancia })
};