import Moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Menu, MenuItem, withStyles } from "@material-ui/core";

import {
  Container,
  ContainerButtonMenu,
  HeaderLinkTagA,
  ItemSubMenu,
  NavButtonHeader,
  NavbarLink,
  SubMenuAula,
  Whrapper
} from "./styles";

import WindowSize from "../../constants/WindowSize";
import ThemeContext from "../../context/theme";
import {
  getDesafioById,
  getDesafioEspecial,
  getDesafioHistorico
} from "../../services/DesafiosService";
import { actionTypes } from "../../store/ducks/auth.duck";
import { getMedalha } from "../../utils/getMedalhaDevStars";
import useGetWindowWidth from "../../utils/useGetWindowWidth";
import DesafioEspecial from "./Components/DesafioEspecial";

import { ReactComponent as IconAvatarDark } from "../../assets/icons/icon_avatarDark.svg";
import { ReactComponent as IconAvatarLight } from "../../assets/icons/icon_avatarLight.svg";
import logoDevariaDark from "../../assets/logo/logo-devaria-02.svg";

import { CMSService } from "../../../app/services";

import { ReactComponent as IconAdminLight } from "../../assets/icons/icon_admin_light.svg";
import { ReactComponent as IconBurger } from "../../assets/icons/icon_burger.svg";
import { ReactComponent as IconClassesMenu } from "../../assets/icons/icon_classesMenu.svg";
import { ReactComponent as IconLogOut } from "../../assets/icons/icon_logout.svg";
import { ReactComponent as IconStarDLight } from "../../assets/icons/icon_star_light.svg";
import { ReactComponent as IconFaleConoscoGreen } from "../../assets/icons/icons_submenu/Icon_fale_conosco_green.svg";
import { ReactComponent as DevariaLogoLight } from "../../assets/logo/logo-devaria-light.svg";

import { isBefore } from "date-fns";
import { FiSettings, FiShoppingBag } from "react-icons/fi";
import { MdLocalGroceryStore } from "react-icons/md";
import variables from "../../../_metronic/variables.scss";
import { ERoles } from "../../enums/Roles";
import QuestionsModal from "../../pages/challenges/Components/QuestionsModal";
import { getTurmaTrilha } from "../../services/TurmaService";
import * as ModalDesafio from "../../store/modalDesafio";
import store from "../../store/store";
import { LaunchBanner } from "./Components/LaunchBanner";
import { ScholarshipModal } from "../ScholarshipModal";
import { useQuery } from "../../utils/useQuery";

export default function Header({ pathName, colorSubHeader, searchValue }) {
  const history = useHistory();
  const width = useGetWindowWidth();
  const dispath = useDispatch();
  const query = useQuery();
  const [imageLogoB2b, setImageLogoB2b] = useState("");

  const nameDevstars = process.env.REACT_APP_DEVSTARS_NAME || "Devstars";
  const routerDevstars = process.env.REACT_APP_DEVSTARS_ROUTER || "devstars";
  const isWatchLink = window.location.pathname;

  const { themeSelect } = useContext(ThemeContext);
  const { user } = useSelector(state => state.auth);
  const { desafioId, isOpen, onComplete } = useSelector(
    state => state.modalDesafio
  );

  const isAdmin = user?.perfil?.nome;
  const isAdminOrCompany =
    user?.perfil.nome === "ADMIN" || user?.perfil.nome === "COMPANY";
  const isVerzelOrAdmin =
    user?.perfil.nome === "ADMIN" || user?.perfil.nome === "VERZEL";

  const [desafioEspecial, setDesafioEspecial] = useState(null);
  const [showDesafioBanner, setShowDesafioBanner] = useState(false);
  const [hoverButtonMenu, setHoverButtonMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewAulasMobile, setViewAulasMobile] = useState(false);
  const [showScholarShipModal, setShowScholarShipModal] = useState(false);

  const [challengeIsSelected, setChallengeIsSelected] = useState();

  const [loadingModal, setLoadingModal] = useState(false);

  let desafioEspecialClosedStr = localStorage.getItem("desafioEspecialClosed");
  let desafioEspecialClosed = JSON.parse(desafioEspecialClosedStr);
  const userClass = user?.subClassId || user?.classId;

  const turmaId = query.get("turmaId");

  const logOut = useCallback(() => {
    dispath({
      type: actionTypes.Logout
    });
    history.push("/");
  }, [dispath]);

  const setShowModal = value => {
    store.dispatch(
      ModalDesafio.ModalDesafioActions.setIsOpen(value, value ? desafioId : 0)
    );
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCloseBanner() {
    localStorage.setItem(
      "desafioEspecialClosed",
      JSON.stringify(desafioEspecial)
    );
    setShowDesafioBanner(false);
  }

  const getDesafio = useCallback(async () => {
    try {
      if (user) {
        const response = await getDesafioEspecial();
        if (response) {
          setDesafioEspecial(response);
        }

        if (!desafioEspecialClosed || desafioEspecialClosed?.id > response.id) {
          setShowDesafioBanner(true);
        } else {
          setShowDesafioBanner(false);
        }
      }
    } catch (error) {
      console.log("Error: ", error.response);
    }
  }, [desafioEspecialClosed, user]);

  useEffect(() => {
    getDesafio();
  }, []);

  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${variables.secondaryColor1}`,
      background: variables.backgroundTertiary,
      borderRadius: 8,
      marginTop: 12
    }
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles(theme => ({
    root: {
      color: variables.colorTextDark,
      fontSize: 14,
      fontFamily: "Biennale",
      fontWeight: 600,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 8,
      "&.MuiButtonBase-root:hover": {
        background: variables.transparencia1,
        color: variables.secondaryColor1
      }
    }
  }))(MenuItem);

  useEffect(() => {
    getChallengeList();
  }, [isOpen]);

  const getChallengeList = useCallback(async () => {
    setLoadingModal(true);
    if (user) {
      try {
        const resultDesafios = await getDesafioHistorico();
        if (resultDesafios) {
          if (desafioId) {
            const selectedByUrl = resultDesafios.find(
              i => i.desafioId === desafioId
            );
            if (selectedByUrl) {
              setChallengeIsSelected(selectedByUrl);
            } else {
              const desafio = await getDesafioById(desafioId);
              if (desafio) {
                setChallengeIsSelected(desafio);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingModal(false);
  }, [isOpen]);

  useEffect(() => {
    loadFromCMS();
  }, [themeSelect]);

  const loadFromCMS = useCallback(async () => {
    try {
      const [imageLogoB2b] = await Promise.all([
        CMSService.getContentFromYear(
          themeSelect.title === "dark" ? "logo-light" : "logo-dark",
          "slug,title,metadata"
        )
      ]);

      const logourl = imageLogoB2b?.metadata?.logo?.url;

      setImageLogoB2b(logourl);
    } catch (e) {
      console.error(e);
    }
  }, [imageLogoB2b, themeSelect]);

  const [cursosTrilha, setCursosTrilha] = useState(null);
  const isCourseStarted =
    cursosTrilha &&
    isBefore(new Date(cursosTrilha?.dataLancamento), new Date());

  async function loadDetailsFromCourse() {
    if (!isVerzelOrAdmin && !!userClass) {
      setCursosTrilha(await getTurmaTrilha(userClass, userClass === user?.classId));
    }
  }

  useEffect(() => {
    loadDetailsFromCourse();
  }, []);

  return (
    <>
      {width > WindowSize.MD ? (
        <>
          <ScholarshipModal isOpen={showScholarShipModal} onClose={setShowScholarShipModal}/>
          <Container width={width} style={{ zIndex: 11 }}>
            <QuestionsModal
              onComplete={() => {
                onComplete();
                setShowModal();
              }}
              getChallengeList={getChallengeList}
              loadingModal={loadingModal}
              challengeIsSelected={
                desafioId > 0 && desafioId == challengeIsSelected?.desafioId
                  ? challengeIsSelected
                  : null
              }
              setChallengeIsSelected={setChallengeIsSelected}
              open={isOpen && desafioId > 0}
              handleClose={() => {
                setShowModal(false);
                setChallengeIsSelected(null);
              }}
            />

            <Link to="/">
              <img
                className="logo"
                src={logoDevariaDark}
                alt="Devaria"
                styles={{ marginRight: 40 }}
              />
            </Link>

            <div className="menu">
              <NavbarLink to="/contato" selected={isWatchLink === "/contato"}>
                Contato
              </NavbarLink>

              {(user?.subClassId > 0 || isAdminOrCompany) && isCourseStarted && (
                <NavbarLink
                  selected={pathName === `/${routerDevstars}`}
                  to={
                    user?.subClassId > 0
                      ? `/${routerDevstars}?turmaId=${userClass}`
                      : `/${routerDevstars}`
                  }
                >
                  {nameDevstars}
                </NavbarLink>
              )}

              {!!user ? (
                <ContainerButtonMenu
                  hover={hoverButtonMenu}
                  onMouseOver={() => setHoverButtonMenu(true)}
                  onMouseOut={() => setHoverButtonMenu(false)}
                  onClick={e => handleClick(e)}
                >
                  <div>
                    {user?.avatar ? (
                      <img src={user.avatar} alt="Imagem Perfil" />
                    ) : themeSelect.title === "dark" ? (
                      !hoverButtonMenu ? (
                        <IconAvatarDark className="icon-user-svg" />
                      ) : (
                        <IconAvatarLight className="icon-user-svg" />
                      )
                    ) : !hoverButtonMenu ? (
                      <IconAvatarLight className="icon-user-svg" />
                    ) : (
                      <IconAvatarDark className="icon-user-svg" />
                    )}
                  </div>
                  <span>{user.nome.split(" ")[0]}</span>
                  {user?.ultimoDevstar?.posicao <= 3 && (
                    <img
                      className="iconCrown"
                      src={getMedalha(
                        user?.ultimoDevstar?.posicao,
                        Moment(
                          user?.ultimoDevstar?.ranking?.dataModificacao
                        ).format("MMM")
                      )}
                      alt="Ícone Coroa"
                    />
                  )}
                </ContainerButtonMenu>
              ) : (
                <NavButtonHeader onClick={() => history.push(`/entrar`)}>
                  Entrar
                </NavButtonHeader>
              )}

              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
              >
                {isAdmin == "ADMIN" && (
                  <StyledMenuItem
                    onClick={() => {
                      console.log("clicado");
                      history.push("/admin");
                      // handleClose();
                    }}
                  >
                    <FiSettings
                      style={{
                        stroke: "var(--detail-color-light-2)",
                        width: 22,
                        height: 22,
                        marginRight: 12
                      }}
                    />
                    Admin
                  </StyledMenuItem>
                )}

                <StyledMenuItem
                  onClick={() => {
                    history.push("/perfil");
                    handleClose();
                  }}
                >
                  <IconAdminLight
                    style={{ width: 22, height: 22, marginRight: 12 }}
                  />
                  Painel
                </StyledMenuItem>

                <StyledMenuItem
                  onClick={() => {
                    window.open(`/minhas-conquistas/${user.id}`, "_blank");
                    handleClose();
                  }}
                >
                  <DevariaLogoLight
                    style={{
                      width: 22,
                      height: 22,
                      marginRight: 12
                    }}
                  />
                  Minhas conquistas
                </StyledMenuItem>

                <StyledMenuItem
                  onClick={() => {
                    history.push("/meus-pedidos");
                    handleClose();
                  }}
                >
                  <MdLocalGroceryStore
                    size={22}
                    style={{
                      color: variables.secondaryColor1,
                      height: 22,
                      marginRight: 12
                    }}
                  />
                  Meus pedidos
                </StyledMenuItem>

                <StyledMenuItem
                  onClick={user ? logOut : () => history.push(`/entrar`)}
                >
                  <IconLogOut
                    style={{ width: 22, height: 22, marginRight: 12 }}
                  />
                  {user ? "Sair" : "Entrar"}
                </StyledMenuItem>
              </StyledMenu>

              {/* {user && getStorage?.redirectId && (
                <Link className="checkout" to="/checkout">
                  <ShoppingCart />
                </Link>
              )} */}
            </div>
          </Container>

          {isWatchLink.indexOf("assistir") === -1 &&
            isWatchLink.indexOf("checkout") === -1 &&
            showDesafioBanner &&
            desafioEspecial && (
              <Container
                width={width}
                style={{
                  border: 0,
                  background: colorSubHeader,
                  paddingTop: 56
                }}
              >
                <DesafioEspecial
                  handleCloseBanner={() => handleCloseBanner()}
                  desafioEspecial={desafioEspecial}
                />
              </Container>
            )}

        </>
      ) : (
        <>
          <Container width={width}>
            <ScholarshipModal isOpen={showScholarShipModal} onClose={setShowScholarShipModal}/>
            <QuestionsModal
              onComplete={() => {
                onComplete();
                setShowModal();
              }}
              getChallengeList={getChallengeList}
              loadingModal={loadingModal}
              challengeIsSelected={
                desafioId > 0 && desafioId == challengeIsSelected?.desafioId
                  ? challengeIsSelected
                  : null
              }
              open={isOpen && desafioId > 0}
              handleClose={() => {
                setShowModal(false);
                setChallengeIsSelected(null);
              }}
            />

            <Whrapper>
              <Link to="/" className="logo">
                <img src={logoDevariaDark} alt="Logo Devaria" />
              </Link>

              <div className="menu-mobile">
                {/* {user && getStorage?.redirectId && (
                  <Link className="checkout" to="/checkout">
                    <ShoppingCart />
                  </Link>
                )} */}

                <button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => handleClick(e)}
                >
                  <IconBurger />
                </button>

                {user ? (
                  <>
                    <StyledMenu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleClose()}
                    >
                      {isAdmin == "ADMIN" && (
                        <StyledMenuItem
                          onClick={() => {
                            history.push("/admin");
                            handleClose();
                          }}
                        >
                          <FiSettings
                            style={{
                              stroke: "var(--detail-color-light-2)",
                              width: 20,
                              height: 20,
                              marginRight: 12
                            }}
                          />
                          Admin
                        </StyledMenuItem>
                      )}

                      <StyledMenuItem
                        selected={pathName === "/perfil"}
                        onClick={() => {
                          history.push(`/perfil`);
                          handleClose();
                        }}
                      >
                        <IconAdminLight
                          style={{ width: 20, height: 20, marginRight: 12 }}
                        />
                        Painel
                      </StyledMenuItem>

                      <StyledMenuItem
                        onClick={() => {
                          history.push("/meus-pedidos");
                          handleClose();
                        }}
                      >
                        <MdLocalGroceryStore
                          size={22}
                          style={{
                            color: variables.secondaryColor1,
                            height: 22,
                            marginRight: 12
                          }}
                        />
                        Meus pedidos
                      </StyledMenuItem>

                      {(user?.subClassId > 0 || isAdminOrCompany) && (
                        <StyledMenuItem
                          selected={pathName === `/${routerDevstars}`}
                          onClick={() => {
                            history.push(
                              user?.subClassId > 0
                                ? `/${routerDevstars}?turmaId=${userClass}`
                                : `/${routerDevstars}`
                            );
                            handleClose();
                          }}
                        >
                          <IconStarDLight
                            style={{ width: 20, height: 20, marginRight: 12 }}
                          />
                          {nameDevstars}
                        </StyledMenuItem>
                      )}

                      {userClass || isVerzelOrAdmin ? (
                        <StyledMenuItem
                          onClick={() => {
                            window.open(
                              `/minhas-conquistas/${user.id}`,
                              "_blank"
                            );
                            handleClose();
                          }}
                        >
                          <DevariaLogoLight
                            style={{ width: 22, height: 22, marginRight: 12 }}
                          />
                          Minhas conquistas
                        </StyledMenuItem>
                      ) : null}

                      

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {(user?.perfil?.nome === ERoles.ADMIN ||
                          user?.perfil?.nome === ERoles.VERZEL) && (
                          <StyledMenuItem
                            selected={
                              pathName === "/aulas" ||
                              pathName === "/carreira" ||
                              pathName === "/treinamentos" ||
                              pathName === "/modulos"
                            }
                            onClick={() => {
                              setViewAulasMobile(!viewAulasMobile);
                            }}
                          >
                            <IconClassesMenu
                              style={{ width: 20, height: 20, marginRight: 12 }}
                            />
                            Aulas
                          </StyledMenuItem>
                        )}

                        {viewAulasMobile ? (
                          <SubMenuAula>
                            <ItemSubMenu
                              selected={pathName === "/carreiras"}
                              to="/carreiras"
                            >
                              Carreira
                            </ItemSubMenu>
                            <ItemSubMenu
                              selected={pathName === "/treinamentos"}
                              to="/treinamentos"
                            >
                              Treinamento
                            </ItemSubMenu>
                          </SubMenuAula>
                        ) : (
                          ""
                        )}
                      </div>

                      <StyledMenuItem
                        selected={pathName === "/contato"}
                        onClick={() => {
                          history.push("/contato");
                          handleClose();
                        }}
                      >
                        <IconFaleConoscoGreen
                          style={{ width: 20, height: 20, marginRight: 12 }}
                        />
                        Contato
                      </StyledMenuItem>

                      <StyledMenuItem
                        onClick={user ? logOut : () => history.push(`/entrar`)}
                      >
                        <IconLogOut
                          style={{ width: 20, height: 20, marginRight: 12 }}
                        />
                        {user ? "Sair" : "Entrar"}
                      </StyledMenuItem>
                    </StyledMenu>
                  </>
                ) : (
                  <>
                    <StyledMenu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleClose()}
                    >
                      <StyledMenuItem
                        onClick={user ? logOut : () => history.push(`/entrar`)}
                      >
                        <IconLogOut
                          style={{ width: 20, height: 20, marginRight: 12 }}
                        />
                        {user ? "Sair" : "Entrar"}
                      </StyledMenuItem>

                      <StyledMenuItem onClick={() => handleClose()}>
                        <FiShoppingBag
                          style={{
                            stroke: variables.secondaryColor1,
                            width: 20,
                            height: 20,
                            marginRight: 12
                          }}
                        />

                        <a
                          href="https://loja.devaria.com.br/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color:
                              themeSelect.title === "dark"
                                ? variables.colorTextDark
                                : variables.colorTextLight
                          }}
                        >
                          Loja
                        </a>
                      </StyledMenuItem>

                      <StyledMenuItem
                        selected={pathName === "/contato"}
                        onClick={() => {
                          history.push("/contato");
                          handleClose();
                        }}
                      >
                        <IconFaleConoscoGreen
                          style={{ width: 20, height: 20, marginRight: 12 }}
                        />
                        Contato
                      </StyledMenuItem>
                    </StyledMenu>
                  </>
                )}
              </div>
            </Whrapper>
          </Container>

          {isWatchLink.indexOf("assistir") === -1 &&
            isWatchLink.indexOf("checkout") === -1 &&
            showDesafioBanner &&
            desafioEspecial && (
              <Container
                width={width}
                style={{
                  border: 0,
                  background: colorSubHeader,
                  paddingTop: 40
                }}
              >
                <DesafioEspecial
                  handleCloseBanner={handleCloseBanner}
                  desafioEspecial={desafioEspecial}
                />
              </Container>
            )}
        </>
      )}
        {(isWatchLink.indexOf("matricula-antecipada") !== -1 &&
          isWatchLink.indexOf("checkout") === -1) &&
          <LaunchBanner setShowModal={setShowScholarShipModal}/>

        }
    </>
  );
}
