import React from 'react';

import { LoadingComponent } from './styles';
import logoDevariaDark from "../../assets/logo/logo-devaria-02.svg";

function LoadingPage() {
  return (
    <LoadingComponent >
      <img src={logoDevariaDark} alt="Devaria logo" />
      <div className="loader">
        <div className="ball ball1"></div>
        <div className="ball ball2"></div>
        <div className="ball ball3"></div>
      </div>
    </LoadingComponent>
  );
}

export { LoadingPage };
