import styled from 'styled-components';
import { backgroundTertiary,
    primaryColor1,
    primaryColor2,
    detailColorLight1,
    detailColorLight2,
    primaryColor3,
    devariaSecondaryColor } from '../../../_metronic/variables.scss';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #FFF;

    ${({ home }) => home && `
        min-width: 28.47vw;
        min-height: 36.36vh;
        height: 19.444vw;
        background-color: ${backgroundTertiary};
        border-radius: 16px;

        @media(max-width: 1366px) and (min-width: 1200px) {
            min-height: 42.792vh;
            max-width: 30.014vw;
            max-height: 25.25vh;
            margin: 0.83vw 0.83vw 0.83vw 0;
        }

        @media(max-width: 1366px) and (min-width: 769px) {
            padding: 4.101vh 0 2.343vh;
        }

        @media(max-width: 1200px) {
            max-width: 34.166vw;
        }

        @media(max-width: 1024px) {
            max-width: 40.03vw;
        }

        @media(max-width: 768px) {
            min-width: 40.625vw;
            min-height: 25.26vh;
            max-width: 312px;
        }

        @media(max-width: 540px) {
            min-width: 57.777vw;
        }

        @media(max-width: 360px) {
            min-height: 24.93vh;
        }

        @media(max-height: 700px) {
            height: 192px;
        }
        `
    }

    ${({ avatar }) => avatar && `
        justify-content: space-between;
        background-color: ${backgroundTertiary};
        border-radius: 16px;

        @media(min-width: 769px) {
        }
    `
    }

    ${({ company }) => company && `
        width: 23.888vw;
        height: 28.311vh;
        min-height: 28.311vh;
        max-height: 30.649vh;
        justify-content: flex-start;
        border: none;
        gap: 24px 0;

        @media(max-width: 1200px) and (min-width: 1025px) {
            min-height: 18.166vw;
            max-width: 40.03vw;
            gap: 0;
        }

        @media(max-width: 1024px) and (min-width: 769px) {
            min-height: 21.28vw;
            min-width: 30.468vw;
            gap: 0;
        }

        @media(max-width: 768px) {
            min-width: 40.625vw;
            min-height: 0px;
            width: 100%;
            height: 100%;
            gap: 8px 0;
            padding: 0 23px;
        }

        @media(max-width: 540px) {
            min-width: 57.777vw;
        }

        @media(max-width: 360px) {
            width: 100%;
            height: 20.259vh;
        }
        `
    }

    ${({ students }) => students && `
        width: 34.722vw;
        height: 17.222vw;
        justify-content: flex-start;
        border: none;
        gap: 24px 0;

        @media(min-width: 1728px) {
            width: 28.935vw;
            height: 14.351vw;
        }

        @media(min-width: 1728px) and media(max-height: 900px) {
            width: 28.935vw;
            height: 27.555vh;
        }

        @media(max-width: 1200px) and (min-width: 1025px) {
            height: 32.207vh;
            width: 41.666vw;
            gap: 0;
        }

        @media(max-width: 1024px) and (min-width: 769px) {
            height: 24.21875vw;
            width: 48.828125vw;
            gap: 0;
        }

        @media(max-width: 768px) {
            height: 26.562vw;
            min-height: 232px;
            max-height: 100% !important;
            width: 100%;
            gap: 8px 0;
            padding: 0 4px;
        }

        @media(max-width: 540px) {
            min-width: 57.777vw;
        }

        @media(max-width: 360px) {
            width: 100%;
        }
        `
    }

    ${({ teachers, mobile, imgDesktop }) => teachers && `
        border: none;
        gap: 16px 0;
        flex-direction: ${(mobile <= 768) ? `column !important;` : `row !important;`}

        ${(mobile > 768) && `
            min-height: 29vw;
            justify-content: flex-end !important;
            background-image: url(${imgDesktop});
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 16px;
            padding: 0;
            `
        }

        @media(max-width: 1024px) and (min-width: 768px) {
            align-items: center;
            height: 412px;
            width: 413px;
            gap: 8px 0;
            margin: 0 4px;
            position: relative;
        }

        @media(max-width: 768px) {
            align-items: center;
            gap: 8px 0;
            margin: 0 4px;
            position: relative;
        }

        @media(max-width: 375px) {
            align-items: center;

        }
    `
    }

    .subcontainer-image-avatar {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        ${({ home }) => home && `
            padding-top: 24px;
            padding-bottom: 16px;

            img {
                width: 80px;
                height: 80px;
            }

            @media(min-width: 1728px) {
                img {
                    width: 4.629vw;
                    height: 7,40vh;
                }
            }

            @media(max-width: 768px) {
                padding: 16px 0;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            @media(max-height: 700px) {
                padding: 2.285vh 0;
            }
            `
        }

        ${({ avatar }) => avatar && `
            display: flex;
            align-items: flex-end;
            width: 100%;
            border-radius: 16px;
            margin-top: 0;
            position: initial;

            img {
                width: 100px;
                height: 100px;
                margin-top: -50px;
            }

            @media(max-width: 768px) {
                padding: 16px 0;

                img {
                    width: 80px;
                    height: 80px;
                }
            }
            `
        }

        ${({ company }) => company && `
            img {
                max-width: 64px;
                height: 64px;

                @media(max-width: 768px) {
                    max-width: 48px;
                    height: 48px;
                }
            }

        `
        }

        ${({ students }) => students && `
            img {
                max-width: 64px;
                height: 64px;

                @media(max-width: 768px) {
                    max-width: 48px;
                    height: 48px;
                }
            }

        `
        }

        ${({ teachers }) => teachers && `
            border: none;
            align-items: center;

            img {
                width: 100%;
                max-width: 28.47222222222222vw;
                height: 28.47222222222222vw;
                border-radius: 16px;

                @media(max-width: 768px) {
                    max-width: 312px;
                    height: 312px;
                }
            }

        `
        }
    }
`;

export const SubContainerDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;

    h1, h2, h3, h4, h5, span {
        font-family: 'Biennale Bold';
        font-weight: 900;
        text-align: center;
        font-size: 1.84rem;
        margin: 0;

        @media(min-width: 1728px) {
            font-size: 1.3888vw;
        }

        ${({ home }) => home && `
            color: ${primaryColor1} !important;

            @media(max-width: 768px) {
                font-size: 1.5rem;
            }

            @media(max-height: 768px) {
                font-size: 2.5714vh;
            }

            `
        }

        ${({ company }) => company && `
            color: ${primaryColor3} !important;

            @media(max-width: 1200px) and (min-width: 1024px) {
                font-size: 1.7vw;
            }

            @media(max-width: 1024px) {
                font-size: 2.343vw;
            }

            @media(max-width: 768px) {
                font-size: 1.6667rem;
            }

            `
        }

        ${({ students }) => students && `
            color: ${devariaSecondaryColor};

            @media(max-width: 1200px) and (min-width: 1024px) {
                font-size: 2vw;
            }

            @media(max-width: 1024px) {
                font-size: 2.343vw;
            }

            @media(max-width: 768px) {
                font-size: 1.333rem;
            }

            `
        }

        ${({ teachers }) => teachers && `
            color: ${detailColorLight2};

            @media(max-width: 1200px) and (min-width: 1024px) {
                font-size: 1.7vw;
            }

            @media(max-width: 1024px) {
                font-size: 2.343vw;
            }

            @media(max-width: 768px) {
                font-size: 1.667rem;
            }
            `
        }
    }

    ${({ company }) => company && `
        gap: 16px 0;

        @media(max-width: 768px) {
            gap: 8px 0;
        }
        `
    }

    ${({ students }) => students && `
        gap: 16px 0;

        @media(max-width: 768px) {
            gap: 8px 0;
        }
        `
    }

    ${({ teachers }) => teachers && `
        align-items: center;

        @media(max-width: 768px) {
            gap: 8px;
            margin-bottom: 24px;
            position: absolute;
            bottom: 0%;
        }
        `
    }

    ${({ home }) => home && `
        @media(min-width: 1366px) {
        }

        @media(max-width: 1366px) and (min-width: 1200px) {
            min-height: 26.5vh;
        }

        @media(min-width: 769px) {
            margin: 2.07vh 2.56vw 2.33vh;
        }

        @media(max-width: 768px) {
            margin: 0 2.105vh 2.083vw;
        }

        @media(max-height: 700px) {
            margin-bottom: 2.2857vh;
        }

        `
    }

    ${({ avatar }) => avatar && `
        padding: 2.07vh 1.66vw 3.11vh;
        height: 100%;
        justify-content: flex-start;

        @media(max-width: 768px) {
            padding: 0 2.083vw 2.0779vh;
        }

        @media(max-width: 768px) {
            padding: 0 16px 16px;
        }
        `
    }

    ${({ mobile, teachers }) => (mobile > 768 && teachers) && `
        width: 100%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        gap: 16px 0;
        padding: 24px;
        `
    }

    .description-sub-container {
        display: flex;
        align-items: center;
        justify-content: center;

        ${({ home }) => home && `
            margin-top: 16px;

            @media(min-width: 1440px) {
               min-width: 24.930vw;
            }

            @media(max-width: 768px) {
                margin-top: 8px;
             }
            `
        }

        ${({ avatar }) => avatar && `
            display: flex;
            flex-direction: column;
            gap: 8px 0;
            align-items: flex-start;
        `
        }

        ${({ company }) => company && `
            min-width: 23.888vw;
            padding: 0 5px;
        `
        }

        @media(max-width: 768px) {
            padding: 0px;
        }

        p {
            font-family: 'Biennale';
            font-style: normal;
            font-weight: 400;
            text-align: center;

            ${({ home }) => home && `
                font-size: 1.38rem;
                line-height: 2.2222vw;
                text-align: center;

                strong {
                    color: ${primaryColor1};
                }
                `
            }

            ${({ avatar }) => avatar && `
                font-size: 1.3888vw;
                line-height: 26px;
                text-align: left;

                strong {
                    color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
                }
                `
            }

            ${({ name }) => name && `
                font-size: 1.538rem;
                text-align: left;

                strong {
                    color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
                }
                `
            }

            ${({ company }) => company && `
                font-size: 1.384rem;
                line-height: 1.9444vw;
                text-align: center;

                @media(min-width: 1728px) {
                    font-size: 1.0416vw;
                }

                @media(max-width: 1439px) and (min-width: 1201px) {
                    font-size: 1.25vw;
                }

                @media(max-width: 1200px) and (min-width: 1025px) {
                    line-height: 28px;
                }

                @media(max-width: 1024px) and (min-width: 769px) {
                    line-height: 2.734vw;
                }

                strong {
                    color: ${primaryColor3};
                }
                `
            }

            ${({ students }) => students && `
                font-size: 1.384rem;
                line-height: 1.9444vw;
                text-align: center;

                @media(min-width: 1728px) {
                    font-size: 1.0416vw;
                }

                @media(max-width: 1439px) and (min-width: 1201px) {
                    font-size: 1.25vw;
                }

                @media(max-width: 1200px) and (min-width: 1025px) {
                    line-height: 28px;
                }

                @media(max-width: 1024px) and (min-width: 769px) {
                    line-height: 2.734vw;
                }

                strong {
                    color: ${devariaSecondaryColor};
                }
                `
            }

            ${({ teachers }) => teachers && `
                font-size: 1.230rem;
                line-height: 1.6666vw;
                text-align: center;

                @media(min-width: 1728px) {
                  font-size: 0.925925vw;
                  margin-bottom: 16px;
                }

                @media(max-width: 1439px) and (min-width: 1201px) {
                    font-size: 1.1118vw;
                    margin-bottom: 16px;
                }

                @media(max-width: 1200px) and (min-width: 1025px) {
                    font-size: 1.1118vw;
                    line-height: 18px;
                }

                @media(max-width: 1024px) and (min-width: 769px) {
                    line-height: 2.34375vw;
                }

                strong {
                    color: ${detailColorLight2};
                }
                `
            }

            @media(min-width: 1728px) {
                ${({ home }) => home && `
                    font-size: 1.041vw;
                    `
                }

                ${({ avatar }) => avatar && `
                    font-size: 1.157vw;
                    `
                }

                ${({ name }) => name && `
                    font-size: 1.157vw;
                    `
                }
            }

            @media(max-width: 768px) {
                ${({ avatar }) => avatar &&`
                    text-align: left;
                    `
                }

                ${({ company }) => company && `
                    text-align: center;
                    `
                }
            }

            ${({ company }) => company && `
                @media(max-width: 768px) {
                    line-height: 24px !important;
                    font-size: 1.166rem !important;
                }
                `
            }

            ${({ students }) => students && `
                @media(max-width: 768px) {
                    line-height: 24px !important;
                    font-size: 1.166rem !important;
                }
                `
            }


            ${({ teachers }) => teachers && `
                @media(max-width: 768px) {
                    line-height: 24px !important;
                    font-size: 1.166rem !important;
                }
                `
            }


            ${({ home }) => home &&`
                @media(max-width: 768px) and (min-height: 770px) {
                    line-height: 24px;
                    font-size: 1.166rem;
                }

                @media(max-width: 768px) and (max-height: 769px) {
                    line-height: 3.120vh;
                    font-size: 1.820vh;
                }

                @media(max-width: 540px) and (min-width: 361px) and (max-height: 660px) {
                    line-height: 3.1168vh;
                    font-size: 2.592vw;
                }

                @media(max-width: 360px) {
                    line-height: 24px;

                }

                @media(max-height: 660px) {
                    font-size: 2vh;
                    line-height: 3.428vh;
                }
                `
            }

            ${({ avatar }) => avatar &&`
                @media(max-width: 768px) {
                    line-height: 24px;
                    font-size: 1.166rem;
                }
                `
            }
        }
    }
`;


