import styled from 'styled-components';
import {
  colorTextDark,
  primaryColor3,
} from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2, p {
      font-family: 'Biennale';
      color: ${colorTextDark};

      margin: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 900;
      font-family: 'Biennale bold';
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      font-family: 'Biennale';

      strong {
        font-family: 'Biennale Bold';
        color: ${({ colorHighlight }) => colorHighlight ?? primaryColor3 };
      }

      @media(max-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .mb-custom-16 {
      margin-bottom: 16px;
    }

    .content-best-choice {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      margin-top: 88px;

      @media(min-width: 1441px) {
        justify-content: space-evenly;
      }

      @media(max-width: 1024px) {
        margin-top: 60px;

        justify-content: center;

        flex-wrap: wrap;
        gap: 80px;
      }
    }
  }
`;
