import { HttpService } from "./HttpService";
import axios from "axios";
import store from "../store/store";
import moment from "moment";
import * as authDuck from "../store/ducks/auth.duck";
import Swal from "sweetalert2";

export class EnefApiService extends HttpService {
  constructor() {
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}/api`
    });

    super(instance);

    instance.interceptors.request.use(
      request => {
        const authData = this.getAuthData();
        const instanciaAno = this.getInstancia();
        request.headers = {
          "x-instancia-ano": parseInt(instanciaAno || new Date().getFullYear(), 10)
        };

        if (authData) {
          request.headers = {
            ...(request.headers || {}),
            Authorization: `Bearer ${authData.token}`,
            "Access-Control-Expose-Headers": "Content-Disposition"
          };
        }

        return request;
      }
    )

    instance.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const { status, data } = error.response;

        switch (status) {
          case 401:
            // let config = error.config;
            let config = Object.assign({}, error.config); // Faz uma cópia do objeto de configuração original
            let newToken = null;
            try {
              await this.refreshToken()
              newToken = this.getAuthData();
              // config.headers.Authorization =  `Bearer ${newToken.token}`;

              config.headers = {
                ...config.headers, // Copia as propriedades existentes do objeto de configuração original
                Authorization: `Bearer ${newToken.token}`,
                "Access-Control-Expose-Headers": "Content-Disposition"
              }
            } catch (e) {
              axios.Cancel();
              const utmId = localStorage.getItem('persist:utmId');
              localStorage.clear();
              localStorage.setItem('persist:utmId', utmId)
            } finally {
              if (newToken)
                return await axios(config);
              else {
                if (window.location.pathname !== '/entrar') {
                  window.location.href = "/entrar";
                }
              }
            }
            break

          case 409:
            axios.Cancel();
            const utmId = localStorage.getItem('persist:utmId');
            localStorage.clear();
            localStorage.setItem('persist:utmId', utmId)
            sessionStorage.clear();

            if(window.location.pathname !== '/entrar')
              window.location.href = "/entrar";

            break;

          default:
            return Promise.reject(error);
        }

        if (process.env.REACT_APP_DEBUG === 'true') {
          const reponsePayload = typeof data === 'string'
          ? data
          : JSON.stringify(data);

          console.error(`
            Error: ${error.message},
            StatusCode: ${status},
            Request Config: ${JSON.stringify(error.config)},
            Response Payload: ${reponsePayload}
          `);
        }
        throw error;
      }
    );
  }

  getAuthData() {
    const {
      auth: { authData }
    } = store.getState();

    return authData;
  }

  getInstancia() {
    const {
      instancia: { instancia }
    } = store.getState();

    return instancia;
  }

  async refreshToken() {
    const { auth } = store.getState();
    const { data } = await this.axios.post(
      "/login", {
        Login: auth.authData.login,
        Refresh: auth.authData.refreshToken,
        grantType: "refresh"
    })

    store.dispatch(authDuck.actions.refresh(data));
  }

  async download(reqConfig, type) {
    const response = await this.makeHttpRequest({
      ...reqConfig,
      responseType: "blob"
    });

    const blob = new Blob([response.data], { type });

    const extensions = {
      "application/pdf": "pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx"
    };

    const contentDisposition = response.headers["content-disposition"];
    let fileName = `${moment().format("YYYY-MM-DD-HH-mm-ss")}.${
      extensions[type]
    }`;
    if (contentDisposition && contentDisposition.split("filename=").length) {
      fileName = contentDisposition.split("filename=")[1];
      if (fileName) {
        fileName = fileName.trim().substr(0, fileName.indexOf(";"));
      }
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  }
}
