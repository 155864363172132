import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
    Listar: "[Calendario.Listar] Action",
    DateInitial: "[Calendario.DateInital] Action",
    InterestInitiativeData: "[Calendario.InterestInitiativeData] Action",
    Total: "[Calendario.Total] Action"
};

const INITIAL_STATE = {
    list: [],
    dateInitial: null,
    filter: {
        perPage:20,
        page:1,
        orderBy:'Id',
        orderByDirection: 'ASC',
    },
    interestInitiativeData : null,
    total: 0
}

export const calendarioReducer = persistReducer(
    { storage, key: "calendario", whitelist: ["list", "dateInitial", "filter", "interestInitiativeData", "total"] },
    (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case actionTypes.Listar: {
          return {...state, list: action.payload};
        }
        case actionTypes.DateInitial: {
          return {...state, dateInitial: action.payload};
        }
        case actionTypes.InterestInitiativeData: {
          return {...state, interestInitiativeData: action.payload};
        }
        case actionTypes.Total: {
          return {...state, total: action.payload};
        }
        default:
          return state;
      }
    }
);

export const calendarioActions = {
    listar: lista => ({ type: actionTypes.Listar, payload: lista }),
    total: total => ({ type: actionTypes.Total, payload: total }),
    dateInitial: dateInitial => ({ type: actionTypes.DateInitial, payload: dateInitial }),
    interestInitiativeData: interestInitiativeData => ({ type: actionTypes.InterestInitiativeData, payload: interestInitiativeData }),
};
