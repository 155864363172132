import React from 'react';
import IsValidEmail from "../../utils/validators/IsValidEmail";
import { InputCustom } from '../InputCustom';
import formatTel from '../../utils/FormatTel';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import * as Component from './styles';
import { ActiveCampaignPreRegistrationOfNewClasses } from '../../services/ActiveCampaign';
import { sendEmail } from '../../services/SendEmailService';

export const ActiveCampaingModal = ({ text, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [nome, setNome] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [tel, setTel] = React.useState("");
  const [confirmation, setConfirmation] = React.useState(false);

  function validateForm(){
    return (
      nome.length > 0 &&
      mail.length > 0 &&
      confirmation);
  }

  async function onSubmit(){
    setIsLoading(true);
    if(!!process.env.REACT_APP_DOMAIN_ACTIVE_COMPAIGN){
      ActiveCampaignPreRegistrationOfNewClasses({
        contactFirstName: nome,
        contactMail: mail,
        contactTel: tel,
        receiveMailContact: confirmation ? "Sim" : "Não"
      })

      await sendEmail({
        name: nome,
        email: mail,
        sendEmailAdmin: false,
        message: "Agradecemos pela sua pré-inscrição e gostaríamos de lembrar que você receberá em primeira mão as nossas novidades. Fique atento(a) às nossas comunicações!"
      })
    }
    setIsLoading(false);
    onClose()
  }

  return (
    <Component.Modal>
      <Component.Form>
        <div className="closed">
          <button
            className='close-button-modal'
            onClick={onClose}
          >
            <img src="images/icons/icon-close-modal.png" alt="ícone de fechar modal" />
          </button>
        </div>

        <h1>{text}</h1>

        <div className="inputs">
          <InputCustom
            img="images/icons/saiba-mais-user.png"
            type="text"
            name="nome"
            placeholder="Nome *"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
          <InputCustom
            img="images/icons/saiba-mais-mail.png"
            type="text"
            name="email"
            placeholder="E-mail *"
            value={mail}
            onChange={e => setMail(e.target.value)}
            isInvalid={mail && !IsValidEmail(mail)}
          />

          <InputCustom
            img="images/icons/saiba-mais-phone.png"
            type="text"
            name="telefone"
            placeholder="Celular (DDD)"
            value={formatTel(tel)}
            onChange={e => setTel(e.target.value)}
          />
      </div>

      <div className="checks">
        <div className="check">
          <Component.Check
            onClick={() => setConfirmation(!confirmation)}
          >
            {confirmation ? <Check/> : ''}
          </Component.Check>
          <span>Desejo receber contato por e-mail.</span>
        </div>
      </div>


      <button
        className='enviar'
        onClick={() => onSubmit()}
        disabled={!validateForm()}
      >
          {!isLoading
            ? 'Enviar'
           : <Spinner animation="border" role="status" />}
      </button>

      </Component.Form>
    </Component.Modal>
  )
}
