import variables from '../../_metronic/variables.scss';

export default {
  title: 'light',
  mainColor: variables.primaryColor1,
  yellowish: variables.primaryColor2,
  disabledButton: variables.disableButton,
  colors: {
    background: {
      primary: variables.backgroundTertiary,
      secondary: variables.backgroundColorLightSecondary,
      tertiary: variables.backgroundColorLightTertiary,
    },
    primary: variables.primaryColor2,
    secondary: variables.primaryColor1,
    tertiary: variables.primaryColor3,
    text: variables.colorTextLight
  }
};
