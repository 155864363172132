import styled from 'styled-components';
import variables from '../../../_metronic/variables.scss';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media(max-width: 768px) {
    align-items: flex-start;
  }

  .mb-custom-16 {
    margin-bottom: 1.1111111111111112vw;

    @media(max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  .mb-custom-32 {
    margin-bottom: 2.2222222222222223vw;

    @media(max-width: 768px) {
      margin-bottom: 32px;
    }
  }

  p {
    text-align: center;
    font-family: 'Biennale';
    strong {
      color: ${variables.devariaSecondaryColor};
    }

    @media(max-width: 768px) {
      text-align: start;
    }
  }

  .content-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .launch-in {
      width: 36.111111111111114vw;

      background-color: rgba(167, 250, 233, 0.25);
      border: none;

      font-style: normal;
      font-weight: 600;
      font-size: 1.3888888888888888vw;
      line-height: 1.6666666666666667vw;

      padding: 1.3888888888888888vw 0;

      color: #FFFFFF;
      border-radius: 0.8333333333333334vw;

      transition: all 0.2s;

      @media(max-width: 768px) {
        padding: 16px 0;

        font-size: 16px;
        line-height: 24px;

        width: 100%;
        max-width: 520px;

        border-radius: 12px;
      }
    }
  }
`;
