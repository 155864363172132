import styled from 'styled-components';
import {
    primaryColor1,
    primaryColor2,
    colorTextDark,
    backgroundTertiary } from '../../../_metronic/variables.scss';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ trail }) => trail && `
        align-items: flex-start;
    `};

    ${({ course }) => course && `
        background-color: ${backgroundTertiary};
        justify-content: flex-start;
        width: 100%;
        border-radius: 16px;
        padding: 16px 0;

        @media(max-width: 768px) {
            padding: 8px;
        }
    `};

    @media(max-width: 1366px) and (min-width: 1201px) {
        ${({ course }) => course && `
            width: 29.282vw;
        `};

        ${({ trail }) => trail && `

        `};

    }

    @media(max-width: 1200px) and (min-width: 1025px) {
        ${({ course }) => course && `
            min-height: 28.666vw;
            width: 33.333vw;
        `};

        ${({ trail }) => trail && `

        `};

    }

    @media(max-width: 1024px) and (min-width: 769px) {
        ${({ course }) => course && `
            min-height: 33.593vw;
            width: 39.0625vw;
        `};

        ${({ trail }) => trail && `

        `};
    }

    @media(max-width: 768px) {
        ${({ course }) => course && `
            min-height: 305px;
            max-height: 422px;
            width: 40.625vw;
            border: 1px solid #F0F5FF;
        `};

        ${({ trail }) => trail && `
            width: 40.625vw;
            gap: 8px;
        `};
    }

    @media(max-width: 680px) {
        ${({ course }) => course && `
            width: 312px;
        `};

        ${({ trail }) => trail && `
            width: 312px;
        `};
    }

    @media(max-width: 360px) {
        width: 100%;
    }


    .subcontainer-image-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ${({ course }) => course && `
            width: 100%;
            height: 56px;
            background-color: ${primaryColor1};
            gap: 8px;
            padding: 8px 0 8px 16px;
            border-radius: 12px 12px 0px 0px;

            @media(max-width: 768px) {
                height: 48px;
                padding: 4px 0 4px 16px;
            }
        `};

        ${({ trail }) => trail && `
            @media(max-width: 768px) {
                gap: 8px;
            }
        `};

        ${({ trail, mobile }) => (mobile > 768 && trail) && `
            @media(min-width: 769px) {
                gap: 16px;
            }
         `
        }

        h1, h2, h3, h4, h5, span {
            font-family: 'Biennale Bold';
            font-weight: 900;
            margin: 0;

            ${({ course }) => course && `
                text-align: left;
                color: ${backgroundTertiary};

                @media(min-width: 1728px) {
                    font-size: 1.3888vw;
                }

                @media(max-width: 1440px) and (min-width: 1025px) {
                    font-size: 1.6667vw;
                }

                @media(max-width: 1024px) {
                    font-size: 2.34375vw;
                }

                 @media(max-width: 768px) {
                    font-size: 1.666666666666667rem;
                }
            `};

            ${({ trail }) => trail && `
                color: ${primaryColor2};

                @media(min-width: 1728px) {
                    font-size: 1.3888vw;
                }

                @media(max-width: 1440px) and (min-width: 1025px) {
                    font-size: 1.6667vw;
                }

                @media(max-width: 1024px) {
                    font-size: 2.34375vw;
                }

                 @media(max-width: 768px) {
                    font-size: 1.333333333333333rem;
                }
            `};
        }

        img {
            ${({ course }) => course && `height: 40px`};
            ${({ course }) => course && `max-width: 40px`};

            ${({ trail }) => trail && `height: 80px`};
            ${({ trail }) => trail && `max-width: 80px`};

            @media(max-width: 768px) {
                ${({ course }) => course && `height: 40px`};
                ${({ course }) => course && `max-width: 40px`};

                ${({ trail }) => trail && `height: 48px;`};
                ${({ trail }) => trail && `max-width: 48px;`};
            }
        }
    }
`;

export const SubContainerDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ course }) => course && `
        background: rgba(37, 203, 211, 0.1);
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 0 0 16px 16px !important;
        padding-top: 16px;

        @media(max-width: 768px) {
            padding-top: 8px;
            height: auto;
        }
    `};

    ${({ trail }) => trail && `

        @media(max-width: 768px) {
        }
    `};

    .description-sub-container {
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 768px) {
            padding: 0px;
        }

        ${({ trail, mobile }) => (mobile > 768 && trail) && `
            @media(min-width: 769px) {
                padding-left: 100px;
            }
         `
        }

        p {
            font-family: 'Biennale';
            font-style: normal;
            font-weight: 400;
            text-align: left;

            strong {
                color: ${colorTextDark};
            }
        }
    }
`;


