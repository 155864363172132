import styled from 'styled-components';
import { Link } from 'react-router-dom';
import variables from '../../../_metronic/variables.scss';

export const Container = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 156px;
  /* background-color: ${variables.backgroundTertiary}; */
  display: flex;
  align-items: center;
  padding: 0 6.67%;
  font-family: 'Biennale Regular' !important;

  @media (min-width: 769px) {
    justify-content: space-between;
    padding: 50px 5.556vw;
  }

  @media(min-width: 1441px){
    padding: 0 5%;
  }

  @media(max-width: 768px){
    flex-direction: column;
    height: 240px;
  }

  > a {
      text-align: center;
        max-width: 158px;
        width: 100%;
      max-height: 84px;

    @media(max-width: 768px){
      margin-top: 48px;
    }

    @media(max-width: 375px){
      text-align: center;
    }

    > img {
      width: 100%;
    }
  }

  p {
    margin: 0;
    color: ${variables.colorTextDark};

    font-size: 14px;
    font-family: 'Biennale Regular' !important;
    font-weight: 500;
    line-height: 150%;

    @media(max-width: 768px){
      order: 3;
      text-align: center;
      font-size: 12px;
    }
  }

  > span {
    width: 90%;
    height: 1px;
    background-color: ${variables.secondaryColor1};
    position: absolute;
    top: 0;
    opacity: 30%;

    @media(max-width: 425px){
      width: 88%;
    }
  }

  .logosMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 48px 0;

    img, svg {
      width: 120px;
    }
  }
`;

 export const LinkUseTerm = styled(Link)`
    margin-top: ${({top}) => !!top ? `${top}px` : '16px'};
    color: ${variables.devariaSecondaryColor};

    font-family: 'Biennale';
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;

    text-decoration: underline;

    &:hover {
      transition: 0.2s;
      opacity: 0.8;
      color: ${({theme}) => theme.colors.primary};
    }

    @media (max-width: 768px) {
      display: none;
    }
`

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 29px;
  margin: 32px 0;

  @media(max-width: 769px){
    gap: 20px;
    margin: 35px 0;
  }

  svg {
    width: 21px;
    height: 21px;

    @media(min-width: 769px) {
      width: 28px;
      height: 28px;
    }
  }
`;
