import { EnefApiService } from "../services/EnefApiService";

const api = new EnefApiService();

export const getDesafiosPerfil = async () => {
  const response = await api.makeHttpRequest({
    url: `/desafiousuario/perfil`,
  });

  return response;
};

export const getDesafioEspecial = async () => {
  const response = await api.makeHttpRequest({
    url: `/desafiousuario/especial`,
  });

  return response;
};

export const getNextClass = async () => {
  const response = await api.makeHttpRequest({
    url: `/site/next-class`,
  });

  return response;
};

export const getNextTraining = async () => {
  const response = await api.makeHttpRequest({
    url: `/site/next-training`,
  });

  return response;
};

export const getDesafioHistorico = async () => {
  const response = await api.makeHttpRequest({
    url: `/desafiousuario`,
  });

  return response;
};

export const getDesafioById = async (desafioId) => {
  const response = await api.makeHttpRequest({
    url: `/desafiousuario/`+desafioId,
  });

  return response;
};

export const getDesafioPerguntas = async desafioid => {
  const response = await api.makeHttpRequest({
    url: '/desafiousuario/perguntas/'+desafioid,
  });

  return response;
};

export const salvaDesafio = async desafio => {
  const response = await api.makeHttpRequest({
    url: '/desafiousuario',
    method: 'POST',
    data: desafio,
  });

  return response;
};
