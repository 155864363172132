/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen } from "./_metronic";
import bsCustomFileInput from 'bs-custom-file-input';

import { ThemeProviderGlobal } from './app/context/theme';

import { ClearCacheProvider, useClearCache } from 'react-clear-cache';

bsCustomFileInput.init();
// //ReactGA.initialize("UA-167151233-1"); TODO: colocar código do GA da devaria
const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

history.listen((location) => {
  const path = buildPath(location);
  ReactGA.set({ page: path }); // Update the user's current page
  ReactGA.pageview(path); // Record a pageview for the given page
});

function getDataStorage(){
  let authStorage;
  let tokenStorage;
  let userStorage;

  if (window.localStorage.getItem('persist:auth')) {
    authStorage = JSON.parse(window.localStorage.getItem('persist:auth'))?.authData;
    
    if(!!authStorage)
      userStorage = JSON.parse(JSON.parse(window.localStorage.getItem('persist:auth'))?.user);
    if (authStorage && JSON.parse(authStorage)) {
      tokenStorage = JSON.parse(authStorage)?.token;
    }
  }

  return { authStorage, tokenStorage, userStorage };
}

export default function App({ store, persistor, basename }) {
  const token = getDataStorage()?.tokenStorage;
  const user = getDataStorage()?.userStorage;

  useEffect(() => {
    const path = buildPath(history.location);
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }, []);


  if(!!token && window.location.pathname === '/'){
    history.push('/');
  }

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  console.log('Is Versão atualizada: ' + (isLatestVersion));

  if (!isLatestVersion && emptyCacheStorage) {
    // SALVAR OS DADOS DO STORAGE NECESSARIOS EM VARIAVEIS:
    let save_data_videos = [];
    let save_data_auth = [];
    let save_data_tour = null;
    let save_data_tour_mobile = null;

    const storage_video = JSON.parse(localStorage.getItem('saveUserPositionVideo'));
    const storage_auth = JSON.parse(localStorage.getItem('persist:auth'));
    const storage_tour = JSON.parse(localStorage.getItem('tour'));
    const storage_tour_mobile = JSON.parse(localStorage.getItem('tourMobile'));

    // VIDEOS
    if(!!storage_video)
      save_data_videos.push(storage_video);

    if(!!storage_auth)
      save_data_auth.push(storage_auth);

    if(!!storage_tour)
      save_data_tour = storage_tour;

    if(!!storage_tour_mobile)
      save_data_tour_mobile = storage_tour_mobile;


    emptyCacheStorage();

    // SALVAR NO LOCALSTORAGE
    if(!!storage_video)
      localStorage.setItem('saveUserPositionVideo', JSON.stringify(save_data_videos));

    if(!!storage_auth)
      localStorage.setItem('persist:auth', JSON.stringify(save_data_auth));

    if(!!storage_tour)
      localStorage.setItem('tour', JSON.stringify(save_data_tour));

    if(!!storage_tour_mobile)
      localStorage.setItem('tourMobile', JSON.stringify(save_data_tour_mobile));
  }

  return (
    /* Provide Redux store */
    <ClearCacheProvider auto={true}>
      <Provider store={store} loading={<LayoutSplashScreen />}>
        <ThemeProviderGlobal>
          <PersistGate persistor={persistor}>
            <Router basename={basename} history={history}>
              <LastLocationProvider>
                <I18nProvider>
                  <Routes user={user} />
                </I18nProvider>
              </LastLocationProvider>
            </Router>
          </PersistGate>
        </ThemeProviderGlobal>
      </Provider>
    </ClearCacheProvider>
  );
}
