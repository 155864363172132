import factoryPurple from '../../assets/icons/factoryPurple.svg'
import increasePurple from '../../assets/icons/increasePurple.svg'
import knowledgePurple from '../../assets/icons/knowledgePurple.svg'
import laptopPurple from '../../assets/icons/laptopPurple.svg'
import promotedPurple from '../../assets/icons/promotedPurple.svg'


export const LearningTrailDescription = [
  {
    id: 1,
    img: knowledgePurple,
    title: "1. Construindo a base de conhecimento",
    description: "Essa é a fase onde construímos todo o <strong>core necessário de um bom programador</strong>: Lógica de Programação, Noções de Hardware e Redes, Banco de Dados e Conceitos de Web, entre outros principais tópicos."
  },
  {
    id: 2,
    img: laptopPurple,
    title: "2. Programador iniciante",
    description: "Com a base de conhecimento feita, <strong>passamos a trabalhar com as tecnologias que 90% das empresas utilizam, focando em JavaScript como pilar</strong> para, indiferente do candidato seguir a linha de backend ou frontend, ele já esteja capacitado a começar."
  },
  {
    id: 3,
    img: increasePurple,
    title: "3. Programador com foco em startups",
    description: "O foco aqui é conhecer tecnologias que estão em alta no mercado de startups e empresas mais novas, como <strong>Flutter, Vue.Js e Python.</strong>"
  },
  {
    id: 4,
    img: factoryPurple,
    title: "4. Programador com foco em big companies",
    description: "Empresas mais tradicionais costumam utilizar <strong>linguagens mais validadas e consolidadas no mercado, então nosso foco aqui será em C#, Java/Kotlin e Angular.</strong>"
  },
  {
    id: 5,
    img: promotedPurple,
    title: "5. Processo seletivo e soft skills",
    description: "Nessa última fase, vamos utilizar todas as nossas avaliações trimestrais, que serão realizadas com o candidato e com a empresa, e <strong>vamos entender quais deficiências o candidato tem para apoiá-lo tanto com novos estudos, como com mentorias.</strong>"
  },
]