import styled from 'styled-components';
import variables from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  //background-color: yellow;

  @media(min-width: 961px){
    flex-direction: row;
  }

  @media(max-width: 960px) {
    flex-direction: column;
  }

  .content-section {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    @media(max-width: 320px){
      margin: 0 0 50px;
    }
  }

  .content-text {
    display: flex;
    flex-direction: column;
    gap: 1.111111111111111vw 0;

    @media(max-width: 768px){
      gap: 16px 0;
    }
  }

  .content-video {
    height: 100%;
    width: 55%;
    position: relative;
    padding-top: 36.25%;
    background: url("images/svg/new_template_2023.svg") no-repeat;
    background-size: contain;

    @media(min-width: 1440px){
      margin-left: 80px;
    }

    @media(min-width: 961px) and (max-width: 1439px) {
      margin-left: 5.555vw;
    }

    @media(min-width: 961px) {
      padding-top: 32.25% !important;
    }

    @media(max-width: 960px){
      width: 100%;
      padding-top: 63%;
      margin-left: 0 !important;
    }
  }

  .react-player {
    width: 95% !important;
    height: 86% !important;
    position: absolute;
    top: 7%;
    bottom: 0;
    left: 0;
    right: 0.5%;
    /* right: 0.97rem; */

    margin: auto;
    overflow: hidden;
    border: 1px solid ${variables.colorTextDark};

    @media(min-width: 1920px) {
      width: 94% !important;
      height: 86% !important;
      right: 1.3%;
    }

    @media(max-width: 1440px){
      width: 96% !important;
      height: 86% !important;
      top: 7%;
      right: 0%;
    }

    @media(max-width: 960px){
      width: 96% !important;
      height: 85% !important;
      top: 6%;
      right: 0%;
    }
  }

  .container-button {
    display: flex;
    justify-content: center;
    width: 100%;

    @media(max-width: 960px) {
      align-items: center;
    }
  }
`;

export const SubContainerTextCta = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.222222222222222vw 0;
    gap: ${({ students }) => students && `16px 0`};

    @media(max-width: 1279px) and (min-width: 800px) {
      gap: ${({ students }) => students && `1.250977326035966vw 0`};
    }

    width: ${({ home }) => home && `45%`};
    width: ${({ company }) => company && `43%`};
    width: ${({ students }) => students && `45%`};

    @media(min-width: 961px) {
      height: 100%;
    }

    @media(max-width: 960px) {
      width: 100%;
      gap: 24px 0;
    }

    @media(max-width: 768px) {
    }

    h5 {
      color: ${({ colorHighlight }) => colorHighlight ?? variables.colorTextDark};
    }

    h3 {
      color: ${({ colorHighlight }) => colorHighlight ?? variables.colorTextDark};
    }

    p {
      font-family: 'Biennale Regular';
      font-style: normal;
      font-weight: 400;

      strong {
        font-family: 'Biennale bold';
        color: ${({ colorHighlight, colorHighlightDefault }) => colorHighlightDefault
        ? colorHighlightDefault
          : colorHighlight
            ?? variables.colorTextDark
        };
      }
    }

    .content-button {
      width: 100%;

      @media(max-width: 960px) {
        display: flex;
        justify-content: center;
      }

      .launch-in {
        width: 100%;

        background-color: rgba(59, 212, 45, 0.25);
        border: none;

        font-style: normal;
        font-weight: 600;
        font-size: 1.3888888888888888vw;
        line-height: 1.6666666666666667vw;

        padding: 1.3888888888888888vw 0;

        color: #FFFFFF;
        border-radius: 0.8333333333333334vw;

        transition: all 0.2s;

        @media(max-width: 960px) {
          padding: 16px 0;

          font-size: 16px;
          line-height: 24px;

          border-radius: 12px;
        }
      }
    }
`
