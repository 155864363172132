import agreement from "../../assets/icons/agreement.svg";
import award from "../../assets/icons/award.svg";
import bubble from "../../assets/icons/bubble_company.svg";
import laptop from "../../assets/icons/laptop.svg";
import magnifier from "../../assets/icons/magnifier.svg";
import star from "../../assets/icons/star_company.svg";

import awardStudents from "../../assets/icons/award-students.svg";
import bubbleStudents from "../../assets/icons/bubble-students.svg";
import filesStudents from "../../assets/icons/files-students.svg";
import gamesStudents from "../../assets/icons/games-students.svg";
import laptopStudents from "../../assets/icons/laptop-students.svg";
import starStudents from "../../assets/icons/star-students.svg";

export const BenefitsToCompany = [
  {
    id: 1,
    img: magnifier,
    title: "Formação completa",
    description:
      "Nosso objetivo é formar desenvolvedores de verdade, amarrando todas as pontas no ensino."
  },
  {
    id: 2,
    img: bubble,
    title: "Mentoria",
    description:
      "Profissionais qualificados para preparar os novos devs durante todo o programa."
  },
  {
    id: 3,
    img: agreement,
    title: "Match cultural",
    description:
      "Selecione os devs que combinam com a cultura da sua empresa, e nós treinamos a tecnologia."
  },
  {
    id: 4,
    img: award,
    title: "Indicadores",
    description:
      "Com a nossa plataforma você tem os indicadores dos melhores candidatos."
  },
  {
    id: 5,
    img: star,
    title: "Capacitação especializada",
    description:
      "Customizamos o programa de treinamento com conteúdos essenciais para sua empresa."
  },
  {
    id: 6,
    img: laptop,
    title: "Acompanhamento",
    description:
      "Acompanhamento do profissional contratado durante o período de formação."
  }
];

export const BenefitsToStudents = [
  {
    id: 1,
    img: awardStudents,
    title: "Vagas garantidas",
    description:
      "Alunos de destaque têm a chance de <strong>contratação durante o curso</strong>, graças às nossas parcerias com grandes empresas."
  },
  {
    id: 2,
    img: bubbleStudents,
    title: "Você não estará sozinho",
    description:
      "Além de contar com <strong>mentoria individual</strong> para tirar suas dúvidas, nossos professores vão te guiar durante toda sua jornada, com insights e dicas para a sua carreira."
  },
  {
    id: 3,
    img: starStudents,
    title: "Método aprovado por mais de 9 mil alunos",
    description:
      "A Devaria já <strong>formou mais de 9 mil alunos</strong> que estão voando no mercado de programação!"
  },
  {
    id: 4,
    img: laptopStudents,
    title: "Formação 100% online",
    description:
      "Adapte as aulas à sua rotina e <strong>estude onde e quando quiser!</strong>"
  }
];
