import styled from "styled-components";

export const Container = styled.div `
    background: ${({ isReward }) => isReward ? '#B2881C' : '#3BD42D'} ;
    color: #F0F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.380952380952381vw 4.761904761904762vw;
    gap: 1.4285714285714286vw;
    width: 100vw;
    position: relative;
    right: ${({ isReward }) => isReward ? '0' : '5.556vw'};

    @media(max-width: 768px) {
        flex-direction: column;
        padding: 16px 24px 24px;
        gap: 16px;
        right: ${({ isReward }) => isReward ? '0' : '7.67%'};
    }

    svg {
        width: 11.111vw;

        @media(max-width: 768px) {
            width: 80px;
            height: 80px;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media(min-width: 769px) {
            width: 76.111vw;
            gap: 1.111vw;
        }
    }

    .details h1 {
        font-family: 'Biennale Bold';
        font-size: 3.333vw;
        font-weight: 900;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
        line-height: 56px;

        @media(max-width: 768px) {
            line-height: 32px;
            font-size: 28px;
            text-align: center;
        }
    }

    .details span {
        font-family: 'Biennale Regular';
        font-size: 1.250vw;
        letter-spacing: 0em;
        text-align: left;
        line-height: 2.222vw;

        @media(max-width: 768px) {
            font-size: 14px;
            line-height: 24px;
            text-align: center;
        }
    }

    .details span strong {
        font-family: 'Biennale Bold';
    }

    .details span text {
        font-style: italic;
    }
`;
