import styled from "styled-components";

export const Container = styled.div`
  border-radius: 12px;
  border: 1px solid var(--color-4);
  width: ${({width}) => width || '100%'};
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  position: relative;

  span {
    position: absolute;
    font-family: 'Biennale';
    font-size: 14px;
    color: var(--color-4);
    top: -18%;
    background: var(--background-tertiary);
    padding: 0 5px;
  }

  input, select {
    outline: none;
    background-color: var(--background-tertiary);
    border: none;
    width: 100%;
    font-size: 16px;
    color: var(--color-4);
    font-family: 'Biennale';
    padding: 0;
  }

  &:focus-within {
    border: 1px solid var(--color-5);

    span {
      color: var(--color-5);
    }
  }

  @media(max-width: 768px){
    height: 48px;
    padding: 0 20px;

    span {
      font-size: 12px;
    }

    input {
      font-size:  14px;
    }
  }
`;
