import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  UtmIdSet: "[UtmId.Set] Action"
};

const INITIAL_STATE = {
  utmId: null
}

export const marketingReducer = persistReducer(
  { storage, key: "utmId" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.UtmIdSet: {
        return { ...state, utmId: action.payload };
      }
      default:
        return state;
    }
  }
);

export const marketingActions = {
  setUtmId: utmId => ({ type: actionTypes.UtmIdSet, payload: utmId })
};
