import styled from 'styled-components';
import {
  colorTextDark,
  backgroundTertiary,
  detailColorLight2
} from '../../../_metronic/variables.scss';

export const Content = styled.div`
  background: ${backgroundTertiary};
  width: 100%;
  max-width: 640px;
  padding: 24px;

  position: relative;

  border: 2px solid #F0F5FF;
  border-radius: 16px;

  .mb-custom-24 {
    margin-bottom: 24px;
  }

  h4 {
    font-family: 'Biennale Semibold';
    font-size: 28px;
    line-height: 48px;
    text-align: center;

    color: ${detailColorLight2};
  }

  .inputContent {
    margin-bottom: 24px;
  }

  .send-button-modal {
    height: 64px;
    width: 100%;
    background-color: ${detailColorLight2};
    border: 1px solid ${detailColorLight2};
    color: ${backgroundTertiary};
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : 0};

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    // VER COM A JU
    /* :hover {
      transition: ${({ isLoading }) => isLoading ? 0 : '0.3s'};
      opacity: ${({ isLoading }) => isLoading ? 1 : '0.8'};
      border: 1px solid ${props => props.theme.colors.secondary};
      border: ${({ isLoading }) => isLoading && 0};
      color: ${({ isLoading }) => isLoading ? props => props.theme.colors.ligth : props => props.theme.colors.secondary};
      background: ${({ isLoading }) => isLoading ? props => props.theme.colors.secondary : 'transparent'};
    } */

    &:hover {
      filter: brightness(1.3);
    }

    &:disabled {
      cursor: not-allowed;
      filter: brightness(1);
      opacity: 0.5;
    }
  }

  .close-button-modal {
    background: transparent;
    border: none;

    position: absolute;
    top: 14px;
    right: 14px;

    img {
      width: 18px;
    }
  }
`;


