import React, { useState, useEffect, useRef } from 'react';
import { EnefApiService } from '../../services/EnefApiService';
import IsValidEmail from "../../utils/validators/IsValidEmail";
import { Content, Container } from './styles';
import { InputCustom } from '../InputCustom';
import formatTel from '../../utils/FormatTel';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'reactstrap';
import Swal from 'sweetalert2';
import { InputWithLabel } from '../InputWithLabel';
import { GrClose } from 'react-icons/gr';
import Documento from '../../assets/icons/icon-document.svg';

import modalClose from "../../assets/icons/icon-close-white.svg";
import CMSService from '../../services/CMSService';
import useGetWindowWidth from '../../utils/useGetWindowWidth';

export const ScholarshipModal = ({ isOpen, onClose }) => {
  const api = new EnefApiService();
  const [isLoading, setIsLoading] = useState(false);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [emprego, setEmprego] = useState('');
  const [curso, setCurso] = useState('');
  const [salario, setSalario] = useState('');
  const [comprovante, setComprovante] = useState('');
  const width = useGetWindowWidth();

  useEffect(() => {
    resetInputField();
  }, [onClose])

  const formIsValid = () => {
    const isValid =
      !!nome &&
      !!email &&
      IsValidEmail(email) &&
      !!telefone &&
      !!curso &&
      !!salario &&
      !!comprovante;

    return isValid;
  }

  const resetInputField = () => {
    setNome('')
    setEmail('')
    setTelefone('')
    setCurso('')
    setSalario('')
    setComprovante('')
  };

  async function handleSubmit() {

    // const comprovanteFile = await readFile(comprovante)


    try {
      setIsLoading(true);
      const response = await CMSService.postMedia("comprovantes", comprovante);

      const body = `O candidato tem interesse em concorrer à uma vaga de bolsa. Possui emprego? ${emprego? "Sim" : "Não"}. Já fez curso de programação? ${curso? "Sim" : "Não"}. Recebe até 1 salário mínimo? ${salario? "Sim": "Não"}. Link do comprovante: ${response?.media?.url}`;

      await api.makeHttpRequest({
        method: 'POST',
        url: '/contact',
        data: {
          nome,
          email,
          telefone: telefone.replace(/\D/g, ""),
          enviarEmailAdmin: true,
          mensagem: body
        }
      });

      setIsLoading(false);
      Swal.fire({
        title: "Sucesso!",
        text: "Mensagem enviada com sucesso.",
        icon: "success",
        heightAuto: false,
        timer: 10000
      });
    } catch (error) {
      Swal.fire({
        title: "Erro!",
        text: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : "Erro interno, tente mais tarde",
        icon: "error",
        heightAuto: false,
        timer: 10000
      });
    } finally {
      setIsLoading(false);
      resetInputField();
      onClose(false)
    }
  }
  const refInputFile = useRef(null);
  const options = [{value: true, name: "Sim", key: 0}, {value: false, name: "Não", key: 1}];

  function openFileSelector() {
    refInputFile.current.click();
    return;
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      console.log(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function handleFileUpload (event) {
    if (event.target.files[0] !== undefined) {
      if (event.target.files[0].type !== 'image/png' &&
        event.target.files[0].type !== 'image/pjpeg' &&
        event.target.files[0].type !== 'image/jpeg' &&
        event.target.files[0].type !== 'application/pdf'
        ) {
        console.log("error")
        return;
      }

      const file = event.target.files[0]


      setComprovante(file)
    }
  }

  return (
    <Modal
      size='xl'
      centered
      className="my-modal"
      isOpen={isOpen}
      style={{ width: '100%', margin: 'auto' }}
    >
      <Content style={{ width: '100%', margin: 'auto' }}>
        <div className='contentInfo'>
          <h2>Caso você receba até 1 salário mínimo, <strong>preencha o formulário {width >= 769 ? "ao lado" : "abaixo"} e anexe um comprovante de renda.</strong></h2>
          <p>Acreditamos na democratização do acesso à educação. Por isso, disponibilizamos de <strong>forma gratuita um curso completo de programação</strong> e, para esta turma da Formação Devaria, teremos bolsas de estudo de até 40% de desconto para alunos de baixa renda.</p>
          <p>A escolha dos alunos que irão ganhar as bolsas de estudos será feita por <strong>ordem de recebimento do formulário preenchido corretamente.</strong></p>
        </div>

        <div className='inputs'>
          <div className="field inputContent">
            <InputWithLabel
              key={"name"}
              label="Nome completo *"
              name="nome"
              onChange={(e) => setNome(e.target.value)}
              placeholder="Digite o seu nome"
              type="text"
              value={nome}
            />
          </div>

          <div className="field inputContent">
            <InputWithLabel
             key={"email"}
              label="E-mail *"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Digite o seu e-mail"
              type="text"
              value={email}
            />
          </div>

          <div className="field inputContent">
            <InputWithLabel
              key={"telefone"}
              label="Telefone *"
              name="telefone"
              onChange={(e) => setTelefone(e.target.value)}
              placeholder="Digite o seu telefone"
              type="text"
              value={formatTel(telefone)}
            />
          </div>

          <div className="field inputContent">
            <InputWithLabel
              key={"emprego"}
              label="Você está empregado atualmente? *"
              name="emprego"
              onChange={(e) => setEmprego(e.target.value)}
              placeholder="Selecione"
              type="select"
              value={emprego}
              options={options}
            />
          </div>
          <div className="field inputContent">
            <InputWithLabel
              key={"curso"}
              label="Já fez algum curso de programação? *"
              name="curso"
              onChange={(e) => setCurso(e.target.value)}
              placeholder="Selecione"
              type="select"
              value={curso}
              options={options}
            />
          </div>
          <div className="field inputContent">
            <InputWithLabel
              key={"salario"}
              label="Você recebe até 1 salário mínimo? *"
              name="salario"
              onChange={(e) => setSalario(e.target.value)}
              placeholder="Selecione"
              type="select"
              value={salario}
              options={options}
            />
          </div>
          <div className="field inputContent" onClick={() => openFileSelector()}>
          <Container className="input-custom input-file">
            <img className="icon-input" src={Documento} alt="" />
            <span>{comprovante ? comprovante.name : "Anexe seu comprovante de renda"}</span>
            <input
              type="file"
              name="comprovante"
              placeholder="Anexe seu comprovante de renda"
              value={comprovante.originalname}
              onChange={(e) => handleFileUpload(e)}
              ref={refInputFile}
              accept="image/png, image/jpeg, image/jpg, application/pdf"
            />
          </Container>;
          </div>
        <button
          type="submit"
          className="send-button-modal"
          onClick={() => handleSubmit()}
          disabled={!formIsValid()}
        >
          {!isLoading ? (
            "Finalize sua inscrição"
          ) : (
            <Spinner animation="border" role="status" />
          )}
        </button>
        </div>


        <button
          className='close-button-modal'
          onClick={() => onClose(false)}
        >

          <img src={modalClose} alt="ícone de fechar modal" />
        </button>
      </Content>
    </Modal>
  )
}
