import styled from "styled-components";

export const ContainerLP = styled.div`
  background-color: #0A0A0A;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 2160px) {
    background-size: cover !important;
  }

  @media (min-width: 1300px) {
    background-size: cover;
    background-position-y: -60vh;
  }

  @media (min-width: 1728px) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -40vh;
  }

  @media (max-width: 768px) {
    background-color: #0A0A0A;
    background-position-y: -12vw;
    background-repeat: no-repeat;
  }

  /* font-size: clamp(1.5rem, 2vw + 1rem, 2.25rem); */
`;

export const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media screen and (min-width: 920px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CTA = styled.button`
  background-color: var(--color-3);
  color: var(--color-8);
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 1rem;
  text-align: center;
  border-radius: 8px;
  border: none;
  font-family: "Biennale SemiBold";
  max-width: 90%;
  font-size: 1.2rem;
  margin: 0 auto;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;

  span {
    font-family: "Biennale Medium";
    color: var(--color-5);
    font-size: 0.85rem;
  }

  h1,
  h2,
  h3 {
    font-family: "Biennale Bold";
  }

  h1 {
    color: var(--color-4);
    font-size: 4rem;
    strong{
      color: var(--color-3);
    }
  }
  h2 {
    color: var(--color-4);
    font-size: 4rem;
    strong{
      color: var(--color-3);
    }
  }

  h3 {
    font-size: 3rem;
    color: var(--color-3);
  }



  button {
    background-color: var(--color-3);
    color: var(--color-8);
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1rem;
    text-align: center;
    border-radius: 8px;
    border: none;
    font-family: "Biennale SemiBold", sans-serif;
    max-width: 90%;
    font-size: 1.2rem;
    margin: 0 auto;
  }

  a{
    align-self: center;
  }

  @media screen and (min-width: 920px) and (max-width: 1440px) {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    button {
      margin-top: 3rem;

    }
  }
  @media(max-width: 768px) {
    align-items: center;
    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 2.2rem;
    }
    h3{
      font-size: 1.7rem;
    }
  }
  @media(max-width: 330px){
    h2{
      font-size: 1.2rem;
    }
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  height: auto;
  justify-content: center;

  .header-img {
    position: relative;
    border-bottom: 1px solid #fff;
    z-index: 9;
    width: 100%;
  }

  .react-player {
    aspect-ratio: 16/9;
    width: 92.3% !important;
    height: auto !important;
    border: 1px solid var(--color-4);
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
