import statistic from '../../assets/icons/statistic.svg'
import draft from '../../assets/icons/draft.svg'
import invest from '../../assets/icons/invest.svg'
import time from '../../assets/icons/time.svg'

export const DescriptionsToCompany = [
  {
    id: 1,
    image: 'images/icons/computer.png',
    desc: 'Défict de profissionais de TI deve chegar a quase <strong>800 mil em 2025, apenas no Brasil</strong>'
  },
  {
    id: 2,
    image: 'images/icons/statistic.png',
    desc: 'Em média, no Brasil, há <strong>4 vagas abertas</strong> para cada programador capacitado',
    separator: true
  },
  {
    id: 3,
    image: 'images/icons/search.png',
    desc: 'Busca de empresas nos <strong>EUA e Europa por brasileiros cresce 700%,</strong> em 2022',
    separator: true
  },
  {
    id: 4,
    image: 'images/icons/rating.png',
    desc: 'Em média, os programadores capacitados <strong>permanecem na empresa por no máximo 1 ano</strong>',
    separator: true
  }
]

export const DescriptionsToStudents = [
  {
    id: 1,
    image: statistic,
    desc: 'Até 2025, teremos <strong>mais de 800 mil vagas</strong>, em tecnologia, abertas no Brasil',
    maxWidth: '250px',
    padding: '0 10px',
    paddingMobile: '0 10px'
  },
  {
    id: 2,
    image: draft,
    desc: 'A média de vagas abertas é de 4:1, ou seja, <strong>desenvolvedores  podem escolher entre 4 empresas.</strong>',
    separator: true,
    maxWidth: '266px',
    padding: '0'
  },
  {
    id: 3,
    image: invest,
    desc: 'O salário dos desenvolvedores <strong>cresceu em média 17,56% por ano</strong>, desde 2020',
    separator: true,
    maxWidth: '250px',
    padding: '0 10px'
  },
  {
    id: 4,
    image: time,
    desc: 'Nenhuma outra área tem o retorno sobre investimento tão alto - <strong>a média é de 6000% ao ano.</strong>',
    separator: true,
    maxWidth: '264px',
    padding: '0 1px'
  }
]
