import { EnefApiService } from "../services/EnefApiService";

const api = new EnefApiService();

export const sendEmail = async ({
  name,
  email,
  sendEmailAdmin,
  message
}) =>
  await api.makeHttpRequest({
    method: "POST",
    url: "/contact",
    data: {
      nome: name,
      email: email,
      EnviarEmailAdmin: sendEmailAdmin,
      mensagem: message
    }
  });
