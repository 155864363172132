import React from "react";
import * as S from './styles';

const InputWithLabel = ({ label, name, value, onChange, type, width, placeholder, readonly = false, options }) => {

  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <S.Container width={width} className="container-input">
      {type !== 'select' &&
        <>
          <span>{label}</span>
          <input
            type={type}
            value={value}
            name={name}
            placeholder={placeholder || label}
            onChange={handleChange}
            readOnly={readonly}
          />
        </>
      }
      {options &&
        <>
          <span>{label}</span>
          <select
            type={type}
            value={value}
            name={name}
            placeholder={placeholder || label}
            onChange={handleChange}
            readOnly={readonly}
          >
            <option value="">{placeholder}</option>
            {options.map(option => (
              <option value={option.value} key={option.key}>{option.name}</option>
            ))}
          </select>
        </>
      }
    </S.Container>
  );
}

export { InputWithLabel };
