import styled from "styled-components";
import borderVideoDark from '../../../assets/images/template_video_dark.svg';
import borderVideoLight from '../../../assets/images/template_video.svg';
import variables from '../../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text};

  @media(min-width:769px){
    padding: 56px 112px 80px;
    flex-direction: row;
  }

  @media(min-width: 1440px) {
    padding: 56px 7.8% 80px;
  }

  @media(max-width: 768px) {
    padding: 40px 6.67%;
    flex-direction: column;
  }

  @media(max-width: 425px){
    padding: 40px 6.67% !important;
  }

  .text-section {
    width: 47%;

    @media(max-width: 768px){
      width: 100%;
    }

    @media(max-width: 320px){
      margin-top: 20px;
    }

    h5 {
      color: ${props => props.theme.title === 'dark' ? variables.detailColorLight3 : props.theme.colors.text};
      text-transform: uppercase;

      font-family: 'Biennale';
      font-weight: 400;
      font-size: 1rem;
      line-height: 16px;
      letter-spacing: 0.08em;
      margin: 0;
      margin-bottom: 16px;

      @media(min-width:769px) and (max-width: 1440px){
        font-size: 13px;
      }

      @media(max-width:768px) {
        margin: 0 0 24px;
      }
    }

    h2 {
      font-family: 'Biennale SemiBold';
      margin-bottom: 0;
      font-size: 28px;

      @media(min-width: 769px) {
        margin-bottom: 16px;
        font-size: 48px;
      }

      @media(max-width: 425px){
        line-height: 32px;
      }
    }

    p {
      color: ${props => props.theme.title === 'dark' ? variables.detailColorLight1 : props.theme.colors.text};
      font-family: 'Biennale';
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin: 24px 0;

      @media(min-width:769px){
        font-size: 18px;
        margin: 0 0 28px;
        line-height: 32px;
      }
    }

    p strong {
      color: ${variables.primaryColor3};
      font-weight: 700;
      font-family: 'Biennale Bold';
    }
  }

  .buttonPadrao {
      max-width: 520px;
      width: 100%;
      margin-top: 12px;

      @media(max-width: 768px) {
        border-radius: 8px !important;
        font-size: 16px !important;
      }
  }

  .link-scroll {
    margin-top: 32px;
  }

  .link-scroll svg {
    width: 41px;
    height: 41px;
  }

  .link-scroll svg rect {
    fill: transparent;
  }

  .link-scroll svg path {
    stroke: ${({ theme }) => theme.title === 'light' && variables.primaryColor2};
  }

  .player-wrapper {
    width: 45%;
    position: relative;
    padding-top: 36.25%; /* 720 / 1280 = 0.5625 */
    background: ${({theme}) => theme.title === 'dark' ? `url(${borderVideoDark})` : `url(${borderVideoLight})`} no-repeat;
    background-size: cover;

    @media(min-width: 769px) {
      width: 53%;
      padding-top: 32.5%;
      margin-left: 23px;
      margin-bottom: 2.5%;
    }

    @media(max-width: 768px){
      width: 100%;
      padding-top: 63%;
      margin-bottom: 24px;
    }
  }

  .react-player {
    width: 93% !important;
    height: 84% !important;
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 1.3%;
    margin: auto;
    overflow: hidden;
    border: 1px solid ${variables.colorTextDark};

    @media(max-width: 768px){
      width: 94% !important;
      height: 84% !important;
      position: absolute;
      top: 4%;
      bottom: 0;
      left: 0;
      right: 1.3%;
      margin: auto;
      overflow: hidden;
      border: 1px solid ${variables.detailColorLight3};
    }
  }
`;

export const ColorBar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 8px;
`;

export const ColorDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.color ? props.color : variables.backgroundColorLightPrimary};
`
