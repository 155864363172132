import React from 'react';
import { Container, StyledInput } from './styles';

export const InputCustom = props => {
  return <Container isInvalid={props.isInvalid} className="input-custom">
    {props.img && <img className="icon-input" src={props.img} alt="" />}
    <StyledInput
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      isInvalid={props.isInvalid}
      defaultValue={props.defaultValue}
    />
  </Container>;
};
