/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { Icon, Modal } from '@material-ui/core';
import ModalAlterPassword from './components/ModalAlterPassword/index';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import './style.scss'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statusModal: false,
      viewModal: false,
      sucess: false,
      disabledClickOut: false
    };
  }
  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        'Erro!',
        error
          ? error
          : 'Erro ao alterar o status, tente novamente mais tarde.',
        'error'
      );
    } else if (sucess) {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Senha alterada com sucesso.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Sair'
      });
    }
  }

  render() {
    const { user, showHi, showBadge } = this.props;
    const ModalContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;

      width: fit-content;
      height: 100%;
      margin: auto;
      outline: none;
    `;
    return (
      <>
        <Modal
          open={this.state.statusModal}
          onClose={() => this.setState({ statusModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalContainer>
            <ModalAlterPassword
              closeModal={() => this.setState({ statusModal: false })}
              bg='white'
              result={(sucess, error) => {
                this.showMensage(sucess, error)
                this.setState({ statusModal: false })
              }}
            />
          </ModalContainer>
        </Modal>

        <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            <div className="kt-header__topbar-user">
              {showHi && (
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
              )}

              {showHi && (
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {user.nome}
                </span>
              )}

              {showBadge && (
                <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                  {user && user.nome[0]}
                </span>
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div
              className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-2.jpg")})`
              }}
            >
              <div className="kt-user-card__avatar">
                <img alt="Pic" className="kt-hidden" />
                <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                  {user.nome[0]}
                </span>
              </div>
              <div className="kt-user-card__name">{user.nome}</div>
            </div>
            <div className="kt-notification">
              <div className="col-12 p-4 option-user-profile" onClick={() => this.setState({ statusModal: true })}>
                <Icon className='mr-2 icon-password'>lockOutlined</Icon>
                <div className="col-10">
                  <div>
                    <strong>Minha Senha</strong>
                  </div>
                  <div>
                    Alterar a senha de acesso
                </div>
                </div>
                <div className="ml-2">
                <NavigateNextIcon/>
                </div>
              </div>
              <div className="kt-notification__custom">
                <Link
                  to="/logout"
                  className="btn btn-primary"
                >
                  Logout
              </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
