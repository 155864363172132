import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import  useGetWindowWidth from '../../utils/useGetWindowWidth';

const SkeletonLoading = ({
  widthPercentWindow,
  color,
  highlightColor,
  style,
  height
}) => {

  const width = useGetWindowWidth();
  const percent = widthPercentWindow / 100;

  return(
    <SkeletonTheme  color={color} highlightColor={highlightColor}>
      <Skeleton height={height} width={!!widthPercentWindow && width * percent} style={style}/>
    </SkeletonTheme>
  )
}

export default SkeletonLoading;
