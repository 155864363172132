import {useState, useEffect} from 'react';
import darkTheme from '../../utils/darkTheme';
import lightTheme from '../../utils/lightTheme';

function usePersistTheme(key, initialState) {
  const [stateTheme, setStateTheme] = useState(() => {
    const initialTheme = localStorage.getItem(key);

    if(initialTheme){
      const initialThemeParsed = JSON.parse(initialTheme);
      if (initialThemeParsed.title === 'dark' && initialTheme !== JSON.stringify(darkTheme)) {
        return darkTheme;
      } else if(initialThemeParsed.title === 'light' && initialTheme !== JSON.stringify(lightTheme)) {
        return lightTheme;
      }

      return initialThemeParsed;
    };

    return initialState;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(stateTheme));
  }, [key, stateTheme]);

  return [stateTheme, setStateTheme];
};

export default usePersistTheme;
