import React, {createContext} from 'react';

import darkTheme from '../../utils/darkTheme';
import lightTheme from '../../utils/lightTheme';
import usePersistTheme from './usePersistTheme';

const ThemeContext = createContext({});

export const ThemeProviderGlobal = ({children}) => {
  const [stateTheme, setStateTheme] = usePersistTheme('persist:theme' , darkTheme);

  const setTheme = (title) => {
   title === 'dark' ? setStateTheme(darkTheme) : setStateTheme(lightTheme);
  };

  return (
    <ThemeContext.Provider value={{
      setTheme,
       themeSelect: stateTheme
      }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext;
