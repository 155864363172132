import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100vw;
    justify-content: center;
    background-image: url("/images/svg/background-banner-mobile.svg");
    background-size: cover;
    background-repeat: no-repeat;

    padding: 36px 0;

    flex-direction: column;
    gap: 10px;


    @media(min-width: 768px){
        flex-direction: row;
        justify-content: space-evenly;
        background-image: url("/images/svg/background-banner.svg");
        width: 100%;
        height: 160px;
        gap: 0px;
    }

    h1, h2 {
        color: #ffffff;
        margin: 0;
        font-family: 'Biennale' !important;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;

        strong{
          color: #3BD42D;
          font-weight: 900;
          font-size: 36px !important;
        }

        @media(max-width: 768px){
            font-size: 16px;

            strong{
              font-size: 16px !important;
            }
        }
    }

    }

    strong {
        font-family: 'Biennale Black' !important;
    }

    span {
        color: #0A0A0A;
        font-family: 'Biennale Regular' !important;
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 24px;
        background-color: #25CBD3;
        border: 1px solid #25CBD3;
        padding: 20px 32px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        @media(max-width: 768px){
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            width: 220px;
            padding: 16px 32px;
            margin-top: 14px;
        }

        &:hover {
            color: #110629;
            background: #25CBD3;
        }
    }

    .name-lancamento {
        display: flex;
        align-items: center;
        height: 88px;
        gap: 20px;

        @media(max-width: 768px){
            justify-content: center;
            height: auto;
        }
    }

    .name-lancamento svg {
        height: 72px;
        width: fit-content;

        @media(max-width: 768px){
            height: 72px;
        }
    }

    .timer {
        display: flex;
        align-items: center;

        justify-content: center;

        @media(max-width: 768px){
            justify-content: center;
        }
    }
`;
