import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    text-align: center;
    width: 100%;
    height: 56px;
    max-width: 36.111vw;
    background-color: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};

    font-family: 'Biennale Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3888888888888888vw;
    line-height: 24px;

    @media (min-width: 1728px) {
      max-width: 38.194vw;
    }

    @media (max-width: 960px) {
      font-size: 1.333rem;
      max-width: ${({ introVideo }) => introVideo && `100%`};
    }

    @media (max-width: 768px) {
      font-size: 1.333rem;
      max-width: 312px;
    }

    color: ${({colorText}) => colorText ? colorText : '#fff'};
    border-radius: 8px;

    transition: all 0.2s;

    cursor: ${({ isPago }) => isPago ? 'not-allowed' : 'pointer'};

    @media (min-width: 769px) {
      border-radius: 12px;
      height: 4.444vw;
      min-height: 35px;
    }

    &:hover {
      filter: brightness(1.3);
    }

    svg {
        font-size: 24px;
    }
`;

export const ContainerTag = styled.button`
    width: 100%;
    height: 56px;
    display: flex;
    gap: 16px;
    //background-color: ${({ color }) => color};
    background-color: red;
    border: 1px solid ${({ color }) => color};
    font-family: 'Biennale';
    font-size: 16px;
    font-weight: 600;
    color: ${({colorText}) => colorText ? colorText : '#fff'};
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: ${({ isPago }) => isPago ? 'not-allowed' : 'pointer'};

    transition: all 0.2s;

    @media (min-width: 769px) {
      border-radius: 12px;
      height: 64px;
    }

    &:hover {
      filter: brightness(1.3);
    }

    svg {
      font-size: 24px;
    }
`;
