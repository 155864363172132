import React from "react";
import { FormattedMessage } from "react-intl";
import variables from '../../../_metronic/variables.scss';

export default class MenuItemText extends React.Component {
  render() {
    const { item, parentItem } = this.props;

    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        {item.translate ? (
          <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap", color: variables.colorTextDark }}>
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          </span>
        ) : (
          <span
            className="kt-menu__link-text"
            style={{ whiteSpace: "nowrap", color: variables.colorTextDark}}

            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        )}

        {item.badge && (
          <span className="kt-menu__link-badge" style={{color: variables.colorTextDark}}>
            <span className={`kt-badge ${item.badge.type}`} style={{color: variables.colorTextDark}}>
              {item.badge.value}
            </span>
          </span>
        )}

        {/*{item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}*/}
      </>
    );
  }
}
