/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React, { lazy, Suspense, useContext, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, withRouter, useLocation } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import ThemeContext from '../context/theme';
import * as routerHelpers from "../router/RouterHelpers";

import { LoadingPage } from "../components/LoadingPage";
import { ERoles } from "../enums/Roles";

import PreRegistrationLanding from "../pages/pre-registration";
import { useQuery } from "../utils/useQuery";
import * as Marketing from "../store/marketing"

const CareerList = lazy(() => import("../pages/aulas/careerList").then((module) => ({ default: module.CareerList })));
const Career = lazy(() => import("../pages/aulas/details/career").then((module) => ({ default: module.Career })));
const TrainingsListing = lazy(() => import("../pages/aulas/trainingListing").then((module) => ({ default: module.TrainingsListing })));
const Training = lazy(() => import("../pages/aulas/details/training").then((module) => ({ default: module.Training })));
const FaleConosco = lazy(() => import("../pages/faleConosco").then((module) => ({ default: module.FaleConosco })));
const DevStars = lazy(() => import("../pages/devStars").then((module) => ({ default: module.DevStars })));
const Login = lazy(() => import("../pages/sessionStudent/Login"));
const Register = lazy(() => import("../pages/sessionStudent/Register"));
const AuthPage = lazy(() => import("../pages/auth/AuthPage"));
const ForgotPassword = lazy(() => import("../pages/sessionStudent/ForgotPassword"));
const RecoverOrRegisterPassword = lazy(() => import("../pages/sessionStudent/RecoverOrRegisterPassword"));
const ConfirmCode = lazy(() => import("../pages/sessionStudent/ConfirmCode"));
const Profile = lazy(() => import("../pages/profile/ProfileHome").then((module) => ({ default: module.Profile })));
const Conquistas = lazy(() => import("../pages/conquistas").then((module) => ({ default: module.Conquistas })));
const LogoutPage = lazy(() => import("../pages/auth/Logout"));
const Aulas = lazy(() => import("../pages/aulas"));
const BadgeValidation = lazy(() => import("../pages/profile/ProfilePublic/BadgeValidation"));
const PrivacyPolicyPage = lazy(() => import("../pages/policy/PrivacyPolicyPage"));
const SorryPage = lazy(() => import("../pages/sorry"));
const MyOrders = lazy(() => import("../pages/myOrders").then((module) => ({ default: module.MyOrders })));
const NotFoundPage = lazy(() => import("../pages/errors/notFound").then((module) => ({ default: module.NotFound })));
const Checkout = lazy(() => import("../pages/checkout").then((module) => ({ default: module.Checkout })));
const AdminPage = lazy(() => import("../pages/admin/AdminPage"));
const SuccessRegistration = lazy(() => import("../pages/successRegistration").then((module) => ({ default: module.SuccessRegistration})))

export const Routes = withRouter(() => {
  const { themeSelect } = useContext(ThemeContext);
  const history = useHistory();
  const lastLocation = useLastLocation();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = useQuery();

  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, isAdmin } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      isAdmin: auth.user?.perfil?.nome === ERoles.ADMIN,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  function persistUtmIdQueryParam() {
    if(query.get('utmid') || query.get('utmId')){
      const utmId = new URLSearchParams(search).get('utmId');
      dispatch(Marketing.marketingActions.setUtmId(utmId));
    }
  }
  persistUtmIdQueryParam();


  const nameDevstars = process.env.REACT_APP_DEVSTARS_NAME || 'devstars';

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <StyledThemeProvider theme={themeSelect}>
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        {process.env.SHOW_SORRY_PAGE === 'true'
          ? <SorryPage />
          : (
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  <Route path="/auth"><AuthPage /></Route>
                  <Route path="/auth/login"><AuthPage /></Route>
                  <Route path="/logout"><LogoutPage /></Route>

                  {!isAuthorized && <Route path="/entrar" exact ><Login /></Route>}
                  {!isAuthorized && <Route path="/entrar/:type/:redirectId" exact ><Login /></Route>}
                  {!isAuthorized && <Route path="/entrar/:type/:redirectId/:buy" exact><Login /></Route>}
                  {!isAuthorized && <Route path="/entrar/:type/:redirectId/:buy/:ativar" exact><Login /></Route>}

                  <Route path="/cadastro"><Register /></Route>
                  <Route path="/cadastro/:type/:redirectId" exact><Register /></Route>
                  <Route path="/cadastro/:type/:redirectId/:buy" exact><Register /></Route>
                  <Route path="/cadastro-google" exact><Register /></Route>
                  <Route path="/cadastro-google/:name/:emailGoogle" exact><Register /></Route>
                  <Route path="/cadastro-google/:name/:emailGoogle/:type/:redirectId" exact><Register /></Route>
                  <Route path="/cadastro-google/:name/:emailGoogle/:type/:redirectId/:buy" exact><Register /></Route>

                  <Route path="/esqueci-senha"><ForgotPassword /></Route>
                  <Route path="/privacy-policy"><PrivacyPolicyPage /></Route>
                  <Route path="/not-found"><NotFoundPage /></Route>

                  {/* MINHAS CONQUISTAS */}
                  <Route path="/minhas-conquistas/:id"><Conquistas /></Route>

                  <Route path="/contato"><FaleConosco /></Route>

                  {/* Validate Badges */}
                  <Route path="/conquistas/:badge/:user" exact><BadgeValidation /></Route>

                  {/* Meus pedidos */}
                  <Route path="/meus-pedidos" exact><MyOrders /></Route>

                  {/* Fim Submenu */}
                  <Route path="/confirm-code/:userId"><ConfirmCode /></Route>

                  <Route path="/ativar-conta"><RecoverOrRegisterPassword /></Route>
                  <Route path="/recuperar-senha"><RecoverOrRegisterPassword /></Route>
                  <Route path="/cadastrar-senha"><RecoverOrRegisterPassword /></Route>

                  {/* DEVSTARS */}
                  <Route path={`/${nameDevstars}`}><DevStars /></Route>

                  {/* AULAS */}
                  <Route path="/assistir/:type/:id" exact><Aulas /></Route>

                  {/* Tela de checkout */}
                  <Route path="/checkout" exact><Checkout /></Route>
                  <Route path="/checkout/matricula-antecipada" exact><Checkout /></Route>
                  <Route path="/checkout/lancamento" exact><Checkout /></Route>
                  <Route path="/checkout-success" exact><SuccessRegistration/></Route>

                  {/* Submenu */}
                  <Route path="/carreiras" exact><CareerList /></Route>
                  <Route path="/carreiras/:id" exact><Career /></Route>
                  <Route path="/treinamentos" exact><TrainingsListing /></Route>
                  <Route path="/treinamentos/:training" exact><Training /></Route>

                  {isAdmin && <Route path="/admin"><Layout><AdminPage userLastLocation={userLastLocation} /></Layout></Route>}
                  {isAuthorized && <Route path='/perfil'><Profile /></Route>}
                  {!isAuthorized && <Redirect from="/admin" to="/auth/login" />}

                  <Route path="">
                    {isAuthorized
                      ? <Redirect to="/perfil" />
                      : <Redirect to="/entrar" />
                    }
                  </Route>

                </Switch>
            </Suspense>
          )}
      </LayoutContextProvider>
    </StyledThemeProvider>
  );
});
