import styled from 'styled-components';
import { Form } from "react-bootstrap";

import {
  primaryColor1,
  colorTextDark,
  backgroundTertiary,
  detailColorLight2
} from '../../../_metronic/variables.scss';

export const Content = styled.div`
  background: ${backgroundTertiary};
  width: 100%;
  max-width: 1280px;
  padding: 40px;

  gap: 40px;
  position: relative;

  border: 0.5px solid #F0F5FF;
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .mb-custom-24 {
    margin-bottom: 24px;
  }

  .contentInfo{
    display: flex;
    width: 610px;
    height: 100%;
    color: ${colorTextDark};
    gap: 20px;
    flex-direction: column;

    p{
      font-family: 'Biennale';
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      margin: 0;
      aling-self: end;

      strong{
        font-family: 'Biennale Bold';
      }
    }
    h2 {
      font-family: 'Biennale Bold';
      line-height: 50px;
      font-size: 36px;
      margin: 0;

      strong{
        color: ${primaryColor1}
      }
    }
  }

  .inputs{
    display: flex;
    flex-direction: column;
    width: 610px;
    height: 100%;
    padding-top: 12px;

    img{
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .inputContent {
    margin-bottom: 34px;
  }

  .send-button-modal {
    height: 64px;
    width: 50%;
    background-color: ${detailColorLight2};
    border: 1px solid ${detailColorLight2};
    color: ${backgroundTertiary};
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : 0};

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    // VER COM A JU
    /* :hover {
      transition: ${({ isLoading }) => isLoading ? 0 : '0.3s'};
      opacity: ${({ isLoading }) => isLoading ? 1 : '0.8'};
      border: 1px solid ${props => props.theme.colors.secondary};
      border: ${({ isLoading }) => isLoading && 0};
      color: ${({ isLoading }) => isLoading ? props => props.theme.colors.ligth : props => props.theme.colors.secondary};
      background: ${({ isLoading }) => isLoading ? props => props.theme.colors.secondary : 'transparent'};
    } */

    &:hover {
      filter: brightness(1.3);
    }

    &:disabled {
      cursor: not-allowed;
      filter: brightness(1);
      opacity: 0.5;
    }
  }

  .close-button-modal {
    background: transparent;
    border: none;

    position: absolute;
    top: 14px;
    right: 14px;

    img {
      width: 18px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1400px){
      .send-button-modal{
        width: 100%;
        font-size: 20px;
      }

      .contentInfo{

        p{
          font-size: 20px;
          line-height: 30px;
        }

        h2{
          font-size: 28px;
          line-height: 38px;
        }
      }
  }

  @media screen and (max-width: 768px){
    flex-direction: column;

    .contentInfo, .inputs{
      width: 100%;
    }

    .contentInfo{
      p{
        font-size: 14px;
        line-height: 24px;
      }

      h2{
        font-size: 24px;
        line-height: 30px;
      }
    }

    .inputs{
      img{
        height: 24px;
        width: 24px;
      }
    }

    .send-button-modal{
      width: 100%;
      font-size: 18px;
    }

    .close-button-modal{
      img{
        width: 12px;
      }
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px 22px;
  height: 56px;
  gap: 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  border-radius: 12px;

  border: 1px solid #F0F5FF;

  .icon-input {
    width: 20px;
    height: 20px;

  }

  @media(min-width: 1550px){
    padding: 1.389vw 1.528vw;
  }
  span {
    font-family: 'Biennale';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DCDCDC;
    padding: 0;
  }
  input{
    display: none;
  }
`;

