export default {
  // Extra small screen / phone
  XS: 0,

  // Small screen / phone
  SM: 576,

  // Medium screen / tablet
  MD: 768,

  // Medium screen / tablet
  MD1: 900,

  // Large screen / desktop
  LG: 1024, //1024px,

  // Extra large screen / wide desktop
  XL: 1399

}
