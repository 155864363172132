import styled from 'styled-components';
import {
  backgroundTertiary,
  detailColorLight2
} from '../../../_metronic/variables.scss';

export const Modal = styled.div `
  height: 100%;
  width: 100%;
  background-color: #000000bf;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.div`
    width: 100%;
    height: fit-content;
    background-color: var(--background-tertiary);


    border: 2px solid #F0F5FF;
    border-radius: 16px;
    padding: 1.667vw;

    display: flex;
    flex-direction: column;

    @media(min-width: 769px) {
        max-width: 44.444vw;
    }
    @media(max-width: 768px) {
        padding: 16px;
    }

    .closed {
      display: flex;
      justify-content: flex-end;

      button {
        background: transparent;
        border: 0;
      }

      button img {
        height: 12px;

        @media(min-width: 769px){
          height: 0.833vw;
        }
      }
    }

    h1 {
      font-family: 'Biennale SemiBold';
      color: var(--color-4);
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      margin: 0 0 16px;

      @media(min-width: 769px){
        margin: 0 0 1.667vw;
        font-size: 1.528vw;
        line-height: 1.944vw;
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 1.111vw;

      @media(max-width: 768px) {
          gap: 8px;
      }
    }

    .inputs .input-custom {
        border: 1px solid #3BD42D;

        svg path {
            fill: #3BD42D;
        }
        @media(max-width: 768px) {
            padding: 17px !important;
            height: 56px;

            input {
                height: 100%;
            }
        }
    }

    .checks {
        color: #F0F5FF;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        margin: 24px 0;

        @media(max-width: 768px) {
            gap: 8px;
            margin: 16px 0;
        }
    }

    .checks .check {
        display: flex;
        align-items: center;
        gap: 10px;

    }

    .checks span {
        font-size: 0.97222vw;

        @media(max-width: 1024px) {
            font-size: 14px;
        }
    }

    .enviar {
        width: 100%;
        padding: 1.389vw 0px;
        font-family: 'Biennale SemiBold';
        font-size: 1.389vw;
        border: 0;
        border-radius: 0.833vw;
        background-color: #3BD42D;;
        cursor: pointer;
        color: #0A0A0A;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        @media(max-width: 768px) {
            padding: 16px 0px;
            border-radius: 8px;
            font-size: 16px;
        }

        @media (min-width: 1728px) {
            padding: 1.11vw 0px;
        }

        &:disabled {
            background-color: #3cd42e40;
            color: #F0F5FF;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            filter: brightness(1.3);
            @media(max-width: 768px) {
                color: #0A0A0A;
            }
        }
    }

`;

export const Check = styled.div `
    height: 18px;
    width: 18px;
    min-width: 16px;
    /* margin-right: 10px; */
    border: 1.5px solid #3BD42D;
    border-radius: 4px;
    position: relative;
    top: 1px;
    cursor: pointer;

    @media(max-width: 769px) {
        height: 17px;
        width: 17px;
        min-width: 17px;
    }

    svg {
        position: absolute;
        width: 19.05px;
        height: 19px;
        top: -2px;
        left: -1.41px;

        @media(max-width: 769px) {
            width: 19.05px !important;
            height: 18px !important;
            top: -1px;
            left: -2.41px !important;
        }
    }
`;
