import axios from "axios";
import { HttpService } from "./HttpService";

export default class YoutubeAPIService extends HttpService {
  constructor() {
    const instance = axios.create({
      baseURL: `https://www.googleapis.com/youtube`
    });

    super(instance);
  }

  getChannelData(token) {
    return this.makeHttpRequest({
      url: '/v3/channels',
      params: {
        part: 'id',
        mine: 'true',
        access_token: token
      }
    });
  }
}
