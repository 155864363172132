import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { ContainerInstancia } from "./style";
import { EnefApiService } from "../../../../services/EnefApiService";
import * as Instancia from "../../../../store/instancia";
import store from "../../../../store/store";

function SelectInstancia() {
  const api = new EnefApiService();
  const history =  useHistory();

  const [instancias, setInstancias] = useState();
  const [selectInstancia, setSelectInstancia] = useState();

  async function getInitialInstancia() {
    try {
      const res = await api.makeHttpRequest({
        url: "/instancia"
      });

      setInstancias(res);
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Erro ao carregar o instancias!",
        icon: "error"
      });
    }
  }

  function getInstancia() {
    const {
      instancia: { instancia }
    } = store.getState();

    return instancia;
  }

  useEffect(() => {
    getInitialInstancia();
    setSelectInstancia(getInstancia())
  }, []);

  function handleSelectInstancia(e){
    store.dispatch(Instancia.instanciaActions.setInstancia(e));
    setSelectInstancia(getInstancia())
    history.push(0);
  }


  return (
    <ContainerInstancia>
        <span>Instância:</span>
        <select 
          name="instancias"
          value={selectInstancia}
          onChange={(e) => handleSelectInstancia(e.target.value)}
        >
         {instancias?.length > 0 
          ?
            instancias?.map(i => ( 
              i.instanciaAno !== 0
                ?
                  <option key={i.id} value={i.instanciaAno}>{i.instanciaAno}</option>
                :
                  ''
           ))
          : 
              <option value={selectInstancia}>{selectInstancia}</option>
        }
        </select>
    </ContainerInstancia>
  );
}

export { SelectInstancia };
