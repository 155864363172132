import styled from 'styled-components';
import { backgroundColorDarkTertiary,
  colorTextDark,
  detailColorLight1, } from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;


  @media(min-width: 769px){
    flex-direction: row;
  }

  @media(max-width: 768px) {
    flex-direction: column;
  }

  .content-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media(max-width: 768px){
      margin-top: 0;
      flex-direction: column;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }
  }

  .content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
    text-align: left;

    gap: 16px;

    .mb-custom {
      margin-bottom: 16px;
    }

    @media(max-width: 768px){
      align-items: flex-start;
      width: 100%;
      margin-bottom: 0px;
    }
    h2{
      width: 100%

    }
    p {
      font-family: 'Biennale';
      width: 100%;

      strong {
        font-family: 'Biennale Bold';
        color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
      }
    }
  }
`;

export const ContainerDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px 0;
    margin: 0 52px;

    @media(max-width: 768px) {
      width: 100%;
      flex-direction: column;
      margin-top: 0;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }

    .container-description-cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      //max-height: 55.97vh;
      max-width: 88.888vw;
      margin: 0 7.22vw;

      img{
        height: 25%;
        width: 50vw;
        border: 3px solid ${({ colorHighlight }) => colorHighlight ?? 'white'};
        border-radius: 50%;
        background: #000;
        object-fit: cover;
      }

      @media(max-width: 768px){
        width: 312px;
        min-width: 40.625vw;
      }

      @media(max-width: 540px) {
        min-width: 57.777vw;
      }

      @media(max-width: 360px) {
        min-width: 86.66vw;
      }

    }

    .swiper {
      display: flex;
      align-items: center;
      padding-bottom: 60px;
      padding-top: 60px;
      margin: -0.666vw;

      @media(max-width: 768px) {
        width: 312px;
        padding-bottom: 32px;
        margin-right: 0px;
      }
    }

    .swiper-wrapper {
      @media(min-width: 769px) {
        gap: 0 1.666vw !important;
      }
    }

    .swiper-slide {
      display: flex !important;
      max-width: 43.611vw !important;

      @media(max-width: 768px) {
        max-width: 92vw !important;
      }
    }

    .swiper-pagination {
      margin: 32px 0 0 !important;
      bottom: 0 !important;

      @media(max-width: 768px) {
        margin: 16px 0 0 !important;
      }

      span {
        width: 16px;
        height: 16px;

        @media(max-width: 768px) {
          width: 8px;
          height: 8px;
        }
      }

      .swiper-pagination-bullet {
        opacity: 1;
        background-color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
      }

      .swiper-pagination-bullet-active {
        background-color: #F0F5FF !important;
      }
    }

    .cards-section {
      width: 700px;
      height: 200px;
    }

    h1, h2, h3, h4, h5, p, span, strong {
      font-family: 'Biennale';
      color: ${colorTextDark};
      text-align: left;

      margin: 0;
    }

    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.08em;

        text-transform: uppercase;
        color: ${detailColorLight1};
      }

      h2 {
        font-family: 'Biennale Bold';
        font-style: normal;
        font-weight: 900;
        font-size: 3.692rem;
        line-height: 3.888888888888889vw;
      }

      p {
        font-family: 'Biennale Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 1.538rem;
        line-height: 3.37vh;

        @media(max-width: 768px) {
          font-size: 1.16rem;
          line-height: 3.11vh;
        }

        strong {
          font-family: 'Biennale Bold';
          color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
        }
      }
`;
