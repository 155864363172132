import styled from 'styled-components';
import { colorTextDark, primaryColor3 } from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;


    h2, p {
      font-family: 'Biennale';
      color: ${colorTextDark};
      width: 100%;
      text-align: left;

      margin: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 900;
      font-family: 'Biennale bold';

      @media(max-width: 768px) {
        padding: 0px 48px;
      }
    }

    p {
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 400;

      strong {
        font-family: 'Biennale bold';
        color: ${({ colorHighlight }) => colorHighlight ?? primaryColor3 };
      }
    }

    .mb-custom-16 {
      margin-bottom: 16px;
    }

    .content-description {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.701vh;

      @media(max-width: 1200px) and (min-width: 1024px) {
      }

      @media(max-width: 1024px) and (min-width: 769px) {
        align-items: baseline;
      }

      @media(max-width: 1024px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 32px 0;
      }

      @media(max-width: 768px) {
        flex-direction: column;
      }
    }

    .swiper {
      display: flex;
      align-items: center;
      padding-bottom: 20px;


      @media(max-width: 768px) {
        width: 312px;
        padding-bottom: 32px;
      }
    }

    .swiper-wrapper {
    }

    .swiper-slide {
      display: flex !important;
      width: 100%;
    }

    .swiper-pagination {
      margin: 32px 0 0 !important;
      bottom: 0 !important;

      @media(max-width: 768px) {
        margin: 16px 0 0 !important;
      }

      span {
        width: 16px;
        height: 16px;

        @media(max-width: 768px) {
          width: 8px;
          height: 8px;
        }
      }

      .swiper-pagination-bullet {
        opacity: 1;
        background-color: ${({ colorHighlight }) => colorHighlight ?? 'white'} !important;
      }

      .swiper-pagination-bullet-active {
        background-color: #F0F5FF !important;
      }
    }
  }
`;
