import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Modal } from '@material-ui/core';

import { Preloader, Bars } from 'react-preloader-icon';
import { ReactComponent as IconCircleXDark } from '../../../../assets/icons/icon_circleX_dark.svg';

import ThemeContext from '../../../../context/theme';
import SkeletonLoading from '../../../../components/SkeletonLoading';
import { CodeBlock, dracula } from "react-code-blocks";
import { FiArrowRight } from 'react-icons/fi';

import {
  ContainerModal,
  ContainerQuestions,
  ContainerAlternatives,
  Alternative,
  ContainerHeaderModal,
  FooterModal,
  ContainerModalExit,
  Button,
  ContainerModalMobile,
  ContainerHeaderModalMobile,
  ContainerHeaderQuestions,
  ContainerAlternativesMobile,
  AlternativeMobile,
  MessageIsIncorrectSwered,
  Code,
  FileComponent,
  Extensões,
  MensagemEspera,
  FeedBack,
  MenssageError
} from './styles';

import useGetWindowWidth from '../../../../utils/useGetWindowWidth';
import windowSize from '../../../../constants/WindowSize';
import { getDesafioPerguntas, salvaDesafio } from '../../../../services/DesafiosService';
import variables from '../../../../../_metronic/variables.scss';
import { useSelector } from 'react-redux';

const QuestionsModal = ({ open, handleClose, challengeIsSelected, setChallengeIsSelected, getChallengeList, onComplete, loadingModal }) => {
  const [currentQuestion, setCurrentQuestion] = useState({ alternativas: [1, 2, 3, 4] });
  const [questionsList, setQuestionsList] = useState([]);
  const [alternativeSelectedId, setAlternativeSelectedId] = useState(null);
  const [showModalExit, setShowModalExit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingCompleteChallenge, setisLoadingCompleteChallenge] = useState(false);
  const [messageModalFinish, setMessageModalFinish] = useState(null);
  const { themeSelect } = useContext(ThemeContext);
  const [showModalFinish, setShowModalFinish] = useState(false);
  const width = useGetWindowWidth();
  const [listSelectedAlternatives, setListSelectedAlternatives] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  const [file, setFile] = useState();
  const [errorFile, setErrorFile] = useState({
    isError: false,
    type: null
  });
  const { trainingId } = useSelector(
    state => state.modalDesafio
  );
  const extra = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';


  function isHTML(answer) {
    let valueAnswer = false;
    if (!answer?.match("TAG") && (
      (answer?.match("<") && answer?.match("/>"))
      || (answer?.match("public string") && answer?.match("{ get; set; }"))
      || (answer?.match("public ") && answer?.match("()") && answer?.match("}"))
    )) {
      valueAnswer = true;
    }

    return valueAnswer;
  }

  function splitExtensoes(arr) {
    var arrTratado = [];
    // var arrSlit = arr.split(";");
    var arrSlit = arr;
    arrSlit.map((a, i) => {
      if (i === arrSlit.length - 1) {
        arrTratado.push(`.${a} `);
      }
      else {
        arrTratado.push(`.${a}, `);
      }
    })

    return arrTratado;
  }

  function isQuestionHTML(question) {
    let isHTML = true;
    if (question?.match("TAG")) {
      isHTML = false;
    }

    return isHTML;
  }

  function setArrFiles(file, questionId) {
    if (file) {

      if (file?.size > 10485760) {
        setErrorFile({
          isError: true,
          type: 'Tamanho do arquivo maior q 10MB!'
        })
        return;
      }

      const formatSupported = currentQuestion.extensoes.find(e => e == file?.type.substr(file?.type.indexOf('/') + 1));
      const formatSupportedExtra = extra == file?.type;

      if (!formatSupported && !formatSupportedExtra) {
        setErrorFile({
          isError: true,
          type: 'Formato não suportado!'
        })
        return;
      }

      setFile(file);
      const newFile = { file, perguntaId: questionId };
      var newArrayFiles = listFiles.filter(({ file, perguntaId }) => perguntaId !== questionId);
      newArrayFiles.push(newFile);
      setListFiles(newArrayFiles);
    }
  }

  const getChallengeQuestionList = async desafioId => {
    setisLoading(true);
    const resultPerguntas = await getDesafioPerguntas(desafioId);

    // setar as respostas

    if (resultPerguntas) {
      setQuestionsList(resultPerguntas);
      setCurrentQuestion({ ...resultPerguntas[0], index: 0 });
    }
    setisLoading(false);
  }

  useEffect(() => {
    if (open === true && !!challengeIsSelected) {
      getChallengeQuestionList(challengeIsSelected?.desafioId);
    }
  }, [open, challengeIsSelected]);

  useEffect(() => {
    setisLoading(loadingModal);
  }, [loadingModal]);

  const convertNumberToLetter = (number) => {
    switch (number) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      case 5:
        return 'F';
      case 6:
        return 'G';
      default:
        break;
    };
  };

  const changeQuestion = useCallback((type) => {
    if (type === 'prox' && currentQuestion?.index < (questionsList?.length - 1)) {

      setCurrentQuestion({
        ...questionsList[currentQuestion?.index + 1],
        index: currentQuestion?.index + 1
      });

      const fileSelected = listFiles.filter(({ perguntaId }) => perguntaId === questionsList[currentQuestion?.index + 1].perguntaId);

      if (fileSelected[0]?.file) {
        setFile(fileSelected[0].file)
      }
      else {
        setFile()
      }

      setAlternativeSelectedId(null);
      return;
    };

    if (type === 'return' && currentQuestion?.index > 0) {
      setCurrentQuestion({
        ...questionsList[currentQuestion?.index - 1],
        index: currentQuestion?.index - 1
      });

      const fileSelected = listFiles.filter(({ perguntaId }) => perguntaId === questionsList[currentQuestion?.index - 1].perguntaId);
      if (fileSelected[0]?.file) {
        setFile(fileSelected[0].file)
      }
      else {
        setFile()
      }

      setAlternativeSelectedId(null)
      return;
    };

  }, [currentQuestion.index, questionsList]);

  const closeModalQuestions = useCallback(() => {
    setCurrentQuestion({ alternativas: [1, 2, 3, 4] });
    setQuestionsList([]);
    setAlternativeSelectedId(null);
    setListSelectedAlternatives([]);
    setListFiles([]);
    setFile(null);
    setShowModalFinish(false);
    setisLoadingCompleteChallenge(false);
    setMessageModalFinish(null);
    setShowModalExit(false);
    handleClose();

  }, [handleClose]);

  const ModalExit = useCallback(() => {
    return (
      <Modal
        open={showModalExit}
        onClose={() => setShowModalExit(false)}
        className=''
      >
        <ContainerModalExit width={width}>
          <h4>Deseja mesmo abandonar esse desafio?</h4>
          <p>Caso resolva cancelar o desafio, você irá perder
            todas as questões respondidas, e terá que iniciar
            novamente.</p>

          <div>
            <Button
              onClick={() => setShowModalExit(false)}>
              Não
            </Button>
            <Button
              style={{ marginLeft: 18 }}
              onClick={closeModalQuestions}
              highlight={true}>
              Sim
            </Button>
          </div>

        </ContainerModalExit>
      </Modal>
    );
  }, [showModalExit, width, closeModalQuestions]);

  const concluirDesafio = useCallback(async () => {
    try {
      setisLoadingCompleteChallenge(true);
      let listAlternativas = listSelectedAlternatives.map(e => { return { alternativaId: e.alternativaId } }).concat(listFiles);

      const body = new FormData();
      body.append("desafioId", challengeIsSelected?.desafioId);

      if(!!trainingId){
        body.append("trainingId", trainingId);
      }

      listAlternativas.forEach((result, index) => {
        if (!!result.alternativaId) {
          body.append(`resultadoAvaliacao[${index}].AlternativaId`, result.alternativaId)
        }

        if (!!result.file) {
          body.append(`resultadoAvaliacao[${index}].File`, result.file)
          body.append(`resultadoAvaliacao[${index}].PerguntaId`, result.perguntaId)
        };
      });

      const response = await salvaDesafio(body);
      setMessageModalFinish({
        title: 'Parabéns!',
        text: response,
        type: 'success'
      });

      setisLoadingCompleteChallenge(false);

    } catch (error) {
      setMessageModalFinish({
        title: 'Erro!',
        text: error?.response?.data
                ? error?.response?.data?.detail
                : `Não foi possível enviar o desafio, tente mais tarde.`,
        type: 'error',
      });
      setisLoadingCompleteChallenge(false);
    }
  }, [
    challengeIsSelected,
    listSelectedAlternatives,
    listFiles
  ]);

  const ModalFinish = useCallback(() => {

    return (
      <Modal
        disableBackdropClick={true}
        open={showModalFinish}
        onClose={() => setShowModalFinish(false)}>
        <ContainerModalExit type={messageModalFinish?.type} width={width}>
          <h4>{!messageModalFinish ? `Finalizou o desafio?` : messageModalFinish?.title}</h4>
          <p>
            {!messageModalFinish ?
              `Ao clicar em sim suas respostas serão
             enviadas não podendo alterá-las mais.` :
              messageModalFinish?.text}
          </p>

          {!messageModalFinish ?
            <div>
              <Button
                onClick={() => setShowModalFinish(false)}>
                Não
              </Button>
              <Button
                style={{ marginLeft: 18 }}
                isLoadingCompleteChallenge={isLoadingCompleteChallenge}
                onClick={async () => {
                  await concluirDesafio();
                }}
                highlight={true}>
                {isLoadingCompleteChallenge ?
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Preloader
                      use={Bars}
                      size={20}
                      strokeWidth={8}
                      strokeColor={themeSelect.colors.primary}
                      duration={1000}
                    />
                  </div> :
                  'Sim'}
              </Button>
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
              {messageModalFinish.type === 'success' &&
                <Button
                  margin='0 12px 0 0'
                  mobile
                  color={messageModalFinish.type === 'success' ? variables.primaryColor3 : '#FF7563'}
                  onClick={(e) => {
                    e.preventDefault();
                    getChallengeList();
                    setShowModalFinish(false);
                    setShowModalExit(false);
                    setListSelectedAlternatives([]);
                    setListFiles([]);
                    setAlternativeSelectedId(null);
                    setMessageModalFinish(null);
                    setisLoading(true);
                  }}>
                  Ver respostas
                </Button>
              }
              <Button
                mobile
                highlight={messageModalFinish.type === 'success' ? true : false}
                color={messageModalFinish.type === 'success' ? variables.primaryColor3 : '#FF7563'}
                margin={'0'}
                onClick={(e) => {
                  e.preventDefault();
                  getChallengeList();
                  closeModalQuestions();
                  onComplete && onComplete();
                }}>
                Sair
              </Button>
            </div>
          }
        </ContainerModalExit>
      </Modal>
    );
  }, [
    concluirDesafio,
    closeModalQuestions,
    showModalFinish,
    width,
    messageModalFinish,
    isLoadingCompleteChallenge,
  ]);

  const handleChangeSelectAlternative = useCallback((current, alternativeId) => {
    setAlternativeSelectedId(alternativeId);

    if (listSelectedAlternatives.find(e => e.questionId === current.perguntaId)) {
      const newList = listSelectedAlternatives.map(e => {
        if (e.questionId === current.perguntaId) {
          e.alternativaId = alternativeId;
        }
        return e;
      });
      setListSelectedAlternatives(newList);
    } else {
      setListSelectedAlternatives(listSelectedAlternatives);
      listSelectedAlternatives.push({ questionId: current.perguntaId, alternativaId: alternativeId });
      setListSelectedAlternatives(listSelectedAlternatives);
    }
  }, [listSelectedAlternatives]);

  const handleCloseModal = () => {
    closeModalQuestions();
    handleClose(onComplete && onComplete());
  }

  if (width > windowSize.MD) {
    return (
      <>
        <Modal
          disableBackdropClick={true}
          open={open}
          onClose={handleClose}
        >
          <ContainerModal>
            {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && <ContainerHeaderModal>
              <h2>{challengeIsSelected?.nome}</h2>

              <button onClick={() => !!challengeIsSelected?.avaliacaoId ? handleCloseModal() : setShowModalExit(true)}>
                {!!challengeIsSelected?.avaliacaoId ? 'Fechar' : 'Cancelar desafio'}
              </button>
            </ContainerHeaderModal>}

            <ContainerQuestions>
              <ContainerHeaderModal>
                {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && challengeIsSelected ?
                  <p>
                    {currentQuestion?.index + 1}/{questionsList.length} - {isQuestionHTML(currentQuestion?.descricao) ? <div style={{ marginLeft: 2, marginTop: 1, width: '85%' }} dangerouslySetInnerHTML={{ __html: currentQuestion?.descricao }} /> : <div style={{ marginLeft: 2, marginTop: 1, width: '85%' }} >{currentQuestion?.descricao}</div>}
                  </p>
                  :
                  <h4>
                    <SkeletonLoading
                      color={variables.backgroundTertiaryLight}
                      highlightColor={variables.devariaSkeletonColor}
                      height={22}
                      widthPercentWindow={20}
                      style={{ marginLeft: 24 }}
                    />
                  </h4>
                }
                <div>
                  {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && <span className='questionValue'>Valor da pergunta</span>}
                  {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && challengeIsSelected ?
                    <strong>{currentQuestion?.pontuacao} xp</strong> :
                    <strong>
                      <SkeletonLoading
                        color={variables.backgroundTertiaryLight}
                        highlightColor={variables.devariaSkeletonColor}
                        height={22}
                        widthPercentWindow={5}
                      />
                    </strong>
                  }
                </div>
              </ContainerHeaderModal>

              {currentQuestion.tipoPergunta !== 'ARQUIVO' &&
                <ContainerAlternatives>
                  {
                    currentQuestion?.alternativas?.map((alternative, index) => {
                      return (
                        !isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && challengeIsSelected ?
                        <>
                            {!!alternative.alternativaId && !!currentQuestion?.alternativaSelecionadaId && (alternative.alternativaId === currentQuestion?.alternativaSelecionadaId) &&
                              <MessageIsIncorrectSwered
                                isCorrectSwered={alternative?.isAlternativaCorreta === "true" ? 'success' : 'error'}
                              >
                                {alternative?.isAlternativaCorreta === "true" ?
                                  'Parabéns, Você acertou!' :
                                  'Infelizmente, essa não é a resposta correta'}
                              </MessageIsIncorrectSwered>
                            }
                            <Alternative
                              disabled={!!currentQuestion?.alternativaSelecionadaId}
                              key={alternative.alternativaId}
                              isSwered={!!currentQuestion?.alternativaSelecionadaId}
                              isCorrectSwered={alternative?.isAlternativaCorreta === "true" &&
                                alternative.alternativaId === currentQuestion?.alternativaSelecionadaId}
                              isIncorrectSwered={
                                alternative.alternativaId === currentQuestion?.alternativaSelecionadaId
                                && alternative.isAlternativaCorreta === "false"}
                              isSelected={alternative.alternativaId === alternativeSelectedId ||
                                !!listSelectedAlternatives.find(
                                  alternat =>
                                    alternat?.alternativaId === alternative?.alternativaId)}
                              onClick={() => handleChangeSelectAlternative(currentQuestion, alternative.alternativaId)}>

                              {isHTML(alternative.descricao)
                                ? (
                                  <p>
                                    {convertNumberToLetter(index)})
                                    <Code>
                                      <CodeBlock
                                        text={alternative.descricao}
                                        language={'jsx'}
                                        showLineNumbers={true}
                                        theme={dracula}
                                      />
                                    </Code>
                                  </p>
                                ) : (
                                  <p>
                                    {convertNumberToLetter(index)}) {alternative.descricao}
                                  </p>
                                )
                              }
                              {alternative?.isAlternativaCorreta === "true" &&
                                !!alternative?.explicacao ?
                                <span className='success'>{alternative?.explicacao}</span>
                                :
                                alternative?.explicacao &&
                                <span>{alternative?.explicacao}</span>
                              }
                            </Alternative>
                          </> :

                          <SkeletonLoading
                            color={variables.backgroundTertiaryLight}
                            highlightColor={variables.devariaSkeletonColor}
                            height={64}
                            style={{
                              marginTop: 12,
                              borderRadius: 12,
                              border: themeSelect.title === 'dark' ? `1px solid ${variables.devariaBorderColor}` : '1px solid #B7E7E8'
                            }}
                          />
                      )
                    })
                  }
                </ContainerAlternatives>
              }

              {currentQuestion.tipoPergunta === 'ARQUIVO' &&
                <>
                  <FileComponent disabledFile={currentQuestion?.observacao !== null}>
                    <span>{
                      file?.name
                        ? file?.name
                        : currentQuestion?.nome
                          ? currentQuestion?.nome
                          : 'Faça o upload do seu arquivo *'
                    }</span>
                    <input type="file" name="arquivo" id="arquivo" onChange={e => setArrFiles(e.target.files[0], currentQuestion.perguntaId)} accept={splitExtensoes(currentQuestion.extensoes)} />
                    {currentQuestion?.pathFile === null
                      ? <label htmlFor={currentQuestion?.pathFile === null ? "arquivo" : ''}>Selecione seu arquivo<FiArrowRight /></label>
                      : !currentQuestion?.observacao
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        ? <a href={currentQuestion?.pathFile} className="btn-pathFile" target="_blank" rel="noopener noreferrer">Visualize seu arquivo<FiArrowRight /></a>
                        : (<label>Visualize seu arquivo<FiArrowRight /></label>)
                    }
                  </FileComponent>

                  {errorFile.isError
                    ? (<MenssageError>
                      {errorFile.type}
                    </MenssageError>
                    ) : ''}

                  <Extensões margin={currentQuestion?.pathFile === null ? '8px 0' : '8px 0 0 0;'}>
                    <p>Extenões válidas {splitExtensoes(currentQuestion.extensoes)}- até 10MB</p>
                  </Extensões>

                  {(currentQuestion?.pathFile !== null && currentQuestion?.observacao === null) ? <MensagemEspera>
                    Nossos avaliadores ainda estão analisando o seu arquivo.<br />
                    Você será notificado por e-mail quando a correção for efetuada.
                  </MensagemEspera> : ''}

                  {currentQuestion?.observacao ? <FeedBack>
                    <h2>Feedback do avaliador:</h2>
                    <p>{currentQuestion?.observacao}</p>
                  </FeedBack> : ''}
                </>
              }

            </ContainerQuestions>
            {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && <FooterModal>
              <div style={{ display: 'flex', gap: 15 }}>
                {currentQuestion.index > 0 &&
                  <button
                    onClick={() => changeQuestion('return')}
                    className='btn-back'>
                    Anterior
                  </button>}
                {currentQuestion.index !== (questionsList.length - 1) ?
                  <button
                    className="btn-next"
                    onClick={() => changeQuestion('prox')}>
                    Próximo
                  </button>
                  :
                  (challengeIsSelected && challengeIsSelected.avaliacaoId) ? null :
                    <button
                      className='btn-next'
                      onClick={() => setShowModalFinish(true)}
                    >
                      Concluir
                    </button>}
              </div>

            </FooterModal>}

          </ContainerModal>
        </Modal>
        <ModalExit />
        <ModalFinish />
      </>
    );
  } else {
    return (
      <>
        <Modal
          disableBackdropClick={true}
          open={open}
          onClose={handleClose}
        // style={{overflow: 'scroll'}}
        >
          <ContainerModalMobile>
            <ModalFinish />
            <ContainerHeaderModalMobile>
              <h5>{challengeIsSelected?.nome}</h5>
              <button
                className='buttonExitMobile'
                onClick={() => !!challengeIsSelected?.avaliacaoId ? handleClose() : setShowModalExit(true)}>
                <IconCircleXDark />
              </button>
            </ContainerHeaderModalMobile>

            <ContainerHeaderQuestions>
              {!isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && challengeIsSelected ?
                <>
                  <span className='nameQuestion'>
                    {currentQuestion?.index + 1}/{questionsList.length}
                    - {isQuestionHTML(currentQuestion?.descricao) ? <div style={{ marginLeft: 2, marginTop: 1, width: '100%' }} dangerouslySetInnerHTML={{ __html: currentQuestion?.descricao }} /> : <div style={{ marginLeft: 2, marginTop: 1, width: '100%' }} >{currentQuestion?.descricao}</div>}
                  </span>

                  <span>Valor da pergunta: <strong>{currentQuestion?.pontuacao} xp</strong></span>
                </>
                :
                <>
                  <span>
                    <SkeletonLoading
                      color={variables.backgroundTertiaryLight}
                      highlightColor={variables.devariaSkeletonColor}
                      height={18}
                      widthPercentWindow={70}
                    />
                  </span>
                  <span>
                    <SkeletonLoading
                      color={variables.backgroundTertiaryLight}
                      highlightColor={variables.devariaSkeletonColor}
                      height={18}
                      widthPercentWindow={55}
                    />
                  </span>
                </>}
            </ContainerHeaderQuestions>

            {currentQuestion.tipoPergunta !== 'ARQUIVO' &&
              <ContainerAlternativesMobile>
                {
                  currentQuestion?.alternativas?.map((alternative, index) => (
                    !isLoading && currentQuestion?.alternativaSelecionadaId >= 0 && challengeIsSelected ?
                      <AlternativeMobile
                        key={alternative.alternativaId}
                        isSelected={alternative.alternativaId === alternativeSelectedId ||
                          !!listSelectedAlternatives.find(
                            alternat =>
                              alternat?.alternativaId === alternative?.alternativaId)}
                        onClick={() => handleChangeSelectAlternative(currentQuestion, alternative.alternativaId)}
                        disabled={!!currentQuestion?.alternativaSelecionadaId}
                        isSwered={!!currentQuestion?.alternativaSelecionadaId}
                        isCorrectSwered={alternative?.isAlternativaCorreta === "true"}
                        isIncorrectSwered={
                          alternative.alternativaId === currentQuestion?.alternativaSelecionadaId
                          && alternative.isAlternativaCorreta === "false"}
                      >
                        {isHTML(alternative.descricao)
                          ? (
                            <p>
                              <strong>{convertNumberToLetter(index)})</strong>
                              <Code>
                                <CodeBlock
                                  text={alternative.descricao}
                                  language={'jsx'}
                                  showLineNumbers={true}
                                  theme={dracula}
                                />
                              </Code>
                            </p>
                          ) : (
                            <p>
                              <strong>{convertNumberToLetter(index)})</strong> {alternative.descricao}
                            </p>
                          )
                        }

                        {alternative?.isAlternativaCorreta === "true" &&
                          !!alternative?.explicacao ?
                          <span className='success'>{alternative?.explicacao}</span>
                          :
                          alternative?.explicacao &&
                          <span>{alternative?.explicacao}</span>
                        }

                      </AlternativeMobile> :
                      <SkeletonLoading
                        color={variables.backgroundTertiaryLight}
                        highlightColor={variables.devariaSkeletonColor}
                        height={38}
                        style={{
                          marginTop: 12,
                          borderRadius: 12,
                          border: themeSelect.title === 'dark' ? `1px solid ${variables.devariaBorderColor}` : '1px solid #B7E7E8'
                        }}
                      />
                  ))
                }
              </ContainerAlternativesMobile>
            }


            {currentQuestion.tipoPergunta === 'ARQUIVO' &&
              <>
                <FileComponent disabledFile={currentQuestion?.observacao !== null}>
                  <span>{
                    file?.name
                      ? file?.name
                      : currentQuestion?.nome
                        ? currentQuestion?.nome
                        : 'Faça o upload do seu arquivo *'
                  }</span>
                  <input type="file" name="arquivo" id="arquivo" onChange={e => setArrFiles(e.target.files[0], currentQuestion.perguntaId)} accept={splitExtensoes(currentQuestion.extensoes)} />
                  {currentQuestion?.pathFile === null
                    ? <label htmlFor={currentQuestion?.pathFile === null ? "arquivo" : ''}>Selecione seu arquivo<FiArrowRight /></label>
                    : !currentQuestion?.observacao
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      ? <a href={currentQuestion?.pathFile} className="btn-pathFile" target="_blank" rel="noopener noreferrer">Visualize seu arquivo<FiArrowRight /></a>
                      : (<label>Visualize seu arquivo<FiArrowRight /></label>)
                  }
                </FileComponent>

                {errorFile.isError
                  ? (<MenssageError>
                    {errorFile.type}
                  </MenssageError>
                  ) : ''}

                <Extensões margin={currentQuestion?.pathFile === null ? '8px 0' : '8px 0 0 0;'}>
                  <p>Extenões válidas {splitExtensoes(currentQuestion.extensoes)}- até 10MB</p>
                </Extensões>

                {(currentQuestion?.pathFile !== null && currentQuestion?.observacao === null) ? <MensagemEspera>
                  Nossos avaliadores ainda estão analisando o seu arquivo.<br />
                  Você será notificado por e-mail quando a correção for efetuada.
                </MensagemEspera> : ''}

                {currentQuestion?.observacao ? <FeedBack>
                  <h2>Feedback do avaliador:</h2>
                  <p>{currentQuestion?.observacao}</p>
                </FeedBack> : ''}
              </>
            }

            <FooterModal mobile>
              {currentQuestion.index > 0 &&
                <Button
                  style={{ marginLeft: 0 }}
                  onClick={() => changeQuestion('return')}
                  className='btn-back'
                  mobile
                  highlight>
                  Anterior
                </Button>}
              {currentQuestion.index !== (questionsList.length - 1) ?
                <Button
                  mobile
                  margin={currentQuestion.index > 0 && '0 0 0 18px'}
                  className="btn-next"
                  onClick={() => changeQuestion('prox')}>
                  Próximo
                </Button> :
                 (challengeIsSelected && challengeIsSelected.avaliacaoId) ? null : <Button
                  mobile
                  margin={questionsList.length === 1 ? '0' : '0 0 0 18px'}
                  color={variables.primaryColor3}
                  className="btn-next"
                  onClick={() => setShowModalFinish(true)}>
                  Concluir
                </Button>
              }
            </FooterModal>
          </ContainerModalMobile>
        </Modal>
        <ModalExit />
      </>
    );
  };
};

export default QuestionsModal;
