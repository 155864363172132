import React from 'react';
import moment from 'moment';
import { Container, SocialMediaContainer, LinkUseTerm } from './styles';
import logoDevariaDark from '../../assets/logo/logo-devaria-02.svg';
import { ReactComponent as YouTube } from '../../assets/icons/icons_footer/youtube.svg';
import { ReactComponent as Instagram } from '../../assets/icons/icons_footer/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/icons/icons_footer/facebook.svg';
import { ReactComponent as Spotify } from '../../assets/icons/icons_footer/spotify.svg';
import { ColorBar, ColorDiv } from '../../pages/home/Intro/styles';
import windowSize from '../../constants/WindowSize';
import useGetWindowWidth from '../../utils/useGetWindowWidth';
import variables from '../../../_metronic/variables.scss';

function Footer() {
  const width = useGetWindowWidth();

  return (
    <Container>
      <span></span>

      <a href="/">
        <img src={logoDevariaDark}
          alt="Devaria Logo Footer"
        />
      </a>

      {width > windowSize.MD ?
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontSize: 14, marginBottom: 6 }}>
            &copy; Copyright {moment().format('YYYY')} Devaria. Todos os direitos reservados.
          </p>
          <LinkUseTerm to='/privacy-policy'>Termos de uso e privacidade</LinkUseTerm>
        </div> :
        <p>
          &copy; Copyright {moment().format('YYYY')} Devaria. Todos os direitos reservados.
        </p>
      }

      <SocialMediaContainer>
        <a href="https://www.youtube.com/channel/UCai0pClQW1kPkAGpjV8LJrA" target="_blank" rel="noopener noreferrer">
          <YouTube />
        </a>
        <a href="https://www.instagram.com/devaria_oficial/" target="_blank" rel="noopener noreferrer">
          <Instagram />
        </a>
        <a href="https://www.facebook.com/groups/devaria" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
        <a href="https://open.spotify.com/playlist/44LKdfwGxLoDi6i2kj0xg8?si=9Lg2qWl1SQ62yEReTjJ_QA" target="_blank" rel="noopener noreferrer">
          <Spotify />
        </a>
      </SocialMediaContainer>


      {width <= windowSize.MD &&
        <LinkUseTerm top={40} to='/privacy-policy'>
          Termos de uso e privacidade
        </LinkUseTerm>}

      <ColorBar>
        <ColorDiv color={variables.footerColorPrimary} />
        <ColorDiv color={variables.footerColorSecondary} />
        <ColorDiv color={variables.footerColorTertiary} />
        <ColorDiv color={variables.footerColorQuartiary} />
        <ColorDiv color={variables.footerColorQuintiary} />
      </ColorBar>
    </Container>
  );
}

export default Footer;
