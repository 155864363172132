import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { calendarioReducer} from "./calendario";
import { desafioEspecialReducer} from "./desafioEspecial";
import { marketingReducer} from "./marketing";
import { modalDesafioReducer} from "./modalDesafio";
import { metronic } from "../../_metronic";
import { instanciaReducer } from "./instancia";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  calendario: calendarioReducer,
  desafioEspecial: desafioEspecialReducer,
  modalDesafio: modalDesafioReducer,
  instancia: instanciaReducer,
  marketing: marketingReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
