import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../../../context/theme';

import {
  ContainerDesafio,
  SectionLeft,
  SectionRight,
  ButtonContainerDesafio,
  ContainerDesafioLink,
  ButtonClose,
} from './styles';

import useGetWindowWidth from '../../../../utils/useGetWindowWidth';
import windowSize from '../../../../constants/WindowSize';
import iconClassActive from '../../../../assets/icons/icon_classActive.svg'
import iconClassBlue from '../../../../assets/icons/icon_classBlue.svg'
import iconCircleXWhite from '../../../../assets/icons/icon_circleX_white.svg';
import iconCircleXDark from '../../../../assets/icons/icon_circleX_dark.svg';
import store from "../../../../store/store";
import * as ModalDesafio from "../../../../store/modalDesafio";

import variables from '../../../../../_metronic/variables.scss';

const DesafioEspecial = ({ desafioEspecial, handleCloseBanner }) => {
  const width = useGetWindowWidth();
  const { themeSelect } = useContext(ThemeContext);

  const setShowModal = (isOpen, desafioId) => {
    store.dispatch(ModalDesafio.ModalDesafioActions.setIsOpen(isOpen, desafioId));
  }

  if (width > windowSize.SM) {
    return (
      <ContainerDesafio className="col-12">
        <SectionLeft width={width}>
          <img
            src={iconClassBlue}
            alt="Icone Aulas" />
          <div>
            <h6>{desafioEspecial?.nome?.toUpperCase()}</h6>
            <h5>{desafioEspecial?.descricao}</h5>
          </div>
        </SectionLeft>
        <SectionRight className="row">
          {/* <Link to={`/desafios/${desafioEspecial?.id}`}> */}
          <ButtonContainerDesafio onClick={() => setShowModal(true, desafioEspecial.id)}>
            <span style={{ color: variables.backgroundColorLightPrimary }}>Fazer Desafio</span>
          </ButtonContainerDesafio>
          <ButtonClose className='buttonClose' onClick={handleCloseBanner}>
            <img
              src={themeSelect.title === 'dark' ? iconCircleXWhite : iconCircleXDark}
              alt="Fechar banner" />
          </ButtonClose>
        </SectionRight>
      </ContainerDesafio>
    );
  } else {
    return (
      <></>
    //   <ContainerDesafioLink
    //     width={width}
    //     // to={`/desafios/${desafioEspecial?.id}`}
    //     className="col-12">
    //     <SectionLeft>
    //       <img src={iconClassBlue} alt="Icone Aulas" />
    //       <div>
    //         <h6>{desafioEspecial?.nome?.toUpperCase()}</h6>
    //         <h5>{desafioEspecial?.descricao}</h5>
    //       </div>
    //     </SectionLeft>
    //     <ButtonClose className='buttonClose' onClick={handleCloseBanner}>
    //       <img src={iconCircleXWhite} alt="Fechar banner" />
    //     </ButtonClose>
    //   </ContainerDesafioLink>
    )
  }

};

export default DesafioEspecial;
