import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;

    @media(max-width: 768px){
        gap: 24px;
    }
`;
