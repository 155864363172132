import styled from "styled-components";

export const Container = styled.div `

    p {
        margin: 16px 0 0;
        font-family: 'Biennale';
        @media(max-width: 768px) {
            margin: 16px 0 24px;
        }
    }

    p strong {
        font-family: 'Biennale Bold';
        font-weight: 900;
        line-height: 1.9444444444444444vw;
        letter-spacing: 0em;
        text-align: left;
        color: #B0A4FF;
    }

    .topicos {
        margin: 2.7777777777777777vw 0 0;
        display: flex;
        flex-direction: column;
        gap: 1.1111111111111112vw;

        @media(max-width: 768px) {
            gap: 8px;
        }
    }

`;
