import styled from 'styled-components';
import { colorTextDark } from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(min-width: 769px){
    flex-direction: row;
  }

  .content-section {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media(max-width: 768px){
      margin-top: 0;
      flex-direction: column;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }
  }

  .content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    gap: 2.07vh;

    .mb-custom {
      margin-bottom: 16px;
    }

    @media(max-width: 768px){
      width: 100%;
      margin: 0 0 24px 0;
    }

    h2, h3, h4, h5, p, span, strong {
      font-family: 'Biennale';
      color: ${colorTextDark};
      text-align: left;

      margin: 0;

      @media(max-width: 768px){
        text-align: left;
      }
    }

    h2 {
      font-family: 'Biennale Bold';
      font-style: normal;
      font-weight: 900;

    }

    p {
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 400;

      strong {
        font-family: 'Biennale Bold';
        color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
      }
    }
  }

  .container-button {
    display: flex;
    justify-content: center;
    width: 100%;

    @media(max-width: 768px) {
      align-items: center;
    }
  }
`;

export const ContainerDescription = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    gap: 48px 0;


    @media(min-width:769px){
      padding: 40px 80px 0px;
    }

    @media(max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      width: 100%;
    }

    @media(max-width: 320px){
      margin: 0 0 50px;
    }

    .container-description-cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 88.888vw;

      @media(min-width: 1025px) {
        gap: 56px;
      }

      @media(max-width: 1024px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 28.5px 0;
      }

      @media(max-width: 768px){
        width: 312px;
        min-width: 40.625vw;
        margin: 0;
      }

      @media(max-width: 540px) {
        min-width: 57.777vw;
      }

      @media(max-width: 360px) {
        min-width: 86.66vw;
      }
    }

    .cards-section {
      width: 700px;
      height: 200px;
    }

    h2, h3, h4, h5, p, span, strong {
      font-family: 'Biennale';
      color: ${colorTextDark};
      text-align: left;

      margin: 0;
    }

      h2 {
        font-family: 'Biennale Bold';
        font-style: normal;
        font-weight: 900;
        font-size: 3.692rem;
        line-height: 7.272vh;
      }

      p {
        font-family: 'Biennale Regular';
        font-style: normal;
        font-weight: 400;

        strong {
          font-family: 'Biennale Bold';
          color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
        }
      }

      .swiper {
        display: flex;
        align-items: center;
        padding-bottom: 60px;
        margin: 0;

        @media(max-width: 1024px) {
          width: 61.328125vw;
        }

        @media(max-width: 768px) {
          padding-bottom: 32px;
          width: 312px;
        }
      }

      .swiper-wrapper {
        @media(min-width: 1025px) {
          gap: 0 1.666vw !important;
        }
      }

      .swiper-slide {
        display: flex !important;
        max-width: 28vw !important;

        @media(max-width: 1024px) {
          max-width: 92vw !important;
        }
      }

      .swiper-pagination {
        margin: 32px 0 0 !important;
        bottom: 0 !important;

        @media(max-width: 768px) {
          margin: 16px 0 0 !important;
        }

        span {
          width: 16px;
          height: 16px;

          @media(max-width: 768px) {
            width: 8px;
            height: 8px;
          }
        }

        .swiper-pagination-bullet {
          opacity: 1;
          background-color: ${({ colorHighlight }) => colorHighlight ?? 'white'};
        }

        .swiper-pagination-bullet-active {
          background-color: #F0F5FF !important;
        }
      }
`;
