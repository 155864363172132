import styled from "styled-components";

export const Container = styled.div `
    display: flex;
    gap: 16px;
    /* align-items: flex-end; */
    

    @media(min-width: 768px){
        min-height: 93.473vw;
    }

    .container-swiper {
        width: 100vw;
        display: flex;

        @media(max-width: 768px){
            padding: 0 6.67%;
        }

        @media(max-width: 425px){
            padding: 0 24px;
        }
        
        .swiper-3d .swiper-cube-shadow, 
        .swiper-3d .swiper-slide, 
        .swiper-3d .swiper-slide-shadow, 
        .swiper-3d .swiper-slide-shadow-bottom, 
        .swiper-3d .swiper-slide-shadow-left, 
        .swiper-3d .swiper-slide-shadow-right, 
        .swiper-3d .swiper-slide-shadow-top, 
        .swiper-3d .swiper-wrapper {
            padding-bottom: 46px;
        }

        .swiper-slide .swiper-slide-visible .swiper-slide-active > div {
            height: 100%;
        }

        .swiper-pagination-bullet {
            opacity: 1;
            background-color: #3BD42D;
        }

      .swiper-pagination-bullet-active {
        background-color: #F0F5FF !important;
      }

      .swiper-pagination span {
            width: 16px;
            height: 16px;

            @media(max-width: 768px) {
                width: 8px;
                height: 8px;
            }
        }
        
    }
`;