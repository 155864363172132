import target from '../../assets/icons/target.svg'
import tag from '../../assets/icons/tag.svg'
import identityCard from '../../assets/icons/identityCard.svg'

export const CardDescriptions = [
  {
    id: 1,
    img: identityCard,
    title: "Inscrição",
    description: "<ul><li>Realizando sua matrícula hoje, você já ganha acesso aos primeiros conteúdos e já pode começar imediatamente o seu aprendizado.</li></ul>"
  },
  {
    id: 2,
    img: tag,
    title: "Durante",
    description: "<ul><li>Assista as aulas, tire suas dúvidas durante os plantões semanais, converse com o seu mentor e troque ideias com seus colegas! Esse é o momento de mergulhar no mundo da programação!</li></ul>"
  },
  {
    id: 3,
    img: target,
    title: "Recrutamento",
    description: "<ul><li>Além de ganhar o seu certificado, aqui você vai encontrar o início do processo seletivo com as empresas parceiras! Passou na vaga? Parabéns! Iremos continuar acompanhando o seu sucesso no novo emprego!</li></ul>"
  },
]
