import styled from 'styled-components';
import { colorTextDark, backgroundTertiary } from '../../../_metronic/variables.scss';

export const Container = styled.div`
  width: 100%;
  max-width: 628px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  border: 2px solid #F0F5FF;
  border-radius: 16px;

  padding: 52px 24px 24px;
  position: relative;

  text-align: center;
  position: relative;

  background-color: #000;

  @media(max-width: 768px) {
    padding: 52px 16px 16px;
  }

  span {
    margin: auto;
    width: 92%;
    font-family: 'Biennale Bold';

    position: absolute;

    top: -10%;
    /* left: 22px; */
    padding: 20px 32px;

    color: ${backgroundTertiary};
    background: ${({ colorHighlight }) => colorHighlight ?? '#fff'};
    border-radius: 12px;

    font-weight: 700;
    font-size: 22px;
    line-height: 24px;

    @media(max-width: 768px) {
      font-size: 16px;
      top: -8%;
    }

    @media(max-width: 540px) {
      top: -6%;
      width: 87%;
    }

    @media(max-width: 360px) {
      width: 84%;
    }
  }

  .container-texts {
    @media(max-width: 540px) {
      margin-top: 16px;
    }
  }

  .content-choice {
    display: flex;
    align-items: flex-start;
    text-align: initial;

    margin-bottom: 16px;

    .best-choice-icon {
      width: 24px;
      margin-right: 16px;
      margin-top: 4px;

      @media(max-width: 768px) {
        width: 16px;
      }
    }

    .best-choice-desc {
      color: ${colorTextDark};
      font-family: 'Biennale';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;

      @media(max-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }

      strong {
        font-family: 'Biennale bold';
        color: ${({ colorHighlight }) => colorHighlight };
      }
    }

    :nth-last-child(1) {
      margin: 0px;
    }
  }
`;

